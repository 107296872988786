import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

class TableLine extends React.Component{

    render() { //className='table-active'
        return (
            <TableRow>
                {
                    this.props.list.map((item, idx) => {
                        return <TableCell key={idx} className={this.props.head && idx === 0 ? (this.props.membership ? 'table-success' : 'table-active') : null }>
                            {typeof item === "number" ? '$' + item : item}
                        </TableCell>
                    })
                }

            </TableRow>
        );
    }
}

export default TableLine;
