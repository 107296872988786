import React from 'react';
import moment from 'moment';

class CustomDate extends React.Component{

    state = {
        value: this.props.value || moment().format('L')
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            value: nextProps.value
        })
    }

    render() {
        return(
            <span className="p-2 date-input" onClick={this.props.onClick}>
                {this.state.value}
            </span>
        )
    }

}

export default CustomDate;
