import React from 'react';
import Services from "../services/Services";
/*import Button from "@material-ui/core/Button";
import db from "../Firestore";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import {easing} from "@material-ui/core/styles";*/

class Home extends React.Component{

    state = {
        online : 'Booked online'
    };

    componentDidMount() {
        Services.checkIfLoggedIn(this.props.user, this.props.history, '/home', true);
    }

  /*  groupBy = (xs, key) => {
       return xs.reduce((rv, x) => {
           (rv[x[key]] = rv[x[key]] || []).push(x);
           return rv;
       }, {});
   };*/

    /*serviceName = (srv) => {
        let flag = srv.flag && srv.cat_name === 'Lashes' ?  ' (' + srv.flag + ')' : '';
        let service = srv.cat_name + ' - ' + srv.name + flag;

        return service
    };*/

    // update upcoming and past visit
    /*fix = async() => {
        //appoitments
        //clients
        //expenses
        //leads
        //expenses

        await db.collection('/clients')
            .get()
            .then(res => {

                let new_list = res.docs.map( itm => {
                    let item = itm.data();
                    return {
                        //...item,
                        uid: itm.id
                    };
                });

                //let apt = {location: 'gg1'} //appoitments | incomes | leads | expenses
                let client = {created_from: 'gg1'} // clients


                console.log(new_list);

                new_list.forEach( async (item, key) => {

                    return await db.collection('clients')
                        .doc(item.uid)
                        .set(client, {merge: true})
                        .then(d => {
                            console.log('Done... for: ' + item.uid)
                        })
                });

            })
    };*/

/*    updateOnlineNote = (item, note) => {
        let apt = {}

        if(note ===  null){
            apt = {source: 'website', note: null}
        }else{
            let single_note = {
                note: note,
                by: 'Default',
                created_at : item.start.toDate()
            }
            apt = {
                note: [single_note]
            }
        }

        console.log(apt)
        return db.collection('appoitments')
            .doc(item.uid)
            .set(apt, {merge: true})
            .then(d => {
                console.log('Done... for: ' + item.uid)
            })
    }*/

    render(){
        return (
            <React.Fragment>
                {/*<h2>This is home</h2>
                <Button onClick={this.fix} variant="contained" className="primary-btn">
                    Fix
                </Button>*/}
            </React.Fragment>
        );
    }
}

export default Home;
