import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import CardSat from "../material-ui/CardSat";
import Hidden from "@material-ui/core/Hidden";
import env from "../../conf";
import Services from "../../services/Services";
import PaymentTypeBadge from "../shared/PaymentTypeBadge";
import Flag from "../material-ui/Flag";

class SingleIncome extends React.Component {

    state = {
        total_prods: 0,
        amount: 0
    };

    income = this.props.income;
    isService = this.income.type === 'service';
    isSubscription = this.income.type === 'subscription';
    isDeposit = this.income.type === 'deposit';
    isGiftCard = this.income.type === 'gift_card';
    membership = this.isSubscription ? env.membership[this.income.client.membership] : null;
    printProducts = this.income.products ? this.income.products.map( (r, idx) => <span key={idx} className='dgray-color d-block'>{(r.qty) + 'x(' + r.name + ') '}</span>): null;

    componentDidMount() {
        this.setState({
            total_prods: Services.calculateRetail(this.income),
            amount: Services.calculateIncome(this.income)
        });
    }

    getFlag = () => {

        let flag = this.income.service.flag;

        return flag ? flag === 'refill' ? ' (RF)' : ' (FS)' : '';

    };

    goToClient = (id) => {
        this.props.goToClient(id);
    };

    render() {
        return(
            this.income.type === 'service' || this.income.type === 'subscription' ?
                <TableRow key={this.income.uid} className={this.isSubscription ? 'subscription' : null}>
                    <TableCell component="th" scope="row">
                        <h6 className='mt-1'>
                            {
                                this.income.credit_used ?
                                    <span className='badge badge-danger mt-0 tag-credit'>c</span> : null
                            }

                            <span className='badge badge-light'>
                                <CardSat
                                    number={this.state.amount}
                                    prefix={true}
                                    showNum={this.props.numbers}
                                />
                            </span>
                        </h6>
                    </TableCell>
                    <Hidden only='xs'><TableCell>
                        <span href={void(0)} onClick={() => this.goToClient(this.income.client.id)} className='income-user'>{this.income.client.name}</span>
                        {this.isSubscription ? <Flag flag={this.income.client.generation}/> : null}
                    </TableCell></Hidden>
                    <TableCell>
                        {this.isService ?
                            <React.Fragment>
                                {this.income.service.cat_name + this.getFlag() + ' - ' + this.income.service.name} <br/>
                                {this.printProducts}
                            </React.Fragment>
                            : this.membership.name
                        }
                    </TableCell>
                    <Hidden only='xs'><TableCell>
                        {this.isService ?
                            <CardSat
                                number={this.income.amount}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                            : null}{/*add hide numbers feature*/}
                    </TableCell>
                        <TableCell>
                            {this.isService ?
                                <CardSat
                                    number={this.income.tip}
                                    prefix={true}
                                    showNum={this.props.numbers}
                                />: null}
                        </TableCell></Hidden>
                    <TableCell>
                        <PaymentTypeBadge data={this.income.payment_type} />
                    </TableCell>
                    <TableCell>
                        {moment(this.income.service_date.toDate()).format('LLL')}
                    </TableCell>
                </TableRow>
                : <TableRow key={this.income.uid}>
                    <TableCell component="th" scope="row">
                        <span className='badge badge-light'>
                            {
                                this.isDeposit || this.isGiftCard ?
                                    <CardSat
                                        number={this.income.amount}
                                        prefix={true}
                                        showNum={this.props.numbers}
                                    />: <CardSat
                                        number={this.state.total_prods}
                                        prefix={true}
                                        showNum={this.props.numbers}
                                    />

                            }
                        </span>
                    </TableCell>
                    <TableCell colSpan={4}>
                        {
                            this.isDeposit ?
                                <span href={void(0)} onClick={() => this.goToClient(this.income.client.id)} className='income-user'>[Deposit] {this.income.client.name}</span>
                                : this.isGiftCard ?
                                '[Gift Card] from "' + this.income.from + '" to "' +  this.income.to + '"'
                                : this.printProducts
                        }
                    </TableCell>
                    <Hidden only='xs'><TableCell>
                        <PaymentTypeBadge data={this.income.payment_type} />
                    </TableCell>
                        <TableCell>
                            {moment(this.income.created_at.toDate()).format('LLL')}
                        </TableCell></Hidden>
                </TableRow>
        )
    }
}

export default SingleIncome;
