import React from 'react';
import moment from 'moment';

class LeadLog extends React.Component {
    log = this.props.log;

    // following is a lazy way to check if the object is a date object or a firebase timestamp
    date = typeof this.log.created_at.getMonth === 'function' ? this.log.created_at : this.log.created_at.toDate();

    render() {
        return(
            <div>
                <small className='gray-color d-block'>
                    {moment(this.date).format('LLL')}
                </small>
                <p className='lead-log'>{this.props.log.note}</p>
            </div>
        )
    }
}

export default LeadLog;
