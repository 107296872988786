import React from 'react';
import SingleAppointment from "./SingleAppointment";
import Services from "../../services/Services";
import moment from "moment";
import env from "../../conf";
import Icon from "@material-ui/core/Icon";

class Appointments extends  React.Component {

    is_past = this.props.title === 'Past Appointments';

    state = {
        r_loading: false
    };

    componentDidMount() {
        if(this.is_past){
            this.setState({
                r_loading: true,
                retails: this.props.retails
            }, cb => {
                console.log(this.props.appts)
            })
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.is_past){
            this.setState({
                retails: nextProps.retails,
                r_loading: false
            });
        }
    }

    render() {
        return(
            <div>
                <h4 className="gray-color">
                    {this.props.title}
                    <span className="float-right">{this.props.appts.length}</span>
                </h4>
                {
                    this.props.appts.length === 0 ?
                        <i className="dgray-color">
                            <Icon className="gray-color icon-top7 mr-1">sentiment_very_dissatisfied</Icon>
                             Nothing to see here
                            <Icon className="gray-color icon-top7 ml-1">sentiment_very_dissatisfied</Icon>
                        </i>
                        : this.props.appts.map((item, idx) => {
                            let apt_date = item.start.toDate()
                            let two_months_before = moment().subtract(env.profile.tech_apt_permission, env.profile.tech_apt_permission_period);
                            let user = Services.getUser()
                            if(
                                (Services.isTech(user) && moment(apt_date) >= two_months_before)
                                || !Services.isTech(user)
                            ){
                                if(this.state.retails && this.state.retails[item.uid]){
                                    return <SingleAppointment item={item} key={idx} items={this.state.retails[item.uid]} />
                                }else{
                                    return <SingleAppointment item={item} key={idx} upcoming={this.props.title} />
                                }
                            }
                            return null
                        })
                }
            </div>
    )
    }
}

export default Appointments;
