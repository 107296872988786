import React from 'react';
import ClientForm from "./ClientForm";

class AddClient extends React.Component {

    state = {
        ld: false,
        lead: {}
    }

    componentDidMount() {
        this.status = 'new';
    }

    render() {
        return (
            <ClientForm />
        )
    }
}
export default AddClient;
