import React from "react";
import Services from "../../services/Services";
import Paper from "@material-ui/core/Paper";
import env from "../../conf";
import {Icon} from "@material-ui/core";

class Location extends React.Component {

    state = {
        location: Services.getLocation()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            location: nextProps.lc
        })
    }

    render() {
        return (
            <Paper className={'p-3 loc-' + this.state.location}>
                <h5 className='font-weight-normal mb-3'>
                    {env.locations[this.state.location].name}
                </h5>
                <span className='spacer'>
                    <Icon className='flag-icon gray-color'>location_on</Icon> {env.locations[this.state.location].address}<br/>
                </span>
                <span className='spacer'>
                    <Icon className='flag-icon gray-color'>phone</Icon>  {env.locations[this.state.location].phone}<br/>

                </span>
                <span className='spacer'>
                    <Icon className='flag-icon gray-color'>link</Icon> <a href={env.locations[this.state.location].page} target='_blank' rel='noopener noreferrer'>Web Page</a><br/>
                </span>
            </Paper>
        )
    }
}

export default Location