const myStyles = {
    button: {
        backgroundColor: '#d8a9b8',
        outline: 0
    },
    fab_appointments: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 10000,
        backgroundColor: '#d8a9b8'
    }
}

export default myStyles
