import React from "react";
import {Paper} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import SingleIncome from "./SingleIncome";
import Hidden from "@material-ui/core/Hidden";
/*import env from "../../conf";
import MenuItem from "@material-ui/core/MenuItem";
import FilterList from "../shared/FilterList";
import Grid from "@material-ui/core/Grid";
import Services from "../../services/Services";*/

class Income extends React.Component {

    state = {
        page: 1,
        size: 30,
        originalList: [...this.props.list],
        new_list: [...this.props.list],
        filter_by:{
            payment_type: 0,
            service: 0,
            type: 0
        }
    };

    receivedProps = false;

    componentWillMount() {
        this.receivedProps = true;
    }

    componentWillReceiveProps(nextProps, nextContext){
        this.setState({
            new_list: nextProps.list
        })
    }

    goToClient = (id) => {
        this.props.goToClient(id);
    };

    setFilter = (filter) => {
        let title = filter.title !== 0 ? filter.title.toLowerCase().replace(' ', '_') : 0;
        let value = filter.value !== 0 ? (title === 'service' ? filter.value : filter.value.toLowerCase().replace(' ', '_')) : 0;
        if(value === 'products_only'){
            value = 'product'
        }else if(value === 'membership'){
            value = 'subscription'
        }

        if(title === 'income_type') title = 'type';

        let filterBy = {...this.state.filter_by}
        filterBy[title] =  value

        this.setState({
            filter_by: filterBy
        }, cb => {

            let my_list = [...this.state.new_list];

            let new_one = [...my_list];

            new_one = my_list.filter(item => {

                if(title === 'service'){
                    return item.service.cat_name === this.state.filter_by.title
                }else if(title === 'type'){
                    return item.type === this.state.filter_by.title
                }else{
                    return item
                }

            })

            this.setState({
                new_list: new_one
            })
        })
    }

    render() {
        return(
            this.receivedProps ?
                <Paper className='mx-3 mt-1 mb-3'>
                    {/*<Grid container spacing={2} className='p-3'>
                        <Grid item xs={6} sm={3} spacing={2}>
                            <FilterList
                                title={'Payment Type'}
                                options={env.accounting.expense_types}
                                setFilter={this.setFilter}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FilterList
                                title={'Service'}
                                options={env.cats}
                                setFilter={this.setFilter}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FilterList
                                title={'Income Type'}
                                options={['Service', 'Membership', 'Deposit', 'Products Only', 'Gift Card']}
                                setFilter={this.setFilter}
                            />
                        </Grid>
                    </Grid>*/}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total</TableCell>{/*add limited width={60} for IG avatar*/}
                                <Hidden only='xs'>
                                    <TableCell>Client</TableCell>
                                </Hidden>
                                <TableCell>Service</TableCell>
                                <Hidden only='xs'>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Tip</TableCell>
                                </Hidden>
                                <TableCell>Payment</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.new_list.map(doc => {
                                    return <SingleIncome key={doc.uid} income={doc} numbers={this.props.numbers} goToClient={this.goToClient}/>})
                            }
                        </TableBody>
                    </Table>
                </Paper>
                : 'Loading'
        )
    }

}

export default Income;
