import React from 'react';
import PropTypes from 'prop-types';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Icon from '@material-ui/core/Icon';
import NumberFormat from 'react-number-format';
import Services from "../../services/Services";
import IconButton from "@material-ui/core/IconButton";
import Instagram from "../material-ui/Instagram";
import Hidden from "@material-ui/core/Hidden";
import EditButton from "../shared/EditButton";
import moment from 'moment';

class SingleClient extends React.Component {

    client = this.props.client;
    user = this.props.user

    openConfirm = () => {
        this.props.select_client(this.client)
    };

    handleClick = () => {
        this.props.openClientDetails(this.client);
    };

    render(){
        return(
            <TableRow key={this.client.uid}>
                <TableCell component="th" scope="row">
                    {
                        this.client.member ?
                            <span className='emoji-list' role='img' aria-label='members'>&#128081;</span>
                            : null
                    }
                    <Instagram ig={this.client.instagram} w={50} />
                </TableCell>
                <TableCell onClick={this.handleClick}>
                    {Services.reformatString(this.client.first_name)} {this.client.last_name}
                </TableCell>
                {
                    Services.isTech(this.user) ?
                        null : <Hidden only="xs">
                            <TableCell onClick={this.handleClick}>
                                <NumberFormat value={this.client.phone} displayType={'text'} format="(###) ###-####" />
                            </TableCell>
                        </Hidden>
                }

                <Hidden only="xs">
                    <TableCell onClick={this.handleClick}>
                        {
                            this.client.last_visit &&  this.client.last_visit.start?
                                moment(this.client.last_visit.start.toDate()).format('LLL')
                                : 'N/A'
                        }</TableCell>
                </Hidden>
                {
                    Services.isTech(this.user) ?
                        null : <Hidden only="xs">
                            <TableCell onClick={this.handleClick}>
                    <span className="d-block h6 dgray-color font-weight-bold">
                        {
                            this.client.has_rewards ?
                                this.client.rewards_count
                                : <Icon className='orange mt-3'>close</Icon>
                        }
                    </span>
                                {/*<LinearProgress
                        variant="determinate"
                        color="primary"
                        value={this.client.rewards_count * 10}
                    />*/}
                            </TableCell>
                        </Hidden>
                }

                {
                    Services.isTech(this.user) ?
                        null : <TableCell>
                            {/*<Button onClick={this.handleEdit}>
                        <Icon color="warning" className="orange">edit</Icon>
                    </Button>*/}
                            <EditButton client={this.client} type='icon' />
                            {
                                this.user.owner ?
                                    <IconButton color="secondary" className="orange" aria-label="delete" onClick={this.openConfirm}>
                                        <Icon className="orange">delete</Icon>
                                    </IconButton> : null
                            }
                            {/*<Button onClick={this.openConfirm}>
                        <Icon color="warning" className="orange">delete</Icon>
                    </Button>*/}
                        </TableCell>
                }

            </TableRow>
        )
    }
}

SingleClient.propTypes = {
    client: PropTypes.object.isRequired,
};

export default SingleClient;

