import React from 'react';
import moment from "moment";

class SingleMembership extends React.Component {

    componentDidMount() {
        console.log(this.props.owner)
    }


    render() {
        return(
            (!this.props.owner && this.props.last) || this.props.owner  ?
                <div key={this.props.idx} className='mb-2'>
                    { !this.props.owner && this.props.last ? 'Last membership payment: ' : null }
                    <span className='mr-1 badge badge-info'>${this.props.item.amount}</span>
                    <span className='font-weight-bold d-inline-block mr-2'>
                        {moment(this.props.item.created_at.toDate()).format('LLL')}
                    </span>
                </div> : null
        )
    }
}

export default SingleMembership;
