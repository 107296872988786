import React from "react";
import Icon from "@material-ui/core/Icon";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import db from "../../Firestore";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Loading from "../material-ui/Loading";
import MenuItem from "@material-ui/core/MenuItem";
import {Select} from "@material-ui/core";

class AddItem extends React.Component {

    state = {
        name: '',
        qty: 0,
        sku: '',
        upc: 0,
        cat_id: '',
        price: 0,
        size: '',
        categories: [],
        loading: true
    }

    cat_ref = db.collection('/inv_categories');
    prod_ref = db.collection('/products');


    componentDidMount() {
        this.getCategories()
    }

    getCategories = () => {
        return this.cat_ref.get().then(r => {
            let categories = r.docs.map(itm => {
                let data = itm.data()
                return {
                    ...data,
                    id: itm.id
                }
            });

            console.log(categories);

            this.setState({
                categories: categories,
                loading: false
            });
        })
    }

    checkIfNewItem = () => {
        return true
    }

    clearRedirect = () => {
        localStorage.removeItem('inv_item');
        localStorage.removeItem('inv_cat');
        this.props.history.push('/inventory');
    }

    handleChange = (e) => {
        let {name, value} = e.target

        value = name === 'qty' || name === 'price' || name === 'upc' ? parseInt(value) : value;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = () => {
        if(this.checkIfNewItem){
            let product = {
                ...this.state,
                created_at: new Date()
            }
            delete product.categories;
            delete product.loading;

            this.prod_ref.add(product).then( r => {
                console.log('added');
                this.clearRedirect()
            })
        }
    }

    render() {
        return(
            this.state.loading ?
                <Loading />
                : <Container maxWidth="sm">
                <div className="new-client mt-5">
                    <Card>
                        <CardContent>
                            <span onClick={this.clearRedirect} className='notif-btn'>
                                <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                            </span>
                            <h4 className="form-title">
                                {this.checkIfNewItem() ? 'Add New Product' : 'Update '+ this.state.name}</h4>
                            <div className="clearfix"></div>

                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={errors => console.log(errors)}
                            >
                                <TextValidator
                                    label="Name"
                                    onChange={this.handleChange}
                                    name="name"
                                    value={this.state.name}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    className='row'
                                />
                                <TextValidator
                                    label="Price"
                                    onChange={this.handleChange}
                                    name="price"
                                    value={this.state.price}
                                    validators={['required', 'isNumber']}
                                    errorMessages={['this field is required', 'This has to be a number']}
                                    className='row'
                                />
                                <TextValidator
                                    label="Qty"
                                    onChange={this.handleChange}
                                    name="qty"
                                    value={this.state.qty}
                                    validators={['required', 'isNumber']}
                                    errorMessages={['this field is required', 'This has to be a number']}
                                    className='row'
                                />
                                <TextValidator
                                    label="SKU"
                                    onChange={this.handleChange}
                                    name="sku"
                                    value={this.state.sku}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    className='row'
                                />
                                <TextValidator
                                    label="UPC"
                                    onChange={this.handleChange}
                                    name="upc"
                                    value={this.state.upc}
                                    validators={['required', 'isNumber']}
                                    errorMessages={['this field is required', 'This has to be a number']}
                                    className='row'
                                />
                                <TextValidator
                                    label="Size"
                                    onChange={this.handleChange}
                                    name="size"
                                    value={this.state.size}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    className='row'
                                />

                                <React.Fragment>
                                    <Grid container>
                                        <Grid item xs={6} className="dgray-color">
                                            <span className='d-block'>
                                                Category
                                            </span>
                                            <Select
                                                value={this.state.cat_id}
                                                name='cat_id'
                                                onChange={this.handleChange}
                                            >
                                                {
                                                    this.state.categories.map(itm => {
                                                        return <MenuItem key={itm.id} value={itm.id}>
                                                            {itm.name}
                                                        </MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>

                                <Grid container className="mt-5">
                                    <Grid item xs={4}>
                                        <Button type="submit" variant="contained" className="primary-btn">
                                            <Icon className="mr-2">{this.checkIfNewItem() ? 'add' : 'refresh'}</Icon>
                                            {this.checkIfNewItem() ? 'Add' : 'Update'}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8} className="text-right p-2">
                                        <span onClick={this.clearRedirect} className='link gray-color text-decoration-none notif-btn'>
                                            Cancel
                                        </span>
                                        <small>{this.loading ? 'Loading...' : ''}</small>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        )
    }
}

export default AddItem