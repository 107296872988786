import React from "react";

class Loading extends React.Component {

    classes = 'loading-center';

    componentDidMount() {
        if (this.props.classes){
            this.classes += ' ' + this.props.classes;
        }
    }

    render() {
        return(
            <div className={this.classes}> </div>
        )
    }
}

export default Loading;
