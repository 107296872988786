import React from 'react';
import NumberFormat from "react-number-format";
import env from "../../conf";

class CardSat extends React.Component {

    prefix = this.props.prefix ? '$' : '';
    hiddenText = this.prefix + env.hide_numbers_text;

    render() {
        return(
            <div className="d-block pb-2">
                {
                    this.props.label ? this.props.label+':' : ''
                }
                <span className="float-right">
                {
                    this.props.showNum ?
                        this.hiddenText
                        : <NumberFormat
                            value={this.props.number}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={this.prefix}
                            decimalScale={2}
                        />
                }
                </span>
            </div>
        )
    }
}

export default CardSat;
