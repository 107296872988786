import React from 'react';
import { withRouter} from 'react-router-dom';
import db from "../../../Firestore";
import Loading from "../../material-ui/Loading";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper} from "@material-ui/core";
import SingleGiftCard from "./SingleGiftCard";
import Icon from "@material-ui/core/Icon";
import CardDetails from "./CardDetails";


class GiftCardsList extends React.Component {

    state = {
        giftcards: [],
        loading: false,
        details: false,
        selected_card: null
    };

    componentDidMount() {
        this.getGiftCards();
    }

    getGiftCards = () => {

        this.setState({
            loading: true
        }, cb => {
            db.collection('/gift-cards')
                .orderBy('created_at', 'desc')
                .get()
                .then( r => {

                    let gc = r.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        };
                    });

                    this.setState({
                        giftcards: gc,
                        loading: false
                    }, cb => {
                        console.log(this.state)
                    })
                })
        })
    };

    toggleDetails = (card) => {

        card = card || null;

        this.setState({
            details: !this.state.details,
            selected_card: card
        })
    };

    addNew = () => {
        this.props.history.push('/giftcards/add');
    };

    render() {
        return (
            <div>
                <Paper className='p-3 mt-3'>
                    <h5 className='font-weight-normal mb-3'>
                        {
                            this.state.details ?
                                <div>
                                    <span onClick={this.toggleDetails} className='dgray-color notif-btn'>Gift Cards</span>
                                    <Icon className="mr-2 icon-top7 gray-color">arrow_right</Icon>
                                    {this.state.selected_card.to}
                                </div>
                                : <React.Fragment>
                                    Gift Cards
                                    <span className='float-right'>{this.state.giftcards.length}</span>
                                    {/*<Fab onClick={this.addNew} color="primary" style={myStyles.button} aria-label="Add">
                                        <Icon>add</Icon>
                                    </Fab>*/}
                                </React.Fragment>
                        }

                    </h5>
                    {
                        this.state.loading?
                            <Loading />
                            : this.state.details ?
                            <CardDetails card={this.state.selected_card} />
                            :<Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>From</TableCell>
                                        <TableCell>To</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.giftcards.map(
                                            doc => <SingleGiftCard key={doc.id} card={doc} openLeadDetails={this.toggleDetails}/>
                                        )
                                    }
                                </TableBody>
                            </Table>
                    }
                </Paper>
            </div>
        );
    }
}


export default withRouter(GiftCardsList);
