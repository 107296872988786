import React from 'react';
import {Paper} from "@material-ui/core";
import moment from "moment";

class Balance extends React.Component {

    state = {
        loading: false
    };

    client = this.props.client;


    // props: client_id

    componentDidMount() {
        this.loading = true;
    }

    render() {
        return(
            <Paper className='p-3 mb-3'>
                <h5 className="text-danger">
                    Balance: ${this.client.balance.amount}

                    <span className="text-right float-right text-secondary">{moment(this.client.balance.created_at.toDate()).format('LLL')}</span>

                </h5>

                <div className="clearfix"></div>
                <span className="alert-warning">{this.client.balance.note}</span>
            </Paper>
        )
    }
}

export default Balance;