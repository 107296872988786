import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import AddClient from "../components/clients/AddClient";
import ClientsList from "../components/clients/ClientsList";
import UpdateClient from "../components/clients/UpdateClient";
import Services from "../services/Services";
import Loading from "../components/material-ui/Loading";

class Clients extends React.Component {

    componentWillReceiveProps(nextProps, nextContext) {
        Services.checkIfLoggedIn(nextProps.user, this.props.history, '', false);
    }

    render(){
        return (
            this.props.user != null ?
                <Router>
                    <Route exact path='/clients' component={ClientsList}></Route>
                    <Route exact path='/clients/add' lc={this.props.lc} component={AddClient}></Route>
                    <Route exact path='/clients/:id/update' component={UpdateClient}></Route>
                </Router>
                : <Loading />
        );
    }
}


export default Clients;
