import React from "react";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";

class EditButton extends React.Component {

    client = this.props.client;
    type = this.props.type;

    handleEdit = () => {
        // this is to convert the timestamp from firestore to a normal date object for local storage usage
        this.client.membership_date = this.convertDateObj('membership_date');
        //this.client.last_visit = this.convertDateObj('last_visit');
        //this.client.upcoming_appt = this.convertDateObj('upcoming_appt');

        localStorage.setItem('client', JSON.stringify(this.client));
        this.props.history.push(`/clients/${this.client.uid}/update`)
    };

    convertDateObj = (prop) => {
        let new_prop;
        if(prop !== 'membership_date'){
            new_prop = this.client[prop] ? moment(this.client[prop].start.toDate()).format() : null;
        }else{
            new_prop = this.client[prop] ? moment(this.client[prop].toDate()).format() : null;
        }

        return new_prop;
    };

    render() {
        return(
            this.type === 'icon' ?
                <IconButton color="secondary" className="orange" aria-label="Edit" onClick={this.handleEdit}>
                    <Icon className="orange">edit</Icon>
                </IconButton>
                : <Button onClick={this.handleEdit} variant="outlined" color="secondary" className='my-2 float-right'>
                    <Icon>edit</Icon>
                </Button>
        )
    }
}

export default withRouter(EditButton);
