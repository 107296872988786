import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import NumberFormat from "react-number-format";
import env from "../../conf";
import axios from 'axios';
import Loading from "../material-ui/Loading";
import {NavLink} from "react-router-dom";
import db from "../../Firestore";

class PreviewCamp extends React.Component {

    state = {
        loading: false,
        sent: false
    };

    data = this.props.data;
    previous_state = this.props.data;

    close = () => {
        this.props.close();
    };

    send = () => {
        this.setState({
            loading: true
        }, cb => {
            let list = this.data.selected_clients.map(it => '+1' + it.phone);

            let payload = {
                list: list,
                message: this.data.content
            };

            axios.post(env.base_api + '/send', payload)
                .then(r => {
                    if(r.data.success) this.logCampaign();
                })
                .catch( er => {
                    this.setState({
                        loading: false,
                        sent: false
                    })
                })
        });
    };

    logCampaign = () => {

        let campaign = {
            title: this.previous_state.title,
            content: this.previous_state.content,
            campaign_cat: this.previous_state.campaign_cat,
            client_type: this.previous_state.client_type,
            selected_clients: this.data.selected_clients,
            created_at: new Date()
        };

        db.collection('/campaigns')
            .add(campaign)
            .then(r => {
                this.setState({
                    loading: false,
                    sent: true
                })
            }).catch(er => {
            this.setState({
                loading: false,
                sent: false
            })
        });
    };

    render() {
        return(
            <div>
                <Grid container className="mt-3 mb-4 text-center">
                    <Grid item xs={12} className="text-center">
                        <Button onClick={this.close} className='link' style={{color: '#bbb', float: 'right', cursor: 'pointer'}}>
                            <Icon>close</Icon>
                        </Button>
                        <h4 className='float-left'>
                            {
                                this.state.sent ?
                                    'Great job, Sent'
                                    : this.state.loading ? 'Sending...' : 'Preview Campaign'
                            }
                        </h4>
                        <div className='clearfix'></div>
                    </Grid>
                </Grid>

                {
                    this.state.loading ?
                        <Loading />
                        : this.state.sent ?
                        <div className='d-block text-center done-modal'>
                            <img width={500} src='https://66.media.tumblr.com/fd525ee9bc5a7879264a62e5233d93db/tumblr_nwhck4fuZz1urz3l2o1_640.png' alt='Done' />
                            <br/>
                            <NavLink to='/campaigns' className='link mt-3 d-block'>
                                <Icon>thumb_up</Icon>
                            </NavLink>
                        </div>
                        : <div>
                            <Grid container className="my-3">
                                <Grid item xs={12} sm={6}>
                                    <h5>Campaign Details</h5>
                                    <div className='mb-4'>
                                        <b>Category</b>: {env.marketing.sms_categories[this.data.campaign_cat]} <br/>
                                        <b>Client Type</b>: {env.marketing.sms_clients_type[this.data.client_type]} <br/>
                                        <b>Title</b>: {this.data.title} <br/>
                                    </div>

                                    <h5>SMS Content</h5>
                                    <div className='text-preview'>
                                        {this.data.content}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h5>Recipients</h5>
                                    <div className='box-client box-customized p-1'>
                                        {
                                            this.data.selected_clients.map((cl, id) => {
                                                return <div key={id}>
                                                    <b>{cl.name}</b> - <NumberFormat value={cl.phone} displayType={'text'} format="(###) ###-####" /><br/>
                                                </div>
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container className="my-3">
                                <Grid item xs={12} className='text-center'>
                                    <Button onClick={this.send} variant='outlined' color='primary'>
                                        <Icon className="mr-2">send</Icon>
                                        SEND
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                }
            </div>
        )
    }
}

export default PreviewCamp;
