import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import env from "./conf";

const config = {};

const conf = env.env === 'prod' ? env.firebase : env.firebaseDev;

for(let key in conf) {
    config[key] = conf[key];
}

firebase.initializeApp(config);

const db = firebase.firestore();

export default db;
export const  auth = firebase.auth();
export const  ts = firebase.firestore.Timestamp;
export const increment = firebase.firestore.FieldValue.increment(1);

//export const storage = firebase.storage();
