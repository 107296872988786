import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

class Confirm extends React.Component {

    item = this.props.client;
    item_name = this.props.inv ? this.item.name : this.item.first_name + ' ' + this.item.last_name;
    general = this.props.general;

    render(){
        return(
            <div>

                <Grid container className="mt-3 mb-4 text-center">
                    <Grid item xs={12}>
                        {
                            this.general ?
                                <React.Fragment>{this.item}</React.Fragment>
                                :
                                <React.Fragment>
                                    Are you sure you want to delete: <b>{this.item_name}</b>?<br/>
                                    <small className="dgray-color">- This is a final action and cannot be reverted! -</small>
                                </React.Fragment>
                        }
                    </Grid>
                </Grid>

                <Grid container className="my-3">
                    <Grid item xs={6}>
                        <Button onClick={this.props.delete} variant="contained" color="secondary">
                            {
                                this.general ?
                                    <React.Fragment>
                                        <Icon className="mr-2">send</Icon>
                                        Send
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Icon className="mr-2">delete_sweep</Icon>
                                        Delete
                                    </React.Fragment>
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                        <Button onClick={this.props.close}  className='link gray-color text-decoration-none'>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Confirm;
