import React from 'react';
import {Icon} from "@material-ui/core";

class SingleClientChip extends React.Component {

    client = this.props.client;

    componentWillReceiveProps(nextProps, nextContext) {
        this.client = nextProps.client
    }

    deleteItem = () => {
        this.props.delete(this.props.client);
    };

    render() {
        return(
            <div className='client-campaign'>
                {this.client.name}
                {
                    this.props.managed ?
                        <Icon onClick={this.deleteItem} className='custom-chip-delete'>close</Icon>:null
                }
            </div>
        )
    }
}

export default SingleClientChip;
