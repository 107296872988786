import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Instagram from "../../material-ui/Instagram";
import Flag from "../../material-ui/Flag";
import Hidden from "@material-ui/core/Hidden";
import Services from "../../../services/Services";

class SingleLead extends React.Component {

    lead = this.props.lead;



    componentDidMount() {
        //console.log(this.lead)
    }

    handleClick = () => {
        this.props.openLeadDetails(this.lead);
    };

    /* openConfirm = () => {
         this.props.select_lead(this.lead)
     };

     handleClick = () => {
         this.props.openLeadDetails(this.lead);
     };*/



    render() {
        return (
            <TableRow key={this.lead.id}>
                <TableCell component="th" scope="row">
                    <Instagram ig={this.lead.instagram} w={50} />
                    {
                        this.lead.returning ?
                            <Flag color={'tag-returning badge-success'} flag={'Client'} /> : null
                    }
                </TableCell>
                <TableCell component="th" scope="row" onClick={this.handleClick}>
                    {
                        this.lead.logs ?
                            this.lead.name: <b>&#8226; {this.lead.name}</b>
                    }
                </TableCell>
                <Hidden only="xs">
                    <TableCell component="th" scope="row" onClick={this.handleClick}>
                        {
                            this.lead.logs ?
                                this.lead.phone: <b>{this.lead.phone}</b>
                        }
                    </TableCell>
                </Hidden>
                <Hidden only="xs">
                    <TableCell component="th" scope="row" onClick={this.handleClick}>
                        {
                            this.lead.logs ?
                                this.lead.email: <b>{this.lead.email}</b>
                        }
                    </TableCell>
                </Hidden>
                <Hidden only="xs">
                    <TableCell component="th" scope="row" onClick={this.handleClick}>
                        {
                            this.lead.logs ?
                                this.lead.service: <b>{this.lead.service}</b>
                        }
                    </TableCell>
                </Hidden>
                <TableCell component="th" scope="row" onClick={this.handleClick}>
                    <Flag flag={'step ' + this.lead.step} color={'badge-info'}/>
                </TableCell>

                {
                    //this.props.owner ?
                        <TableCell component="th" scope="row" onClick={this.handleClick}>
                            {
                                (this.props.owner && this.lead.aff)
                                || (!this.props.owner && Services.showAffiliate(this.lead.aff)) ?
                                    <Flag flag={this.lead.aff} color={'badge-warning'}/>
                                    : null
                            }
                        </TableCell>// : null
                }
            </TableRow>
        );
    }
}

export default SingleLead;
