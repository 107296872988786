import React from "react";
import Paper from "@material-ui/core/Paper";
import CardSat from "../material-ui/CardSat";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import db from "../../Firestore";

class MembersAct extends React.Component {

    state = {
        total: 0,
        amount: 0,
        average: 0
    };

    componentDidMount() {
        db.collection('/clients')
            .where('member', '==', true)
            .get()
            .then(r => {
                let size = r.size;
                let total = 0;

                r.docs.map(doc => {
                    let info = doc.data();
                    total += info.membership_price ? info.membership_price : 0;
                    console.log('info', info.membership_price)
                    return total
                });

                this.setState({
                    total: size,
                    amount: total,
                    average: total / size
                })

            })
    }

    render() {
        return(
            <Grid item xs={12} sm={6}>
                <Paper className='px-3 pt-3 pb-2'>
                    <CardSat
                        number={this.state.total}
                        label={'Total Members'}
                        prefix={false}
                        showNum={this.props.numbers}
                    />
                    <CardSat
                        number={this.state.amount}
                        label={'Total Membership Income/mo'}
                        prefix={true}
                        showNum={this.props.numbers}
                    />
                    <Divider className="mb-2" />
                    <CardSat
                        number={this.state.average}
                        label={'Average Price Per Member/mo'}
                        prefix={true}
                        showNum={this.props.numbers}
                    />
                </Paper>
            </Grid>
        )
    }
}

export default MembersAct;
