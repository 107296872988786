import React from 'react';
import Hidden from "@material-ui/core/Hidden";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import env from "../../conf";

class SingleCampaign extends React.Component {

    campaign = this.props.campaign;

    openCampaign = () => {
        this.props.open(this.campaign);
    };

    render() {
        return(
            <TableRow key={this.campaign.id}>
                <Hidden only='xs'>
                    <TableCell onClick={this.openCampaign}>{env.marketing.sms_categories[this.campaign.campaign_cat]}</TableCell>
                </Hidden>
                <TableCell onClick={this.openCampaign}>{this.campaign.title}</TableCell>{/*add limited width={60} for IG avatar*/}
                <Hidden only='xs'>
                    <TableCell onClick={this.openCampaign}>{env.marketing.sms_clients_type[this.campaign.client_type]}</TableCell>
                </Hidden>
                <TableCell onClick={this.openCampaign}>{this.campaign.selected_clients.length}</TableCell>
                <Hidden only='xs'>
                    <TableCell onClick={this.openCampaign}>{moment(this.campaign.created_at.toDate()).format('LLL')}</TableCell>
                </Hidden>
            </TableRow>
        )
    }
}

export default SingleCampaign;
