import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from 'moment';
import {Icon} from "@material-ui/core";
import env from "../../../conf";

class SingleFeedback extends React.Component {

    feedback = this.props.feedback;

    componentDidMount() {
        console.log(this.feedback)
    }

    render() {
        return (
            <TableRow key={this.feedback.id}>
                <TableCell component="th" scope="row">
                    <a href={env.urls.website + this.feedback.url} target='_blank' rel='noopener noreferrer'>{this.feedback.url}</a>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong className='mr-3 dgray-color'>{this.feedback.up +  this.feedback.down}</strong>
                    <Icon className='gray-color icon-list mr-1'>thumb_up</Icon>{this.feedback.up}
                    <Icon className='gray-color icon-list mr-1 ml-3'>thumb_down</Icon>{this.feedback.down}

                </TableCell>

                <TableCell component="th" scope="row">{moment(this.feedback.created_at.toDate()).format('LLL')}</TableCell>
            </TableRow>
        );
    }
}

export default SingleFeedback;
