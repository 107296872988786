import React from 'react';
import Leads from "../components/campaigns/leads/Leads";
import Services from "../services/Services";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Feedback from "../components/campaigns/feedback/Feedback";
import CampList from "../components/campaigns/CampList";
import SendSms from "../components/campaigns/SendSms";
import Loading from "../components/material-ui/Loading";
import { withRouter} from 'react-router-dom';
import SubNav from "../components/shared/SubNav";
import GiftCardsList from "../components/campaigns/giftcards/GiftCardsList";
import AddGiftCard from "../components/campaigns/giftcards/AddGiftCard";


class Marketing extends React.Component {

    state = {
        user: Services.getUser()
    }

    componentDidMount() {
        Services.checkIfLoggedIn(this.props.user, this.props.history, '', false);
    }

    goToClientDetails = (id) => {
        this.props.history.push('/clients?cl=' + id);
    };

    render() {
        return (
            this.props.user != null ?
                <div className='m-4'>
                    <Router>

                        {
                            this.state.user.owner ?
                                <SubNav />
                                : null
                        }

                            <Route
                                exact
                                path='/marketing'
                                render={(props) => <Leads goToClient={this.goToClientDetails} lc={this.props.lc}/>}
                            />
                        <Route exact path='/campaigns' component={CampList} />
                        <Route exact path='/campaigns/new' component={SendSms} />
                        <Route exact path='/feedback' component={Feedback} />
                        <Route exact path='/giftcards' component={GiftCardsList} />
                        <Route exact path='/giftcards/add' component={AddGiftCard}></Route>

                    </Router>
                </div>
                : <Loading />
        );
    }
}

export default withRouter(Marketing);
