import React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import db from "../../Firestore";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import env from "../../conf";
import DatePicker from "react-datepicker/es";
import Dates from "../../services/Dates";
import moment from 'moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccInsights from "./AccInsights";
import Income from "./Income";
import Expense from "./expenses/Expense";
import CustomDate from "../shared/CustomDate";
import Loading from "../material-ui/Loading";
import Chart from "./chart/Chart";

class AccList extends React.Component {

    constructor(props) {
        super(props);

        this._isMounted = false;
        this.loading = true;
    }

    state = {
        expenses: [],
        incomes: [],
        expenses_count: 0,
        incomes_count: 0,
        service_count: 0,
        products: [],
        products_count: 0,
        tip: 0,
        service: 0,
        filter: env.accounting.filter_default,
        custom_from: moment().startOf('month').toDate(),
        custom_to: moment().toDate(),
        stats: {
            total_services: 0,
            total_clients: 0,
            clients: {}
        },
        numbers: false,
        tab: 0,
        location: this.props.lc
    };

    loading = true;

    chart = env.chart;


    componentDidMount() {

        // check if user is coming from calendar to add an income
        let check_storage = JSON.parse(localStorage.getItem(env.local.income_from_cal));

        // get data needed to add an income then redirect user to add a new income
        if(check_storage) {
            let apt_id = JSON.parse(localStorage.getItem(env.local.apt_id));
            this.props.history.push('/accounting/add-service?apt=' + apt_id);
            localStorage.removeItem(env.local.income_from_cal);
            localStorage.removeItem(env.local.apt_id);
        }else{
            // load data without redirect
            this.getData('expenses', 'created_at');
            this.getData('incomes', 'created_at'); // old service_date
        }
    }

    componentWillMount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            location: nextProps.lc
        }, r => {
            this.getData('expenses', 'created_at');
            this.getData('incomes', 'created_at'); // old service_date
        })
    }

    getProducts = (products) => {

        let pr_count = 0;

        products.map(product => (
            pr_count += product.price * product.qty
        ));

        this.setState({
            products: this.state.products.concat(products),
            products_count: this.state.products_count + pr_count
        })

    };

    getNum = (name, val) => {

        this.setState({
            [name]: this.state[name] + val
        })
    };

    getData = (collection, field) => {

        this._isMounted = false;

        let count = 0;
        let total_services = 0;
        let total_clients = 0;
        let client = {};

        db.collection(collection)
            .where(field, '>=', this.state.custom_from)
            .where(field, '<=', this.state.custom_to)
            .where('location', '==', this.state.location)
            .orderBy('created_at', 'asc')
            .get()
            .then(res => {

                let results = res.docs.map((doc) => {

                    let info = doc.data();
                    let products = info.products;
                    let amount = info.amount;
                    let tip = info.tip;

                    if(collection === 'incomes'){

                        if(products){
                            this.getProducts(products);
                            products.map(product => (
                                count += product.price * product.qty
                            ));
                        }

                        if(info.type === 'service'){
                            total_services++;
                            if(!client[info.client.id]){
                                client[info.client.id] = info.client.name;
                                total_clients++;
                            }

                        }

                    }

                    if(amount) {
                        count += amount;
                        if( collection === 'incomes' ) this.getNum('service', amount);
                    }

                    if(tip){
                        count += tip;
                        this.getNum('tip', tip);
                    }

                    return {
                        ...doc.data(),
                        uid: doc.id
                    };
                });

                let count_name = collection + '_count';

                this.setState({
                    [collection]: results,
                    [count_name]: count,
                    stats: {
                        total_services: total_services,
                        total_clients: total_clients,
                        clients: client
                    }
                }, cb => {
                    this.loading = false;
                    //console.log(this.state)
                })
            })
    };

    addBtn = (link) => {
        this.props.history.push('/accounting/add-' + link)
    };

    changeFilter = (e) => {

        let start, end = moment().toDate();
        let value = e.target.value;

        if(value !== 'Custom'){
            start = Dates.getDate(e.target.value, 'start', null);
            end = Dates.getDate(e.target.value, 'end', null);
        }

        this.setState({
            filter: e.target.value,
            custom_from: start,
            custom_to: end,
            products: [],
        }, ()=> {
            if(value !== 'Custom') this.getNumbers();
        })
    };

    handleCustom = (e, name) => {

        this.setState({
            [name]: Dates.getDate('custom', name, e)
        });
    };

    goToClient = (id) => {
        this.props.goToClient(id);
    };

    getNumbers = () => {

        this.loading = true;

        this.setState({
            products_count: 0,
            tip: 0,
            service: 0
        }, () => {
            let start = this.state.custom_from;
            let end = this.state.custom_to;

            if(start === undefined || start === '' || end === '' || end === undefined){
                alert('Error!, please check date range');
            }else{
                this.getData('expenses', 'created_at');
                this.getData('incomes', 'created_at');
            }
        })
    };

    handleChangeTab = (e, newValue) => {
        this.setState({
            tab: newValue
        })
    };

    render() {
        return (
            <div className="clients-list">
                <div className="col-sm-6 col-xs-12 float-left mt-4 mb-4" style={{textAlign: 'left'}}>
                    <Icon className='orange mr-2 position-relative space-top1 icon-list'>filter_list</Icon>
                    <Select
                        value={this.state.filter}
                        name='filter'
                        onChange={this.changeFilter}
                    >
                        {
                            env.accounting.filter_options.map((filter, index) => {
                                return <MenuItem key={index} value={filter}>{filter}</MenuItem>
                            })
                        }
                    </Select>
                    {
                        this.state.filter === 'Custom' ?
                            <div className='d-inline-block col-xs-12'>
                                <DatePicker
                                    selected={this.state.custom_from}
                                    selectsStart
                                    startDate={this.state.custom_from}
                                    endDate={this.state.custom_to}
                                    onChange={e => this.handleCustom(e, 'custom_from')}
                                    customInput={<CustomDate value={this.state.custom_from}/>}
                                    disabledKeyboardNavigation
                                    shouldCloseOnSelect={true}
                                />

                                <DatePicker
                                    selected={this.state.custom_to}
                                    selectsEnd
                                    startDate={this.state.custom_from}
                                    endDate={this.state.custom_to}
                                    onChange={e => this.handleCustom(e, 'custom_to')}
                                    minDate={this.state.custom_from}
                                    customInput={<CustomDate value={this.state.custom_to} />}
                                    disabledKeyboardNavigation
                                    shouldCloseOnSelect={true}
                                />
                                <Button onClick={this.getNumbers} color='secondary'>
                                    <Icon className='dgray-color'>search</Icon>
                                </Button>
                            </div>
                            : ''
                    }
                </div>
                <div className="col-sm-6 col-xs-12 float-right mt-4 mb-4" style={{textAlign: 'right'}}>
                    <Button onClick={() => this.addBtn('service')} variant="contained" className="mr-3">
                        <Icon className="mr-2">attach_money</Icon>
                        Income
                    </Button>
                    <Button onClick={() => this.addBtn('expense')} variant="contained">
                        <Icon className="mr-2">add_shopping_cart</Icon>
                        Expense
                    </Button>
                </div>
                <div className="clearfix"></div>
                {
                    this.loading ?
                        <Loading />
                        : <div>
                            <Tabs
                                value={this.state.tab}
                                onChange={this.handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Insights" />
                                <Tab label="Income" />
                                <Tab label="Expenses" />
                                <Tab label="Chart" />
                            </Tabs>

                            {this.state.tab === 0 && !this.loading &&
                            <AccInsights accState={this.state} numbers={this.props.numbers} />
                            }

                            {this.state.tab === 1 && <Income list={this.state.incomes} numbers={this.props.numbers} goToClient={this.goToClient}/>}
                            {this.state.tab === 2 && <Expense list={this.state.expenses} numbers={this.props.numbers}/>}
                            {this.state.tab === 3 && <Chart
                                incomes={this.state.incomes}
                                expenses={this.state.expenses}
                                numbers={this.props.numbers}
                                from={this.state.custom_from}
                                to={this.state.custom_to}
                            />}
                        </div>
                }
            </div>
        )
    }
}

export default AccList;
