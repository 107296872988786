import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableLine from "./TableLine";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

class Skin extends React.Component {

    list = {
        facials: {
            head: ['Facials', 'Non-Members Price'],
            list : [
                ['Detox Deep Cleansing', 299],
                ['Sensitive Calming', 199],
                ['Acne Extractions', 249],
                ['Anti-Aging', 285],
                ['HydroFacial', 299],
                ['Back Facial', 149], // TODO move to body care
                ['Men Facial', 149],
                ['Teen Facial', 149],
                ['Express Facial', 125]
            ]
        },
        treatments: {
            head: ['Advanced Treatments', 'Non-Members'],
            list : [
                ['Microneedling', 449],
                ['Glow Vitamin C', 349],
                ['Dermaplane', 249],
                ['LED Therapy & Laser Rejuvenation', 349],
                ['Microdermabrasion', 249],
                ['Oxygen Skin Therapy', 299],
                ['Hifu Face Lift', 649],
                ['Vampire Velvet Peel', 249]
            ]
        },
        skin_members : {
            head: ['Members', 'Price'],
            price: ['1 Facial/mo', 99.99],
        }
    };

    render() {
        return(
            <div>
                <Paper className='mx-3 mt-1 mb-3'>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <Table>
                                <TableHead>
                                    <TableLine list={this.list.facials.head} head={true} />
                                </TableHead>
                                <TableBody>
                                    {
                                        this.list.facials.list.map(it => {
                                            return <TableLine list={it} head={false} />
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Table>
                                <TableHead>
                                    <TableLine list={this.list.treatments.head} head={true} />
                                </TableHead>
                                <TableBody>
                                    {
                                        this.list.treatments.list.map(it => {
                                            return <TableLine list={it} head={false} />
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableLine list={this.list.skin_members.head} head={true} membership={true}/>
                        </TableHead>
                        <TableBody>
                            <TableLine list={this.list.skin_members.price} head={false} />
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

export default Skin;
