import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import db, {increment} from "../../Firestore";
import Loading from "./Loading";

class Redeem extends React.Component {

    client = this.props.client;
    canRedeem = this.client.rewards_count >= 10;

    state = {
        load: false,
        redeemed: false
    };

    redeemRewards = () => {

        this.setState({
            load: true
        }, cb => {
            let setObj = {
                redeemed: increment,
                redeemed_at: new Date(),
                rewards_count: this.client.rewards_count - 10
            };

            db.collection('/clients')
                .doc(this.client.uid)
                .set(setObj, {merge: true})
                .then(
                    r => {
                        this.setState({
                            load: false,
                            redeemed: true
                        });
                    }
                ).catch(
                er => {
                    this.setState({
                        load: false
                    });
                }
            )
        });

    };

    render(){
        return(
            this.state.load ?
                <Loading />
                : <div>
                    <h5 className="text-center">Redeem Rewards for {this.client.first_name} {this.client.last_name}</h5>
                    <Grid container className="mt-3 mb-4 text-center">
                        {
                            this.state.redeemed ?
                                <Grid item xs={12} className="text-center">
                                <span className="d-block alert-success">
                                    10 Rewards has been redeemed <br/>
                                    Remaining rewards: <b>{this.client.rewards_count - 10}</b>
                                </span>
                                </Grid>
                                : <Grid item xs={12} className="text-center">
                                    <span className="d-block">Available rewards: <b>{this.client.rewards_count}</b></span>
                                    {
                                        this.canRedeem ?
                                            <span className="d-block">Available rewards after redemption: <b>{this.client.rewards_count - 10}</b></span>
                                            : <span className="mt-3 d-block alert-warning"><b>{this.client.first_name}</b> needs {10 - this.client.rewards_count} rewards more in order to redeem!</span>
                                    }
                                </Grid>
                        }
                    </Grid>

                    {
                        this.state.redeemed ?
                            <Grid container className="my-3">
                                <Grid item xs={12} className="text-center">
                                    <Button onClick={this.props.close} className='link gray-color text-decoration-none'>
                                        Got it
                                    </Button>
                                </Grid>
                            </Grid>
                            : this.canRedeem?
                            <Grid container className="my-3">
                                <Grid item xs={6}>
                                    <Button onClick={this.redeemRewards} variant="contained" className="orange">
                                        <Icon className="mr-2">stars</Icon>
                                        Reddem
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className="text-right">
                                    <Button onClick={this.props.close} className='link gray-color text-decoration-none'>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                            : <Grid container className="my-3">
                                <Grid item xs={12} className="text-center">
                                    <Button onClick={this.props.close} className='link gray-color text-decoration-none'>
                                        Got it
                                    </Button>
                                </Grid>
                            </Grid>
                    }

                </div>
        )
    }
}

export default Redeem;
