import React from 'react';
import {Icon} from "@material-ui/core";
import db from "../../Firestore";
import onClickOutside from 'react-onclickoutside'
import SingleNotification from "./SingleNotification";

class Notifications extends React.Component {

    state = {
        open: false,
        notifications: [],
        new_notification: false
    };

    componentDidMount() {
        this.getNotification();
    }

    toggleNotif = () => {
        this.setState({
            open: !this.state.open
        })
    };

    handleClickOutside = () => {
        this.setState({
            open: false
        });
    };

    getNotification = () => {
        db.collection('notifications')
            .onSnapshot(r => {

                let new_notification = false;

                let list = r.docs.map(res => {
                    if(res.data().read) new_notification = true;
                    return {
                        ...res.data(),
                        id: res.id
                    }
                });

                this.setState({
                    notifications: list,
                    new_notification: new_notification
                })
            })
    };

    goToClient = (id) => {
        this.props.goToClient(id)
    };


    render(){
        return(
            <div className={'float-right' + (this.state.new_notification ? ' new_notif':'')}>
                <span onClick={this.toggleNotif} className="notif-btn">
                    {
                        this.state.notifications.length > 0 ?
                            <React.Fragment>
                                <Icon>notifications</Icon>
                                <i className="notif-count">{this.state.notifications.length}</i>
                            </React.Fragment>
                            : <Icon>notifications_none</Icon>
                    }
                </span>
                {
                    this.state.open ?
                        <div className="notif-paper dgray-color">
                            <div>
                                {
                                    this.state.notifications.length === 0 ?
                                        <span className="notif-item text-center">All good!</span>
                                        : this.state.notifications.map((item, idx) => {
                                            return <SingleNotification key={idx} notification={item} clickName={this.goToClient} toggle={this.toggleNotif}/>
                                        })
                                }
                            </div>
                        </div>
                        : null

                }
            </div>
        )
    }
}

export default onClickOutside(Notifications);
