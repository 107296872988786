import React from "react";
import Icon from "@material-ui/core/Icon";
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import db from "../../Firestore";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Hidden from "@material-ui/core/Hidden";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import SingleItem from "./SingleItem";
import DialogContent from "@material-ui/core/DialogContent";
import Confirm from "../material-ui/Confirm";
import Modal from "@material-ui/core/Modal";
import env from "../../conf";

class InventoryList extends React.Component {

    state = {
        products: [],
        categories: [],
        selected_products: {},
        loading: false,
        selected_item: null,
        item_details: false,
        modal: false,
        keyword: ''
    }

    cat_ref = db.collection('/inv_categories');
    prod_ref = db.collection('/products');
    confirmStyle = env.modal_style;


    componentDidMount() {
        this.getStuff(this.cat_ref, 'categories');
        this.getStuff(this.prod_ref, 'products');
    }

    getStuff(e, str) {
        this.unsubscribe = e.onSnapshot(r => {
            let items = r.docs.map(item => {

                return {
                    ...item.data(),
                    id: item.id
                }
            })

            this.setState({
                [str]: items
            }, r => {
                console.log(this.state);
            })
        })

        return this.unsubscribe

    }

    addNew = (e) => {
        let local_item = e === 'add' ? 'inv_item' : 'inv_cat';
        localStorage.removeItem(local_item);
        this.props.history.push('/inventory/' + e);
    }

    selectItem = (item) => {
        this.setState({
            modal: true,
            selected_item: item
        })
    };

    openItemDetails = (item) => {
        this.setState({
            item_details: true,
            selected_item: item
        })
    };

    handleModalClose = e => {
        this.setState({
            modal: false
        })
    };

    deletItem = id => {
        this.prod_ref.doc(this.state.selected_item.id).delete().then(res => {
            this.setState({
                modal: false,
                selected_item: null,
                keyword: ''
            });
        });
    };

    getCategoryName = (id) => {
        let category = this.state.categories.find(x => x.id === id)
        return category.name;
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        return(
            <div className="clients-list m-4">
                <div className="col-sm-6 col-xs-12 float-left mt-4 mb-4" style={{textAlign: 'left'}}>
                    search  goes here
                </div>
                <div className="col-sm-6 col-xs-12 float-right mt-4 mb-4" style={{textAlign: 'right'}}>
                    <Button onClick={() => this.addNew('add')} variant="contained" className="mr-3">
                        <Icon className="mr-2">add</Icon>
                        Item
                    </Button>
                    <Button onClick={() => this.addNew('add-cat')} variant="contained">
                        <Icon className="mr-2">add</Icon>
                        Category
                    </Button>
                </div>
                <div className="clearfix"></div>
                {
                    this.state.item_details ?
                        <p>item details here</p>
                        : this.state.products.length > 0 &&  this.state.categories.length > 0 ?
                            <Paper>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {/*<Icon className="float-left" >arrow_drop_down</Icon>*/}
                                                Item Name
                                            </TableCell>
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Size</TableCell>
                                            <Hidden only="xs"><TableCell>Category</TableCell></Hidden>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.products.map(doc => {
                                                 return <SingleItem
                                                    key={doc.id}
                                                    item={doc}
                                                    selectItem={this.selectItem}
                                                    openitemDetails={this.openItemDetails}
                                                    cat={this.getCategoryName(doc.cat_id)}
                                                />
                                            })
                                        }
                                        {/*<TableRow>
                                            <TableCell colSpan={6}>
                                                <TablePagination
                                                    rowsPerPageOptions={env.per_page.options}
                                                    component="div"
                                                    count={this.state.size}
                                                    rowsPerPage={env.per_page.default}
                                                    page={this.state.page}
                                                    backIconButtonProps={{
                                                        'aria-label': 'Previous Page',
                                                    }}
                                                    nextIconButtonProps={{
                                                        'aria-label': 'Next Page',
                                                    }}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                />
                                            </TableCell>
                                        </TableRow>*/}
                                    </TableBody>
                                </Table>
                            </Paper> : <p>No products added yet!</p>
                }

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modal}
                    onClose={this.handleModalClose}
                >
                    <DialogContent style={this.confirmStyle}>
                            <Confirm
                                client={this.state.selected_item}
                                close={this.handleModalClose}
                                delete={this.deletItem}
                                inv={true}
                            />
                    </DialogContent>
                </Modal>
            </div>
        )
    }
}

export default withRouter(InventoryList)