import React from 'react';
import {Select} from "@material-ui/core";
import env from "../../conf";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter} from 'react-router-dom';


class SubNav extends React.Component {

    state = {
        menu: 'marketing'
    };

    changeFilter = (e) => {
        console.log(e.target.value);
        this.setState({
            menu: e.target.value
        }, cb => {
            this.props.history.push('/' + e.target.value);

        })
    };

    render() {
        return (
            <div>
                <Select
                    value={this.state.menu}
                    name='menu'
                    onChange={this.changeFilter}
                >
                    {
                        Object.keys(env.marketing.menu).map((key) => {
                            return <MenuItem key={key} value={key}>{env.marketing.menu[key]}</MenuItem>
                        })
                    }
                </Select>
            </div>
        );
    }
}

export default withRouter(SubNav);
