import React from 'react';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import SingleClientChip from "./SingleClientChip";

class Customize extends React.Component {

    state = {
        new_list: []
    };

    componentDidMount() {
        this.setState({
            new_list: this.props.list
        })
    }

    close = () => {
        this.props.close()
    };

    delete = (item) => {
        let updated_list = this.state.new_list.filter( r => {
            return r.id !== item.id;
        });

        this.setState({
            new_list: updated_list
        });
    };

    updateList = () => {
        this.props.update(this.state.new_list)
    };

    render() {
        return(
            <div className='customize-modal'>
                <Grid container className="mt-3 mb-4 text-center">
                    <Grid item xs={12} className="text-center">
                        <Button onClick={this.close} className='link' style={{color: '#bbb', float: 'right', cursor: 'pointer'}}>
                            <Icon >close</Icon>
                        </Button>
                        <h4 className='float-left'>Customize Selected Clients</h4>
                        <div className='clearfix'></div>

                        <div className='box-client box-customized'>
                            {
                                this.state.new_list.map((item, idx) => {
                                    return <SingleClientChip key={idx} idx={idx} client={item} managed={true} delete={this.delete} />
                                })
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="my-3">
                    <Grid item xs={6}>
                        <Button onClick={this.updateList}>
                            <Icon className="mr-2">flip_to_back</Icon>
                            Update List
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                        <Button onClick={this.close}  className='link gray-color text-decoration-none'>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Customize;
