import React from 'react';
import { withRouter} from 'react-router-dom';
import db from "../../../Firestore";
import Loading from "../../material-ui/Loading";
import {Paper} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Hidden from "@material-ui/core/Hidden";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import SingleLead from "./SingleLead";
import LeadDetails from "./LeadDetails";
import Icon from "@material-ui/core/Icon";
import Services from "../../../services/Services";

class Leads extends React.Component {

    state = {
        leads: [],
        loading: false,
        details: false,
        selected_lead: null,
        user: Services.getUser(),
        location: this.props.lc
    };

    componentDidMount() {
        this.getLeads();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        let location = nextProps.lc;
        let state_obj = {
            location: nextProps.lc,
        }

        if( this.state.details && this.state.selected_lead.location !== location){
            state_obj.details = false;
            state_obj.selected_lead = null
        }

        this.setState(state_obj, r => {
            this.getLeads('clear');
        })
    }

    getLeads = (str) => {

        str = str || null;

        this.setState({
            loading: true
        }, cb => {
            db.collection('/leads')
                .orderBy('created_at', 'desc')
                .where('location', '==', this.state.location)
                .onSnapshot( r => {

                    let leads = r.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        };
                    }).filter(r => !r.resolved);

                    this.setState({
                        leads: leads,
                        loading: false
                    }, cb =>{
                        if(!str && this.props.location.search !== '') this.isLeadDetails();
                    })
                })
        })
    };

    isLeadDetails  = () => {
        let lead_id = Services.getPramValue('ld', this.props.location.search);

        let new_list = [...this.state.leads];
        new_list = new_list.filter(r => r.id === lead_id);

        let my_ld = new_list[0];

        this.toggleDetails(my_ld, true);

        return lead_id !== null;
    };


    toggleDetails = (lead, details) => {

        lead = lead || null;
        details = details || null

        this.setState({
            details: details ? details : !this.state.details,
            selected_lead: lead
        })
    };

    goToClient = (id) => {
        this.props.goToClient(id);
    }

    render() {
        return (
            <div>
                <Paper className='p-3 mt-3'>
                    <h5 className='font-weight-normal mb-3'>
                        {
                            this.state.details ?
                                <div>
                                    <span onClick={this.toggleDetails} className='dgray-color notif-btn'>Leads</span>
                                    <Icon className="mr-2 icon-top7 gray-color">arrow_right</Icon>
                                    {this.state.selected_lead.name}
                                </div>
                                : <React.Fragment>
                                    Leads
                                    <span className='float-right'>{this.state.leads.length}</span>
                                </React.Fragment>
                        }

                    </h5>
                    {
                        this.state.loading ?
                            <Loading />
                            : this.state.details ?
                            <LeadDetails
                                lead={this.state.selected_lead}
                                owner={this.state.user.owner}
                                close={this.toggleDetails}
                                goToClient={this.goToClient}
                            />
                            : <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={60}></TableCell>
                                        <TableCell>Name</TableCell>
                                        <Hidden only="xs"><TableCell>Phone</TableCell></Hidden>
                                        <Hidden only="xs"><TableCell>Email</TableCell></Hidden>
                                        <Hidden only="xs"><TableCell>Service</TableCell></Hidden>
                                        <TableCell>Step</TableCell>
                                        {
                                            //this.state.user.owner ?
                                                <TableCell>Ref</TableCell>
                                                //:null
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.leads.map( (info) => {
                                            return <SingleLead
                                                key={info.id}
                                                lead={info}
                                                owner={this.state.user.owner}
                                                //select_client={this.selectClient}
                                                openLeadDetails={this.toggleDetails}
                                            />
                                        })
                                    }
                                </TableBody>
                            </Table>
                    }
                </Paper>
            </div>
        );
    }
}

export default withRouter(Leads);
