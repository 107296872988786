import React from 'react';
import Button from "@material-ui/core/Button";
import { withRouter} from 'react-router-dom';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Services from "../../services/Services";
import RowInfo from "../clients/RowInfo";

class AptReadOnly extends React.Component {

    state = {
        user: Services.getUser(),
        client: this.props.appointment.client,
        apt: this.props.appointment
    };

    clearRedirect = () => {
        this.setState({
            client: {},
            appt: {},
            loading: false
        });
        this.props.close();
    };

    componentDidMount() {
        this.setState({
            user: Services.getUser()
        })

        console.log(this.state);
    };

    goToClient = () => {
        this.props.goToClient(this.state.client.id);
    };

    render(){
        return(
            <Card>
                <CardContent>
                    <div>
                        <Button onClick={this.clearRedirect} className='col-xs-3 link float-right'>
                            <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                        </Button>
                        <h4 className={'col-xs-9 ' + (this.props.new ? 'form-title' : 'form-title tabs')} >
                            <span>
                               Appointment details
                            </span>
                        </h4>
                        <div className="clearfix"></div>
                        <RowInfo
                            icon={'person'}
                            value={this.state.client.name}
                        />

                        <RowInfo
                            icon={'spa'}
                            value={this.state.apt.service.cat_name + ' - ' + this.state.apt.service.name + (this.state.apt.service.flag ? ' (' + this.state.apt.service.flag +')' : '')}
                            text={'Service: '}
                        />

                        <RowInfo
                            icon={'date_range'}
                            value={moment(this.state.apt.start.toDate()).format('LLLL')}
                        />

                        <RowInfo
                            icon={'schedule'}
                            value={moment(this.state.apt.start.toDate()).format('LT') + ' to ' +  moment(this.state.apt.end.toDate()).format('LT')}
                        />

                        <Button variant="contained" onClick={this.goToClient} className='btn btn-secondary'>Full Profile</Button>

                    </div>
                </CardContent>
            </Card>
        )
    }
}

export default withRouter(AptReadOnly);