import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import CardSat from "../../material-ui/CardSat";
import Services from "../../../services/Services";
import PaymentTypeBadge from "../../shared/PaymentTypeBadge";

class SingleExpense extends React.Component {

    expense = this.props.expense;

    render() {
        return(
            <TableRow>
                <TableCell component="th" scope="row">
                    <h6 className='mt-1'>
                        <span className='badge badge-light'>
                            <CardSat
                                number={this.expense.amount}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                        </span>
                    </h6>
                </TableCell>
                <TableCell>
                    <PaymentTypeBadge data={this.expense.type} />
                </TableCell>
                <TableCell>
                    {Services.reformatString(this.expense.name)}
                </TableCell>
                <TableCell>
                    {this.expense.note ? Services.reformatString(this.expense.note) : ''}
                </TableCell>
                <TableCell>
                    {moment(this.expense.created_at.toDate()).format('LLL')}
                </TableCell>
            </TableRow>
        )
    }
}

export default SingleExpense;
