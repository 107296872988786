import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableLine from "./TableLine";
import {Paper} from "@material-ui/core";

class Hr extends React.Component {

    list = {
        hr: {
            head: ['Area', '1 time', '6 Times', 'Unlimited*'],
            line1: ['Upper Lip', 85, 300, 650],
            line2: ['Chin', 85, 300, 650],
            line3: ['Full Face', 125, 500, 850],
            line4: ['Armpits', 180, 700, 1150],
            line5: ['Forearms', 250, 800, 1300],
            line6: ['Full Arms', 325, 1300, 1995],
            line7: ['Bikini', 350, 1400, 2150],
            line8: ['Half Legs', 350, 1400, 2150],
            line9: ['Full Legs', 550, 2700, 4900],
            line10: ['Full Brazilian', 550, 2700, 4900]
        }
    };

    render() {
        return(
            <div>
                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableLine list={this.list.hr.head} head={true} />
                        </TableHead>
                        <TableBody>
                            <TableLine list={this.list.hr.line1} head={false} />
                            <TableLine list={this.list.hr.line2} head={false} />
                            <TableLine list={this.list.hr.line3} head={false} />
                            <TableLine list={this.list.hr.line4} head={false} />
                            <TableLine list={this.list.hr.line5} head={false} />
                            <TableLine list={this.list.hr.line6} head={false} />
                            <TableLine list={this.list.hr.line7} head={false} />
                            <TableLine list={this.list.hr.line8} head={false} />
                            <TableLine list={this.list.hr.line9} head={false} />
                            <TableLine list={this.list.hr.line10} head={false} />
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

export default Hr;
