import React from 'react';
import { withRouter} from 'react-router-dom';
import db from "../../../Firestore";
import Loading from "../../material-ui/Loading";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper} from "@material-ui/core";
import SingleFeedback from "./SingleFeedback";


class Feedback extends React.Component {

    state = {
        feedback: [],
        data:[],
        loading: false
    };

    componentDidMount() {
        this.getFeedbacks();
    }

    getFeedbacks = () => {

        this.setState({
            loading: true
        }, cb => {
            db.collection('/feedback')
                .orderBy('created_at', 'desc')
                .get()
                .then( r => {

                    let feedback = r.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        };
                    });

                    this.setState({
                        data: this.organizeData(feedback),
                        loading: false
                    }, cb => {
                        console.log(this.state)
                    })
                })
        })
    };

    organizeData = (items) => {

        return items.reduce((groups, item) => {

            let url = item.url;

            if (!groups[url]) {
                groups[url] = {
                    up: 0,
                    down: 0,
                    created_at: item.created_at,
                    id: item.id,
                    url: item.url
                };
            }

            if(item.fb){
                groups[url].up++
            }else{
                groups[url].down--
            }

            return groups;
        }, {});
    };


    render() {
        return (
            <div>
                <Paper className='p-3 mt-3'>
                    <h5 className='font-weight-normal mb-3'>
                        Feedback
                        <span className='float-right'>{this.state.data.length}</span>
                    </h5>
                    {
                        this.state.loading && this.state.data.length > 0 ?
                            <Loading />
                            : <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Link</TableCell>
                                        <TableCell>Feedback</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.keys(this.state.data).map( (key, index) => {
                                            return <SingleFeedback
                                                key={index}
                                                feedback={this.state.data[key]} />
                                        })
                                    }
                                </TableBody>
                            </Table>
                    }
                </Paper>
            </div>
        );
    }
}


export default withRouter(Feedback);
