import React from 'react';
import axios from "axios";

class Instagram extends React.Component {

    constructor(props){
        super();

        this._isMounted = false;
    }

    state = {
        imageUrl: ''
    };

    componentDidMount() {
        this.getPic();
    }

    getPic = () => {

        let ig = this.props.ig;

        if(ig){
            axios.get(`https://instagram.com/${ig}/?__a=1`).then(
                r => {
                    if(r.status === 200){
                        this.setState({
                            imageUrl: r.data.graphql.user.profile_pic_url,
                        });
                    }
                }
            ).catch(er => {

            })
        }
    };

    componentWillMount() {
        this._isMounted = false;
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.state.imageUrl !== '' ?
                        <a className="links" target="_blank" href={'https://instagram.com/' + this.props.ig} rel="noopener noreferrer">
                            <img src={this.state.imageUrl} alt='yes' className='avatar' width={this.props.w} />
                        </a>
                        : <img src="img/avatar.png" alt='avatar GG' className='avatar' width={this.props.w} />
                }
            </React.Fragment>
        )
    }
}

export default Instagram;
