import React from "react";
import Grid from "@material-ui/core/Grid";
import Instagram from "../material-ui/Instagram";
import NumberFormat from "react-number-format";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import db from "../../Firestore";
import Flag from "../material-ui/Flag";
import Services from "../../services/Services";
import {Icon} from "@material-ui/core";

class LeadInfo extends React.Component {

    state = {
        resolved: false
    };

    info = this.props.info;

    displayInfo = (info, label) => {
        let lbl = label || null;
        return info ?
            <span className="d-block">
                {lbl ? lbl + ': ' : null}
                {info}
            </span>
            : null
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: !JSON.parse(e.target.value)
        }, cb => {
            db.collection('/leads')
                .doc(this.info.id)
                .set(this.state, {merge: true})
        })
    };

    goToClient = (id) => {
        this.props.goToClient(id);
    };

    render() {
        return(
            <div>
                <Grid container className="mt-3 mb-4 text-center ld-dash">
                    <Grid item xs={12} className="text-center">
                        <Instagram ig={this.info.instagram} w={100} className="mb-2" />
                        {this.displayInfo(this.info.name)}
                        {this.displayInfo(this.info.email)}
                        <NumberFormat value={this.info.phone} displayType={'text'} format="(###) ###-####" />
                        {this.displayInfo(this.info.service + ' - ' + this.info.custom_service)}
                        {this.displayInfo(this.info.date + ' - ' + this.info.time)}
                        {this.displayInfo(this.info.referral, 'Referral')}
                        {this.displayInfo(this.info.price, 'Price')}
                        {this.displayInfo(this.info.code, 'Code')}
                        {this.displayInfo(this.info.returning ? 'Yes' : 'No', 'Existing Customer')}
                        {
                            (this.props.owner && this.info.aff)
                            || (!this.props.owner && Services.showAffiliate(this.info.aff)) ?//this.props.owner
                                <Flag flag={this.info.aff} color={'badge-warning'}/>
                                : null
                        }

                        {
                            this.info.comment && this.info.comment !== '' ?
                                <React.Fragment>
                                    <p className='lead-comment'>
                                        <Icon className='flag-icon comment-icon'>chat</Icon>
                                        {this.info.comment}
                                    </p>
                                </React.Fragment>  : null
                        }

                        <span className="mt-3 gray-color d-block">
                            <span className="float-left">Lead received at: {moment(this.info.created_at.toDate()).format('LLL')}</span>
                            <span className="float-right">Step: {this.info.step}</span>
                        </span>
                        <div className='clearfix'></div>
                        <FormControlLabel className='row' control={
                            <Switch
                                checked={this.state.resolved}
                                onChange={this.handleChange}
                                value={this.state.resolved}
                                name="resolved"
                            />
                        } label="Resolve this Lead" />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default LeadInfo;
