import React, { Component } from 'react'
import db from "../../Firestore";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

class SearchInput extends Component {
    state = {
        query: '',
        results: [],
        selectedItem: {},
        client_selected: this.props.status,
        empty_message: false
    };

    getInfo = () => {
        let keyword = this.state.query[0] ? this.state.query[0].toLocaleUpperCase() + this.state.query.slice(1) : '';
        db.collection('/clients').where('first_name', '==', keyword).get().then(res => {

            this.setState({
                results: res.docs.map(doc => {
                    return {
                        ...doc.data(),
                        uid: doc.id,
                    }
                }),
                empty_message: res.docs.length === 0
            });
        })
    };

    handleInputChange = e => {
        this.setState({
            query: e.target.value
        });

        if(e.target.value === ''){
            this.setState({
                empty_message: false
            })
        }
    };

    _handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.getInfo()
            /*let button = document.getElementById('search_client');
             if (button.onclick) {
                 button.onclick();
             } else if (button.click) {
                 button.click();
             }*/
        }
    };

    handleClose = (item) => {
        this.props.client(item);
        this.setState({
            selectedItem: item,
            results: [],
            client_selected: !this.status
        });
    };

    toggleStatus = () => {
        this.props.client({});
        this.setState({
            client_selected: !this.state.client_selected,
            selectedItem: {},
            query: ''
        });
    };

    componentDidMount() {
        if(this.props.status){
            this.setState({
                client_selected: this.props.status,
                selectedItem: {
                    first_name: this.props.selectedClient.name,
                    id: this.props.selectedClient.id
                }
            })
        }
    }

    render() {
        return (
            <div>
                {!this.state.client_selected ?
                    <TextField
                        placeholder="Search for client..."
                        value={this.state.query}
                        name='search'
                        onChange={this.handleInputChange}
                        onKeyDown={this._handleKeyDown}
                    />: ''}
                {!this.state.client_selected ?
                    <Button color='secondary' id="search_client" onClick={this.getInfo}>
                        <Icon className="gray-color">search</Icon>
                    </Button>
                    : ''}

                {(!this.state.client_selected && this.state.results.length > 0) || (!this.state.client_selected && this.state.empty_message) ?
                    <Paper id="menu-list-grow">
                        {this.state.empty_message ?
                            <p className="p-2 dgray-color">
                                <Icon style={{ fontSize: 13 }} className="mr-2">arrow_forward_ios</Icon>
                                No such a client!</p>
                            : <MenuList>
                                {this.state.results.map(r => (
                                    <MenuItem key={r.uid} onClick={(event) => this.handleClose(r)}>
                                        {r.first_name} {r.last_name}
                                    </MenuItem>

                                ))}
                            </MenuList>
                        }
                    </Paper>
                    : ''}
                {this.state.client_selected ?
                    <Grid container>
                        <Grid item xs={12}>
                            <span className="dgray-color">Selected client:</span> <b>{this.state.selectedItem.first_name} {this.state.selectedItem.last_name}</b>
                            {
                                this.props.change || this.props.user?
                                    <Button color='secondary' onClick={this.toggleStatus}>
                                        <Icon className="gray-color mr-2">refresh</Icon> Change Client
                                    </Button>: null
                            }
                        </Grid>
                    </Grid>
                    : ''}
            </div>
        )
    }
}

export default SearchInput;
