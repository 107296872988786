import React from 'react';
import db from "../../Firestore";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { withRouter} from 'react-router-dom';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import env from "../../conf";
import {Select} from "@material-ui/core";
import DatePicker from "react-datepicker";
import moment from "moment";
import Services from "../../services/Services";
import Loading from "../material-ui/Loading";


class ClientForm extends React.Component {

    state = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        instagram: '',
        birthday: '',
        rewards_count: 0,
        has_rewards: false,
        member: false,
        membership: 'classic_1rf',
        generation: 'second',
        membership_date: moment().toDate(),
        membership_credits: 1,
        membership_price: 0,
        subscribed_sms: true,
        subscribed_email: true,
        user: Services.getUser(),
        compLoading: false,
        location: Services.getLocation(),
        created_from: Services.getLocation()
    };

    status = this.props.match.path;
    local_client = {};
    loading = false;

    clientRef = db.collection('/clients');

    /**
     * checking if the current path is add/update client
     * @returns {boolean}
     */
    checkIfNewClient = () => {
        return this.status === '/clients/add';
    };

    getLead = () => {
       return JSON.parse(localStorage.getItem('lead_info'));
    }

    /**
     * clearing the localstorage and redirect
     */
    clearRedirect = () => {
        localStorage.removeItem('client');
        localStorage.removeItem('lead_info');
        this.props.history.push('/clients');
    };

    componentDidMount() {

        // checking if adding new client path
        if(!this.checkIfNewClient()){

            this.setState({
                compLoading: true
            }, cb => {

                // getting the local client
                this.local_client = JSON.parse(localStorage.getItem('client'));
                //console.log('local client', this.local_client)

                // checking if current key matches the local client key
                if(this.local_client.uid === this.props.match.params.id){

                    let localCl = {...this.local_client};
                    localCl.rewards_count = parseInt(localCl.rewards_count);
                    localCl.membership_credits = localCl.membership_credits ? parseInt(localCl.membership_credits) : 1;
                    localCl.membership_price = localCl.membership_price ? parseFloat(localCl.membership_price) : 0;
                    localCl.membership_date = localCl.membership_date ? moment(localCl.membership_date).toDate() : moment().toDate();
                    this.setState({
                        ...localCl,
                        compLoading: false
                    }, cb => {
                        localStorage.removeItem('client')
                    })

                }else{
                    // if not clear the local client and send back to the client list
                    this.clearRedirect();
                }
            })

        }else{
            let lead_info = this.getLead();

            if(lead_info){
               this.setState({
                   first_name: Services.reformatString(lead_info.name.split(' ')[0]),
                   last_name: lead_info.name.split(' ')[1] ? Services.reformatString(lead_info.name.split(' ')[1]) : '',
                   phone: lead_info.phone,
                   instagram: lead_info.instagram ? lead_info.instagram : '',
                   email: lead_info.email,
                   created_from: lead_info.location
               })
            }
        }
    }

    /**
     * handling changes in inputs
     * @param event
     */
    handleChange = event => {
        let new_value;
        let name = event.target.name;
        if(name === 'has_rewards' || name === 'member' || name === 'subscribed_email' || name === 'subscribed_sms'){
            new_value = !JSON.parse(event.target.value);
        } else {
            new_value = event.target.value
        }

        this.setState({
            [name]: new_value
        });
    };

    handleDate = (value) => {
        this.setState({
            membership_date: value
        });
    };

    /**
     * submitting the form
     */
    handleSubmit = () => {
        this.loading = true;

        // adding a new client
        if(this.checkIfNewClient()){

            this.setState({
                ...this.state,
                redeemed: 0,
                rewards_count: 0,
                created_at: new Date()
            });

            delete this.state.user;
            delete this.state.location;

            this.clientRef.add(this.state).then(() => {
                let lead = this.getLead();
                if(lead){
                    db.collection('leads')
                        .doc(lead.id)
                        .set({
                            resolved: true
                        }, {merge: true})
                }

                this.loading = false;
                this.clearRedirect()
            })

        }
        // updating an existing client
        else{

            this.setState({
                rewards_count: parseInt(this.state.rewards_count),
                membership_credits: parseInt(this.state.membership_credits),
                membership_price: parseFloat(this.state.membership_price)
            });

            let client_id = this.props.match.params.id;

            // deleting some properties to not overwrite the old ones
            // TODO change the concept to have ability to update these fields for certain users only

            delete this.state.redeemed;


            // delete this.state.rewards_count;

            delete this.state.created_at;
            delete this.state.upcoming_appt;
            delete this.state.last_visit;
            delete this.state.user;
            delete this.state.created_from;
            delete this.state.location;

            this.clientRef.doc(client_id).set(this.state, {merge: true}).then(() => {
                this.loading = false;
                this.clearRedirect();
            })
        }
    };

    render(){
        return(
            <Container maxWidth="sm">
                <div className="new-client mt-5">
                    <Card>
                        {
                            this.state.compLoading ?
                                <Loading />
                                : <CardContent>
                                    <span onClick={this.clearRedirect} className='notif-btn'>
                                        <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                                    </span>
                                    <h4 className="form-title">
                                        {this.checkIfNewClient() ? 'Add New Client' : 'Update '+ this.state.first_name + '\'s Info'}</h4>
                                    <div className="clearfix"></div>

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                        onError={errors => console.log(errors)}
                                    >
                                        <TextValidator
                                            label="First name"
                                            onChange={this.handleChange}
                                            name="first_name"
                                            value={this.state.first_name}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            className='row'
                                        />
                                        <TextValidator
                                            label="Last name"
                                            onChange={this.handleChange}
                                            name="last_name"
                                            value={this.state.last_name}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            className='row'
                                        />
                                        <TextValidator
                                            label="Phone"
                                            onChange={this.handleChange}
                                            name="phone"
                                            value={this.state.phone}
                                            validators={['required', 'isNumber']}
                                            errorMessages={['this field is required', 'This has to be a number']}
                                            className='row'
                                        />
                                        <
                                            TextValidator
                                            label="Email"
                                            onChange={this.handleChange}
                                            name="email"
                                            value={this.state.email}
                                            className='row'
                                        />
                                        {
                                            this.state.user.owner
                                            || (!this.state.user.owner && this.local_client.instagram === '') ?
                                                <TextValidator
                                                    label="Instagram"
                                                    onChange={this.handleChange}
                                                    name="instagram"
                                                    value={this.state.instagram}
                                                    className='row'
                                                /> : null
                                        }
                                        <TextValidator
                                            label="Birthday"
                                            onChange={this.handleChange}
                                            name="birthday"
                                            value={this.state.birthday}
                                            className='row'
                                            validators={['matchRegexp:^(0?[1-9]|1[0-2])/(0?[1-9]|[12][0-9]|3[01])$']}
                                            errorMessages={['wrong format, use ex: 07/16']}
                                        />
                                        {
                                            !this.checkIfNewClient() ?
                                                <TextValidator
                                                    label="Rewards"
                                                    onChange={this.handleChange}
                                                    name="rewards_count"
                                                    value={this.state.rewards_count}
                                                    className='row'
                                                />
                                                : ''
                                        }

                                        <Grid container className="my-3">
                                            <Grid item xs={6} className="dgray-color">
                                                <FormControlLabel className='row' control={
                                                    <Switch
                                                        checked={this.state.subscribed_email}
                                                        onChange={this.handleChange}
                                                        value={this.state.subscribed_email}
                                                        name="subscribed_email"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                } label="Subscribe for Emails?" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel className='row' control={
                                                    <Switch
                                                        checked={this.state.subscribed_sms}
                                                        onChange={this.handleChange}
                                                        value={this.state.subscribed_sms}
                                                        name="subscribed_sms"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                } label="Subscribe for SMS?" />
                                            </Grid>
                                        </Grid>
                                        {
                                            this.state.user.owner ? // only owner can change membership level
                                                <React.Fragment>
                                                    <Grid container className="my-3">
                                                        <Grid item xs={6} className="dgray-color">
                                                            <FormControlLabel className='row' control={
                                                                <Switch
                                                                    checked={this.state.has_rewards}
                                                                    onChange={this.handleChange}
                                                                    value={this.state.has_rewards}
                                                                    name="has_rewards"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            } label="Enable Rewards?" />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel className='row' control={
                                                                <Switch
                                                                    checked={this.state.member}
                                                                    onChange={this.handleChange}
                                                                    value={this.state.member}
                                                                    name="member"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            } label="Member?" />
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        this.state.member ?
                                                            <React.Fragment>
                                                                <Grid container>
                                                                    <Grid item xs={6} className="dgray-color">
                                                                        <span className='d-block'>
                                                                            Membership Package
                                                                        </span>
                                                                        <Select
                                                                            value={this.state.membership}
                                                                            name='membership'
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            {
                                                                                Object.keys(env.membership).map((key, idx) => {
                                                                                    return <MenuItem key={idx} value={key}>
                                                                                        {env.membership[key].name}
                                                                                    </MenuItem>
                                                                                })
                                                                            }

                                                                        </Select>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <span className='d-block'>
                                                                            Start Date
                                                                        </span>
                                                                        <DatePicker
                                                                            selected={this.state.membership_date}
                                                                            onChange={this.handleDate}
                                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container className='mt-3'>
                                                                    <Grid item xs={6} className="dgray-color">
                                                                        <span className='d-block'>
                                                                            Price
                                                                        </span>
                                                                        <TextValidator
                                                                            label=""
                                                                            onChange={this.handleChange}
                                                                            name="membership_price"
                                                                            value={this.state.membership_price}
                                                                            className='row pr-3'
                                                                            validators={['required', env.accounting.decimalValidator]}
                                                                            errorMessages={['This field is required', 'This is not a valid number']}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <span className='d-block'>
                                                                            Monthly Credits
                                                                        </span>
                                                                        <TextValidator
                                                                            label=""
                                                                            onChange={this.handleChange}
                                                                            name="membership_credits"
                                                                            value={this.state.membership_credits}
                                                                            className='row pr-3'
                                                                            validators={['required', 'isNumber']}
                                                                            errorMessages={['This field is required', 'This is not a valid number']}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </React.Fragment>
                                                            : null
                                                    }
                                                </React.Fragment> : null
                                        }

                                        <Grid container className="mt-5">
                                            <Grid item xs={4}>
                                                <Button type="submit" variant="contained" className="primary-btn">
                                                    <Icon className="mr-2">{this.checkIfNewClient() ? 'add' : 'refresh'}</Icon>
                                                    {this.checkIfNewClient() ? 'Add' : 'Update'}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={8} className="text-right p-2">
                                                <span onClick={this.clearRedirect} className='link gray-color text-decoration-none notif-btn'>
                                                    Cancel
                                                </span>
                                                <small>{this.loading ? 'Loading...' : ''}</small>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </CardContent>
                        }
                    </Card>
                </div>
            </Container>
        )
    }
}

export default withRouter(ClientForm);