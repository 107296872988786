import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Services from "../services/Services";
import AccList from "../components/accounting/AccList";
import AddService from "../components/accounting/AddService";
import AddExpense from "../components/accounting/AddExpense";
import Loading from "../components/material-ui/Loading";

class Accounting extends React.Component {

    componentWillReceiveProps(nextProps, nextContext) {
        Services.checkIfLoggedIn(nextProps.user, this.props.history, '', false);
    }

    goToClientDetails = (id) => {
        this.props.history.push('/clients?cl=' + id);
        console.log('get to accounting', id)
    };

    render(){
        return (
            this.props.user != null ?
                <Router>
                    <Route exact path='/accounting' render={(props) =>
                        <AccList
                            {...props}
                            numbers={this.props.numbers}
                            goToClient={this.goToClientDetails}
                            lc={this.props.lc}
                        />} />
                    <Route path='/accounting/add-service' component={AddService} />
                    <Route path='/accounting/add-expense' component={AddExpense} />
                </Router>
                : <Loading />
        );
    }
}


export default Accounting;
