import React from 'react';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import env from "../../conf";
import {Select} from "@material-ui/core";
import db from "../../Firestore";
import CampaignClients from "./CampaignClients";
import Loading from "../material-ui/Loading";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import Customize from "./Customize";
import PreviewCamp from "./PreviewCamp";
import Services from "../../services/Services";

class SendSms extends React.Component {

    state = {
        title: '',
        content: '',
        campaign_cat: 0,
        client_type: '',
        selected_clients: [],
        loading: false,
        modal: false,
        preview: false,
        customize: false
    };

    main_path = '/campaigns';
    campaign_for = env.marketing.sms_categories;
    client_ref = db.collection('/clients');
    lead_ref = db.collection('/leads');

    componentDidMount() {

    }

    handleChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleClientChange = (e) => {
        this.setState({
            client_type: e.target.value,
            loading: true
        }, cb => {
            this.getClients();
        })
    };

    handleSubmit = () => {

    };

    getClients = () => {
        switch (this.state.client_type) {

            case 0:
                this.getAllClients();
                break;

            case 1:
                this.getMembers(true);
                break;

            case 2:
                this.getMembers(false);
                break;

            case 3:
                this.getLeads();
                break;

            case 4:
                this.getCustom();
                break;

            default:
                break;
        }
    };

    getAllClients = () => {
        this.client_ref
            .orderBy('first_name')
            .where('subscribed_sms', '==', true)
            .get()
            .then(res => {
                this.saveClients(res.docs);
            });
    };

    getMembers = status => {

        this.client_ref
            .orderBy('first_name')
            .where('member', '==', status )
            .where('subscribed_sms', '==', true)
            .get()
            .then(res => {
                this.saveClients(res.docs);
            });
    };

    getCustom = () => {
    };

    getLeads = (step) => {

        step = step || null;

        this.lead_ref
            .orderBy('created_at', 'desc')
            .where('subscribed_sms', '==', true)
            .get()
            .then(res => {

                // filter by resolved: true and steps if exist
                let leads = res.docs.filter(r => {

                    let ld = r.data();

                    return step === null ? !ld.paid : !ld.paid && (!ld.paid && r.step === step);
                });

                this.saveClients(leads, 'lead');
            });
    };

    saveClients = (client_list, status) => {

        status = status || null;

        let new_clientList = client_list.map(doc => {

            let info = doc.data();

            return {
                name: status === 'lead' ? info.name : info.first_name + ' ' + info.last_name,
                phone: Number(info.phone),
                id: doc.id,
                instagam: status === 'lead' ? null : info.instagram
            }

        }).filter(r => {
            return r.phone && r.phone !== '' && r.phone !== 1 && r.phone !== 0
        });

        if(status === 'lead'){
            new_clientList = Services.removeDuplicates(new_clientList, 'phone');
        }

        this.setState({
            selected_clients: new_clientList,
            loading: false
        });
    };

    handleModalClose = e => {
        this.setState({
            modal: false,
            preview: false,
            customize: false
        })
    };

    openCustom = () => {
        this.setState({
            modal: true,
            customize: true
        })
    };

    updateList = (new_list) => {
        this.setState({
            selected_clients: new_list,
            modal: false,
            customize: false,
            client_type: 4
        })
    };

    preview = () => {
        if(this.state.client_type === ''){
            alert('Select client list');
        }else{
            this.setState({
                preview: true,
                modal: true
            });
        }

    };

    render() {
        return(
            <div>

                <Container maxWidth="xl">
                    <div className="new-client mt-5">
                        <Card>
                            <CardContent>
                                <NavLink to={this.main_path} className='link'>
                                    <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                                </NavLink>
                                <h4 className="form-title">New SMS</h4>

                                <div className="clearfix"></div>

                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={errors => console.log(errors)}
                                >

                                    <Grid container className="mt-3 mb-4 text-center">
                                        <Grid item xs={12} sm={6}>
                                            <div className='text-left'>
                                                <span className='mr-3'>Campaign Type</span>

                                                <Select
                                                    value={this.state.campaign_cat}
                                                    name='campaign_cat'
                                                    onChange={this.handleChange}
                                                >
                                                    {
                                                        this.campaign_for.map( (item, index) => {
                                                            return <MenuItem key={index} value={index}>{item}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </div>

                                            <TextValidator
                                                label="Title"
                                                onChange={this.handleChange}
                                                name="title"
                                                value={this.state.title}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                className='row'
                                            />


                                            <TextValidator
                                                label="SMS Content"
                                                onChange={this.handleChange}
                                                name="content"
                                                value={this.state.content}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                className='row'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className='pl-0 pl-sm-4 mt-3 mt-sm-0'>

                                                <div className='text-left'>
                                                    <span className='mr-3'>Client</span>

                                                    <Select
                                                        value={this.state.client_type}
                                                        name='client_type'
                                                        onChange={this.handleClientChange}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select clients
                                                        </MenuItem>
                                                        {
                                                            env.marketing.sms_clients_type.map( (itm, idx) => {
                                                                return <MenuItem key={idx} value={idx}>{itm}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                    {
                                                        this.state.client_type !== '' ?
                                                            <span className='ml-2'>{this.state.selected_clients.length} clients found</span> : null
                                                    }
                                                </div>

                                                {
                                                    this.state.client_type !== '' ?
                                                        this.state.loading ?
                                                            <Loading />
                                                            : <CampaignClients list={this.state.selected_clients} customize={this.openCustom} />
                                                        : null
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="mt-5">
                                        <Grid item xs={4}>
                                            <Button type="submit" variant="contained" className="primary-btn" onClick={this.preview}>
                                                <Icon className="mr-2">remove_red_eye</Icon>
                                                Preview
                                            </Button>
                                        </Grid>
                                        <Grid item xs={8} className="text-right p-2">
                                            <NavLink to={this.main_path} className='link gray-color text-decoration-none'>
                                                Cancel
                                            </NavLink>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </CardContent>
                            <CardActions>

                            </CardActions>
                        </Card>


                    </div>
                </Container>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modal && (this.state.preview || this.state.customize)}
                    onClose={this.handleModalClose}
                >
                    <DialogContent className='modal-big'>
                        {
                            this.state.preview ?
                                <PreviewCamp close={this.handleModalClose} data={this.state} />
                                : <Customize close={this.handleModalClose} list={this.state.selected_clients} update={this.updateList} />
                        }
                    </DialogContent>
                </Modal>
            </div>
        )
    }

}

export default SendSms;
