import React from 'react';
import PieChart from 'react-minimal-pie-chart';
import env from './../../conf';
import Grid from "@material-ui/core/Grid";


class Charts extends React.Component {

    chart = env.chart;


    render() {
        return (
            <Grid item xs={6}>
                {
                    this.props.condition ?
                        <div>
                            <PieChart
                                data={this.props.numbers}
                                style={{height: this.chart.height}}
                                label={({ data, dataIndex }) => data[dataIndex].label }
                                labelStyle={{
                                    fontSize: '5px',
                                    fontFamily: 'sans-serif',
                                    fill: '#000'
                                }}
                                radius={42}
                                labelPosition={112}
                                animate
                            />

                        </div>
                        : <div className="emptyPie">Nothing to show Yet!</div>
                }
            </Grid>
        )
    }
}

export default Charts;
