import React from 'react';
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import RowInfo from "../../clients/RowInfo";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import env from "../../../conf";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Modal from "@material-ui/core/Modal";
import db from "../../../Firestore";
import Services from "../../../services/Services";

class CardDetails extends React.Component {

    card = this.props.card;

    state = {
        open_log_form: false,
        items: '',
        total: '',
        user: Services.getUser()
    };

    componentDidMount() {
        this.setState({
            user: Services.getUser()
        })
    }

    toggleRedeem = () => {
        this.setState({
            open_log_form: !this.state.open_log_form,
        })
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    redeem = () => {

        console.log(this.state);

        let redemption = {
            card: this.card,
            items: this.state.items,
            total: parseFloat(this.state.total).toFixed(2),
            balance: this.card.amount - this.state.total,
            created_at: new Date()
        };

        console.log(redemption);

        db.collection('/gift-redemption')
            .add(redemption)
            .then(r => {
                console.log('added to redemtpion table');
                this.toggleRedeem();
            }).catch(er => {
            console.log('error from adding to redemption table')
        })
    };

    render() {
        return(
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <RowInfo icon={'person'} value={'From ' + this.card.from + ' <' + this.card.from_email + '>'} />
                        <RowInfo icon={'person'} value={'To ' + this.card.to + ' <' + this.card.to_email + '>'} />
                        <RowInfo icon={'card_giftcard'} value={'Gift Card # :' + this.card.id} />
                        <RowInfo icon={'credit_card'} value={'Amount : $' + this.card.amount} />
                        <RowInfo icon={'message'} value={'Message : "' + this.card.message + '"'} />
                        <RowInfo icon={'calendar_today'} value={moment(this.card.created_at.toDate()).format('LLL') + '"'} />
                    </Grid>
                    {
                        this.state.user.owner ?
                            <Grid item xs={12} sm={6}>
                                <Button onClick={() => this.toggleRedeem()} variant="contained" className="secondary-btn">Redeem</Button>
                            </Grid>
                            : null
                    }
                </Grid>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open_log_form}
                    onClose={() => this.toggleRedeem()}
                >
                    <DialogContent style={env.modal_style}>


                        <Grid container className="mt-3 mb-4 text-center">
                            <h4 className='text-center'>Redeem</h4>
                            <Grid item xs={12}>
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={() => this.redeem()}
                                    onError={errors => console.log(errors)}
                                >
                                    <TextValidator
                                        label="items"
                                        onChange={this.handleChange}
                                        name="items"
                                        value={this.state.items}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className='row'
                                        multiline
                                        rows={2}
                                        rowsMax={2}
                                    />
                                    <TextValidator
                                        label="total"
                                        onChange={this.handleChange}
                                        name="total"
                                        value={this.state.total}
                                        validators={['required', env.accounting.decimalValidator]}
                                        errorMessages={['This field is required', 'not a valid value']}
                                        className='row'
                                        multiline
                                    />
                                </ValidatorForm>
                            </Grid>

                            <Grid item xs={12} className='mt-3'>
                                <Button type='submit' onClick={() => this.redeem()} variant="contained" className="primary-btn">Add Log</Button>
                                <Button onClick={() => this.toggleRedeem()} className="secondary-btn">Cancel</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Modal>
            </div>
        )
    }
}

export default CardDetails;
