import React from 'react';
import Services from "../services/Services";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import db from "../Firestore";


class Settings extends React.Component{

    state = {
        auto_hbd: true,
        auto_reminder_1: true, // 3 days reminder
        auto_reminder_2: true, //one day reminder
        update_appt_wa: true,
        new_appt_wa: true
    }

    componentDidMount() {
        Services.checkIfLoggedIn(this.props.user, this.props.history, '/home', false);
        db.collection('settings')
            .doc('emails')
            .onSnapshot(r => {
                let settings = r.data()
                this.setState(settings)
            })
    }

    handleSwitch = (e) => {
        let name = [e.target.name];
        let val = !JSON.parse(e.target.value);

        this.setState({
            [name]: val,
        }, cb => {
            db.collection('settings')
                .doc('emails')
                .set({[name]: val}, {merge: true})
                .then(() => {console.log('name updated', val)});
        });
    };


    render(){
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Paper className='p-3 mt-3'>
                            <h5>Emails</h5>
                            <FormControlLabel className='row' control={
                                <Switch
                                    checked={this.state.new_appt_wa}
                                    onChange={this.handleSwitch}
                                    value={this.state.new_appt_wa}
                                    name="new_appt_wa"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                />
                            } label="Creating new appointment?"
                            />
                            <FormControlLabel className='row' control={
                                <Switch
                                    checked={this.state.update_appt_wa}
                                    onChange={this.handleSwitch}
                                    value={this.state.update_appt_wa}
                                    name="update_appt_wa"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                />
                            } label="Updating an appointment?"
                            />
                            <FormControlLabel className='row' control={
                                <Switch
                                    checked={this.state.auto_reminder_2}
                                    onChange={this.handleSwitch}
                                    value={this.state.auto_reminder_2}
                                    name="auto_reminder_2"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                />
                            } label="1 day reminder?"
                            />
                            <FormControlLabel className='row' control={
                                <Switch
                                    checked={this.state.auto_reminder_1}
                                    onChange={this.handleSwitch}
                                    value={this.state.auto_reminder_1}
                                    name="auto_reminder_1"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                />
                            } label="3 days reminder?"
                            />
                            <FormControlLabel className='row' control={
                                <Switch
                                    checked={this.state.auto_hbd}
                                    onChange={this.handleSwitch}
                                    value={this.state.auto_hbd}
                                    name="auto_hbd"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                />
                            } label="Happy birthday?"
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Settings;
