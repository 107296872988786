import React from 'react';
import {Icon} from "@material-ui/core";
import db from "../../Firestore";

class SingleNotification extends React.Component {

    state = {
        load: false
    };

    notification = this.props.notification;

    deleteNotofication = (e) => {
        e.preventDefault();
        this.setState({
            load: true
        }, cb => {
            db.collection('/notifications')
                .doc(this.notification.id)
                .delete()
                .then(r => {
                    this.setState({
                        load: false
                    })
                })
        })
    };

    clickName = (id) => {
        this.props.toggle()
        this.props.clickName(id);
    };

    render() {
        return(
            <span className="notif-item">
            {
                this.state.load ?
                    <span className="text-center">Loading</span>
                    : <span>
                        Add <b>
                                <span onClick={e => this.clickName(this.notification.data.client.id)} className="notif-btn">
                                    {this.notification.data.client.name}
                                </span>
                            </b> next appointment
                        <span onClick={this.deleteNotofication} className="float-right delete-notif gray-color"><Icon>check</Icon></span>
                    </span>
            }
            </span>
        )
    }
}

export default SingleNotification;
