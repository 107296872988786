import React from "react";
import Grid from "@material-ui/core/Grid";
import CardSat from "../material-ui/CardSat";
import Services from "../../services/Services";
import Paper from "@material-ui/core/Paper";
import Divider from '@material-ui/core/Divider';
import MembersAct from "./MembersAct";

class AccInsightStats extends React.Component {

    render() {
        return(
            <div className="px-3">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Paper className='px-3 pt-3 pb-2'>
                            <CardSat
                                number={this.props.incomes_count}
                                label={'Gross Income'}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                            <CardSat
                                number={this.props.expenses_count}
                                label={'Expenses'}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                            <Divider className="mb-2" />
                            <CardSat
                                number={Services.subscract(this.props.incomes_count, this.props.expenses_count)}
                                label={'Net Income'}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className='px-3 pt-3 pb-2'>
                            <CardSat
                                number={this.props.total_clients}
                                label={'Served Clients'}
                                prefix={false}
                                showNum={this.props.numbers}
                            />
                            <CardSat
                                number={this.props.total_services}
                                label={'Total Services'}
                                prefix={false}
                                showNum={this.props.numbers}
                            />
                            <Divider className="mb-2" />
                            <CardSat
                                number={this.props.total_services / this.props.total_clients}
                                label={'Average Service/Client'}
                                prefix={false}
                                showNum={this.props.numbers}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <Paper className='px-3 pt-3 pb-2'>
                            <CardSat
                                number={Services.getAveragePerDay(this.props.from, this.props.to, this.props.total_services)}
                                label={'Average Service/Day'}
                                prefix={false}
                                showNum={this.props.numbers}
                            />
                            <CardSat
                                number={Services.formatPrice(this.props.incomes_count / this.props.total_services)}
                                label={'Average Service Price'}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                            <Divider className="mb-2" />
                            <CardSat
                                number={Services.getAveragePerDay(this.props.from, this.props.to, this.props.incomes_count)}
                                label={'Daily Gross Income'}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                            <CardSat
                                number={Services.getAveragePerDay(this.props.from, this.props.to, this.props.incomes_count - this.props.expenses_count)}
                                label={'Daily Net Income'}
                                prefix={true}
                                showNum={this.props.numbers}
                            />
                        </Paper>
                    </Grid>
                    <MembersAct numbers={this.props.numbers} />

                </Grid>
            </div>
        )
    }
}

export default AccInsightStats;
