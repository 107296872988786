import React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {NavLink} from "react-router-dom";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import {Select} from "@material-ui/core";
import env from "../../conf";
import db from "../../Firestore";
import Services from "../../services/Services";


class AddExpense extends React.Component {

    state = {
        name: '',
        amount: '',
        note: '',
        type: 0,
        location: Services.getLocation()
    };

    loading = false;

    handleSubmit = () => {
        this.loading = true;

        let expense = {
            ...this.state,
            created_at: new Date()
        };

        expense.amount = parseFloat(expense.amount);

        db.collection('/expenses').add(expense).then(() => {
            this.loading = false;
            this.props.history.push('/accounting');
        })

    };

    clearRedirect = () => {
        this.props.history.push('/accounting');
    };

    handleChange = event => {

        this.setState({
            [event.target.name]: event.target.value
        });

    };

    render() {
        return(
            <Container maxWidth="sm">
                <div className="new-expense mt-5">
                    <Card>
                        <CardContent>
                            <NavLink to='/accounting' className='link'>
                                <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                            </NavLink>
                            <h4 className="form-title">
                                Add New Expense</h4>
                            <div className="clearfix"></div>

                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={errors => console.log(errors)}
                            >
                                <TextValidator
                                    label="Name"
                                    onChange={this.handleChange}
                                    name="name"
                                    value={this.state.name}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    className='row'
                                />
                                <TextValidator
                                    label="Amount"
                                    onChange={this.handleChange}
                                    name="amount"
                                    value={this.state.amount}
                                    validators={['required', env.accounting.decimalValidator]}
                                    errorMessages={['This field is required', 'not a valid value']}
                                    className='row'
                                />
                                <TextValidator
                                    label="Note"
                                    onChange={this.handleChange}
                                    name="note"
                                    value={this.state.note}
                                    errorMessages={['this field is required', 'This has to be a number']}
                                    className='row'
                                />
                                <div className="mt-3">
                                    <span className="mt-2 mr-2">Paid with:</span>
                                    <span className="mt-3 mr-2 icon-list">
                                        <Icon className='orange icon-list'>
                                            { this.state.type === 0 ? 'attach_money' : 'credit_card'}</Icon>
                                    </span>
                                    <Select
                                        value={this.state.type}
                                        name='type'
                                        onChange={this.handleChange}
                                    >
                                        {
                                            env.accounting.expense_types.map((item, idx) => {
                                                return <MenuItem key={idx} value={idx}>{item}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </div>

                                <Grid container className="mt-5">
                                    <Grid item xs={4}>
                                        <Button type="submit" variant="contained" className="primary-btn">
                                            <Icon className="mr-2">add</Icon>
                                            Add
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8} className="text-right p-2">
                                        <NavLink to='/accounting' className='link gray-color text-decoration-none'>
                                            Cancel
                                        </NavLink>
                                        <small>{this.loading ? 'Loading...' : ''}</small>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        )
    }
}

export default AddExpense;
