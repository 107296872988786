import React from "react";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Instagram from "../material-ui/Instagram";
import Icon from "@material-ui/core/Icon";
import RowInfo from "./RowInfo";
import Button from "@material-ui/core/Button";
import db from "../../Firestore";
import Appointments from "./Appointments";
import Divider from '@material-ui/core/Divider';
import env from "../../conf";
import moment from "moment";
import EditButton from "../shared/EditButton";
import Subscriptions from "./Subscriptions";
import Services from "../../services/Services";
import Balance from "./Balance";
import Flag from "../material-ui/Flag";

class ClientDetails extends React.Component {

    client = this.props.client;
    membership_date = this.client.membership_date ? moment(this.client.membership_date.toDate()).format("LL") : '';
    membership_day = this.client.membership_date ? moment(this.client.membership_date.toDate()).format('Do') : '';
    state = {
        upcoming: [],
        past: [],
        retails: {},
        loading: {
            retail: false
        },
        user: Services.getUser(), // TODO dusplicated instance, bring it using propss
        credit: 0
    };

    apptRef = db.collection('/appoitments');

    goBack = () => {
        this.props.back()
    };

    componentDidMount() {
        let credit = this.client.credit;

        if(this.client.member){
            credit = this.client.credit || 0
        }

        this.setState({
            user: Services.getUser(),
            credit: credit
        }, cb => {
            //console.log('credit', this.state.credit)
        })
        this.getAppts();

    }

    getAppts = () => {
        return this.apptRef
            .where('client.id', '==', this.client.uid)
            .orderBy('start', 'asc')
            .onSnapshot( res => {

                let appts = res.docs.map((doc) => {

                    return {
                        ...doc.data(),
                        uid: doc.id
                    };
                });

                let now = new Date();

                this.setState({
                    upcoming: appts.filter(item => item.start.toDate() > now),
                    past: appts.filter(item => item.start.toDate() <= now)
                });

            }, error => {
                console.log(error);
            });
    };

    handleRedeem = () => {
        this.props.redeem();
    };

    getRetails = (list) => {

        this.setState({
            retails: list
        }, cb => {
            //console.log('got retails');
            //console.log(this.state);
        });

        /* this.state.past.forEach((item, idx) => {

              let id = item.uid;
              if(list[id]){
                  console.log('found it', list[id])

                  item.products = list[id]['products'];
              }
          })*/
    };

    render() {
        return(
            <div>
                <Button onClick={this.goBack} variant="outlined" color="secondary" className='my-2'>
                    <Icon className="mr-2">arrow_left</Icon>
                    Back
                </Button>
                <span></span>
                {
                    Services.isTech(this.state.user)?
                        null : <EditButton client={this.client} type='button' />
                }

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Paper className='p-3 position-relative'>
                            {
                                this.client.member && !Services.isTech(this.state.user) ?
                                    <span className='credit-member'>
                                        <Flag
                                            flag={'credits: ' + this.state.credit}
                                            color={'badge-pill badge-warning'}
                                            size={15}
                                            icon={'account_balance_wallet'}
                                        />
                                    </span>
                                   /* : ( this.client.membership ?
                                    <span className='former-m'>Former Members</span>*/
                                : null //)
                            }
                            <div className="client-top">
                                {
                                    this.client.member ?
                                        <span className='emoji-details' role='img' aria-label='members'>&#128081;</span>
                                        : null
                                }
                                <Instagram ig={this.client.instagram} w={150} />
                                <span className="client-title">{this.client.first_name} {this.client.last_name}</span>
                            </div>

                            <div className="clearfix mt-2"></div>

                            {
                                Services.isTech(this.state.user) ?
                                    null : <React.Fragment>
                                        <RowInfo icon={'phone'} value={this.client.phone} phone={true} />
                                        <RowInfo icon={'email'} value={this.client.email} />
                                    </React.Fragment>
                            }
                            {
                                this.state.user.owner ?
                                    <RowInfo icon={'info'} value={'@'+this.client.instagram} />: null
                            }
                            {
                                Services.isTech(this.state.user) ?
                                    null : <React.Fragment>
                                        <RowInfo icon={'stars'} value={this.client.rewards_count} />
                                        <RowInfo icon={'cake'} value={this.client.birthday} />
                                        <RowInfo icon={'location_on'} value={env.locations[this.client.created_from].name} />
                                    </React.Fragment>
                            }

                            {
                                this.client.member && !Services.isTech(this.state.user) ?
                                    <div className='pb-3'>
                                        <Divider className='my-3' />
                                        <RowInfo
                                            icon={'done_all'}
                                            value={env.membership[this.client.membership].name + ' - $' + this.client.membership_price + '/mo'}
                                        />
                                        <RowInfo
                                            icon={'event'}
                                            value={'Started: ' + this.membership_date}
                                        />
                                        <RowInfo
                                            icon={'event_available'}
                                            value={'Charges every: ' + this.membership_day}
                                        />

                                        <RowInfo
                                            icon={'toll'}
                                            value={this.client.membership_credits + ' credits/mo'}
                                        />
                                        <RowInfo icon={'people'} value={Services.reformatString(this.client.generation) + '  Generation Member'} />
                                    </div>
                                    : null
                            }
                            {
                                this.state.user.owner ?
                                    <Button onClick={this.handleRedeem} color="primary">
                                        Redeem Rewards
                                    </Button> : null
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {
                            this.client.balance && !Services.isTech(this.state.user) ?
                                <Balance client={this.client} />
                                : null
                        }
                        {
                            Services.isTech(this.state.user) ?
                                null : <Subscriptions
                                    client_id={this.client.uid}
                                    member={this.client.member}
                                    membership={this.client.membership}
                                    owner={this.state.user.owner}
                                    retails={e => this.getRetails(e)}
                                />
                        }

                        <Paper className='p-3'>
                            <Appointments appts={this.state.past} title={'Past Appointments'} toggle={false} retails={this.state.retails} />
                            <Divider className='my-3' />
                            <Appointments appts={this.state.upcoming} title={'Upcoming Appointments'} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default ClientDetails;
