import React from "react";
import Services from "../services/Services";
import {BrowserRouter as Router, Route} from "react-router-dom";
import InventoryList from "../components/inventory/InventoryList";
import AddItem from "../components/inventory/AddItem";
import AddCategory from "../components/inventory/AddCategory";

class Inventory extends React.Component {

    componentDidMount() {
        Services.checkIfLoggedIn(this.props.user, this.props.history, '/home', false);
    }

    render() {
        return(
            <Router>
                <Route exact path='/inventory' component={InventoryList}></Route>
                <Route exact path='/inventory/add' lc={this.props.lc} component={AddItem}></Route>
                <Route exact path='/inventory/add-cat' lc={this.props.lc} component={AddCategory}></Route>
            </Router>
        )
    }
}

export default Inventory