import React from 'react';
import Instagram from "../material-ui/Instagram";
import RowInfo from "../clients/RowInfo";
import env from "../../conf";
import db from "../../Firestore";
import Loading from "../material-ui/Loading";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import Flag from "../material-ui/Flag";

class ClientInfo extends React.Component {

    state = {
        loading: false,
        credit: 0
    };

    id = this.props.client.id;
    client = {};
    now = new Date();

    componentDidMount() {
        this.setState({
            loading: true
        }, cb => {
            db.collection('/clients').doc(this.id).get().then(r => {
                this.client = r.data();

                let credit = this.client.credit;

                if(this.client.member){
                    credit = this.client.credit || 0
                }

                this.setState({
                    loading: false,
                    credit: credit
                })
            })
        });
    }

    goToClient = () => {
        this.props.goToClient(this.id);
    };

    render(){
        return(
            this.loading ?
                <Loading />
                : <div className='position-relative'>
                    {
                        this.client.member ?
                            <span className='credit-member'>
                                        <Flag
                                            flag={'credits: ' + this.state.credit}
                                            color={'badge-pill badge-warning'}
                                            size={15}
                                            icon={'account_balance_wallet'}
                                        />
                                    </span>: null
                    }
                    <div className="client-top">
                        {
                            this.client.member ?
                                <span className='emoji-details' role='img' aria-label='members'>&#128081;</span>
                                : null
                        }
                        <Instagram ig={this.client.instagram} w={150} />
                        <span className="client-title">{this.client.first_name} {this.client.last_name}</span>
                    </div>

                    <div className="clearfix mt-2"></div>

                    <RowInfo icon={'phone'} value={this.client.phone} phone={true} />
                    <RowInfo icon={'email'} value={this.client.email} />
                    <RowInfo icon={'info'} value={'@'+this.client.instagram} />
                    <RowInfo icon={'stars'} value={this.client.rewards_count} />
                    {
                        this.client.last_visit ?
                            <RowInfo icon={'date_range'} value={moment(this.client.last_visit.start.toDate()).fromNow() + ' (' + moment(this.client.last_visit.start.toDate()).format('LLL') + ')'} text={'Last Visit: '} />
                            : null
                    }
                    {
                        this.client.upcoming_appt ?
                            <RowInfo
                                icon={'date_range'}
                                value={moment(this.client.upcoming_appt.start.toDate()).format('LLL')}
                                text={'Next Appointment: '}
                            />
                            : null
                    }
                    {
                        this.client.member ?
                            <RowInfo icon={'done_all'} value={env.membership[this.client.membership].name + ' - $' + this.client.membership_price + '/mo'} />
                            : null
                    }

                    <Button variant="contained" onClick={this.goToClient} className='btn btn-secondary'>Full Profile</Button>
                </div>
        )
    }

}

export default ClientInfo;
