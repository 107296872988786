import React from 'react';
import { AreaChart } from 'react-chartkick'
import 'chart.js'
import {Paper, Select} from "@material-ui/core";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import env from "../../../conf";
import Services from "../../../services/Services";
import MenuItem from "@material-ui/core/MenuItem";
import db from "../../../Firestore";

class Chart extends React.Component {

    data = {
        "2017-01-01": 2,
        "2017-01-02": 5,
        "2017-01-03": 3,
        "2017-01-04": 7,
    };

    setting = env.accounting.report_chart;

    state = {
        data: [],
        incomes: this.props.incomes,
        expenses: this.props.expenses,
        xtitle: this.setting.default_xtitle,
        ytitle: this.setting.default_ytitle,
        view: this.setting.default_view,
        data_filter: this.setting.default_data,
        unformatted_data: [],
        from: this.props.from,
        to: this.props.to
    };

    componentDidMount() {
        this.groupBy();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            incomes: nextProps.incomes,
            expenses: nextProps.expenses,
            from: nextProps.from,
            to: nextProps.to
        }, cb => {
            //console.log(this.state);
            this.groupBy();
        })
    }

    groupBy = () => {

        let start_year = moment(this.state.from).year();
        let end_year = moment(this.state.to).year();
        let format = '';

        if(start_year === end_year){
            //format = this.state.view === 'month' ? 'MMM' : 'MMM D';
            switch (this.state.view){
                case 'quarter' :
                    format = '[Q]Q-YYYY';
                    break;
                case 'month' :
                    format = 'MMM-YYYY';
                    break;
                default:
                    format = 'MMM D';
            }
        }else{
            format = this.state.view === 'month' ? 'MMM YYYY' : 'D MMM YYYY';
            switch (this.state.view){
                case 'year' :
                    format = 'YYYY';
                    break;
                case 'quarter' :
                    format = '[Q]Q-YYYY';
                    break;
                case 'month' :
                    format = 'MMM-YYYY';
                    break;
                default:
                    format = 'MMM D';
            }
        }

        let items = this.state.data_filter === 'clients' ?
            this.state.unformatted_data
            : this.state[this.state.data_filter === 'services' || this.state.data_filter === 'retail' ||  this.state.data_filter === 'services-count'? 'incomes' : this.state.data_filter];

        let data = this.organizeData(items, format);

        this.setState({
            data: data
        })

    };

    organizeData = (items, date_format) => {

        return items.reduce((groups, item) => {

            let item_date = item.created_at;
            // old line
            //let item_date = this.state.data_filter === 'incomes' || this.state.data_filter === 'services' || this.state.data_filter === 'retail' ||  this.state.data_filter === 'services-count' ? item.service_date : item.created_at;

            const date =  moment(item_date.toDate()).startOf(this.state.view).format(date_format);

            if (!groups[date]) {
                groups[date] = 0;
            }

            if(this.state.data_filter === 'incomes'){

                // calculate income based on service + tips + products sales
                if(this.checkIncome(item)) groups[date] +=  Services.calculateIncome(item);

            }else if(this.state.data_filter === 'services'){

                if(this.checkIncome(item)) groups[date] +=  Services.calculateService(item);

            }else if(this.state.data_filter === 'retail'){

                if(this.checkIncome(item)) groups[date] +=  Services.calculateRetail(item);

            }else if(this.state.data_filter === 'expenses'){

                // expenses has only amount
                groups[date] +=  item.amount

            }else if(this.state.data_filter === 'services-count'){
                if(this.checkIncome(item) && item.type === 'service') groups[date]++;
            }else{

                // in case of 'clients' we just keep increasing the value of the total when finding a client
                groups[date] += 1;

            }

            return groups;
        }, {});
    };


    checkIncome = (item) => {
        return (!isNaN(item.amount) && item.type === 'service') || item.type !== 'service';
    };


    changeFilter = (str) => {

        this.setState({
            view: str,
            xtitle: Services.reformatString(str) + 's'
        }, cb => {
            this.groupBy()
        })
    };

    changeDataFilter = (e) => {

        let val = e.target.value;
        let new_state = {};

        new_state.data_filter = val;
        new_state.ytitle = Services.reformatString(val);

        if(val === 'clients') {

            db.collection('/clients')
                .where('created_at', '>', this.state.from)
                .where('created_at', '<', this.state.to)
                //.where('member', '==' , true)
                .get()
                .then(res => {
                    new_state.unformatted_data = res.docs.map(doc => {
                        return {
                            ...doc.data()
                        }
                    });

                    this.updateRecall(new_state);
                });
        }else{
            this.updateRecall(new_state);
        }
    };

    updateRecall = (state) => {
        this.setState(state, cb => {
            this.groupBy();
        })
    };

    render() {
        return(
            <Paper className='mx-3 mt-1 mb-3'>
                <Grid container spacing={0} className='p-4'>
                    <Grid item sm={6}>
                        <Select
                            value={this.state.data_filter}
                            name='data_filter'
                            onChange={this.changeDataFilter}
                        >
                            <MenuItem value='incomes'>Income</MenuItem>
                            <MenuItem value='services'>- Services</MenuItem>
                            <MenuItem value='services-count'>- Total Services</MenuItem>
                            <MenuItem value='served-client'>- Served Client</MenuItem>
                            <MenuItem value='retail'>- Retail</MenuItem>
                            <MenuItem value='expenses'>Expenses</MenuItem>
                            <MenuItem value='clients'>Clients</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={6} className='text-right'>
                        <span className={'tab-menu' + (this.state.view === 'day' ? ' active' : '')} onClick={() => this.changeFilter('day')}>Days</span>
                        <span className={'tab-menu' + (this.state.view === 'week' ? ' active' : '')} onClick={() => this.changeFilter('week')}>Weeks</span>
                        <span className={'tab-menu' + (this.state.view === 'month' ? ' active' : '')} onClick={() => this.changeFilter('month')}>Months</span>
                        <span className={'tab-menu' + (this.state.view === 'quarter' ? ' active' : '')} onClick={() => this.changeFilter('quarter')}>Quarter</span>
                        <span className={'tab-menu' + (this.state.view === 'year' ? ' active' : '')} onClick={() => this.changeFilter('year')}>Year</span>
                    </Grid>
                </Grid>
                <AreaChart
                    id="report-chart"
                    discrete={true}
                    decimal="."
                    thousands=","
                    messages={{empty: "No data"}}
                    //download="report"
                    xtitle={this.state.xtitle}
                    ytitle={this.state.ytitle}
                    colors={this.setting.colors}
                    data={this.state.data}
                />
            </Paper>

        )
    }

}

export default Chart;
