import React from 'react';
import PieChart from 'react-minimal-pie-chart';
import Grid from "@material-ui/core/Grid";
import Services from "../../services/Services";
import NumberFormat from "react-number-format";

class SmallChart extends React.Component{

    total = this.props.total;
    value = this.props.value;
    numbers = this.props.numbers;


    state = {
        percentage: (this.value * 100) / this.total
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {

        this.setState({
            percentage: (nextProps.value * 100) / nextProps.total
        })

    }

    render() {
        return (
            <Grid item sm={2} xs={4} className='my-3'>
                <h6 className='text-center dgray-color'>{this.props.title}</h6>
                <h6 className='text-center gray-color'>
                    <NumberFormat
                        value={this.props.value}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                    />
                </h6>
                <PieChart
                    data={[
                        { value: this.state.percentage, key: 1, color: this.props.color }]}
                    reveal={-this.state.percentage}
                    lineWidth={20}
                    lengthAngle={360}
                    rounded
                    animate
                    background={'#c1efd4'}
                    style={{height: 100}}
                    label={function noRefCheck(e){
                        if(e.data.length > 0){
                            return Services.formatPrice(e.data[0].value, false) + '%'
                        }

                        return 'N/A'
                    }}
                    labelPosition={0}
                    labelStyle={{
                        fontSize: '15px'
                    }}
                />
            </Grid>
        );
    }
}

export default SmallChart;
