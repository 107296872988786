import React from "react";
import {auth} from "../Firestore";

class Logout extends React.Component {

    componentDidMount() {
        auth.signOut().then( r=> {
            localStorage.removeItem('user');
            console.log('logged out');
            this.props.history.push('/login')
        });
    }

    render() {
        return(
            <div>logout out</div>
        )
    }

}

export default Logout;
