import React from 'react';
import {auth} from "../Firestore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

class Login extends React.Component {

    initial_state =  {
        email: '',
        password: '',
        error: ''
    };

    state = this.initial_state;


    login = () => {
        auth.signInWithEmailAndPassword(this.state.email, this.state.password).then(r => {
            this.setState({...this.initial_state});
            this.props.updateUser(r);
            this.props.history.push('/');
        }).catch(er => {
            this.setState({
                error: er.message
            })
        })
    }


    handleSubmit = () => {
        this.login()
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        return (
            <Container maxWidth="sm">
                <div className="new-client mt-5">
                    <Card>
                        <CardContent>
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={errors => console.log(errors)}
                            >
                                <TextValidator
                                    label="Email"
                                    onChange={this.handleChange}
                                    name="email"
                                    value={this.state.email}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['Email is required', 'This has to be a valid email address']}
                                    className='row mb-3'
                                />
                                <TextValidator
                                    label="Password"
                                    onChange={this.handleChange}
                                    name="password"
                                    value={this.state.password}
                                    validators={['required']}
                                    errorMessages={['Password is required']}
                                    className='row mb-3'
                                    type="password"
                                />
                                <Button type="submit" variant="contained" className="mt-3">
                                    <Icon className="mr-2">add</Icon>
                                    Login
                                </Button>
                                {
                                    this.state.error !== '' ?
                                        <p className="my-3 text-danger">{this.state.error}</p>
                                        : ''
                                }
                            </ValidatorForm>
                        </CardContent>
                    </Card>


                </div>
            </Container>
        )
    }
}

export default Login;
