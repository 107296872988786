import React from 'react';
import db from "../../Firestore";
import Grid from "@material-ui/core/Grid";
import {Paper, Select} from "@material-ui/core";
import Flag from "../material-ui/Flag";
import moment from 'moment';
import env from "../../conf";
import Members from "./Members";
import Leads from "./Leads";
import Loading from "../material-ui/Loading";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import LeadInfo from "./LeadInfo";
import Button from "@material-ui/core/Button";
import Birthday from "./Birthday";
import MenuItem from "@material-ui/core/MenuItem";
import Services from "../../services/Services";
import Location from "./Location";

class Dash extends React.Component{

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    state = {
        total_upcoming_apt: 0,
        ucoming_apt: [],
        clients: [],
        clLoading: false,
        apLoading: false,
        modal: false,
        modalInfo: {},
        status: 0,
        filtered_list: [],
        user: Services.getUser(),
        location: this.props.lc
    };

    confirmStyle = {
        top: '50%',
        left: '50%',
        marginLeft: -250,
        marginTop: -250,
        transform: 'translate(-50%, -50)',

        position: 'absolute',
        width: 500,
        outline: 'none',
        backgroundColor: '#fff'
    };

    componentDidMount() {
        this._isMounted = true;
        this.getUpcomingApt();
        this.getClientWithRewards();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            location: nextProps.lc
        }, r => {
            this.getUpcomingApt();
        })
    }

    componentWillMount() {
        this._isMounted = false
    }

    getUpcomingApt = () => {

        this.setState({
            apLoading: true
        }, () => {
            db.collection('/appoitments')
                .where('start', '>=', new Date())
                .get()
                .then( r => {
                    let count = 0;
                    let docs = r.docs.map(doc => {
                        count++;
                        return doc.data()
                    }).filter(itm => {
                        return itm.status !== 2
                    }).filter(apt => {
                        return apt.location === this.state.location
                    });

                    this.setState({
                            total_upcoming_apt: count,
                            ucoming_apt: docs,
                            filtered_list: docs
                        },() => {
                            this.setState({
                                apLoading: false
                            })
                        }
                    )
                })
        });
    };

    getClientWithRewards = () => {
        this.setState({
            clLoading: true
        }, () => {
            db.collection('/clients')
                .where('has_rewards', '==', true)
                .where('rewards_count', '>=', 1)
                .orderBy('rewards_count', 'desc')
                .limit(env.dashboard.top_client)
                .get()
                .then( r => {

                    let clients = r.docs.map(doc => {
                        return doc.data()
                    });

                    this.setState({
                            clients: clients
                        },() => {
                            this.setState({
                                clLoading: false
                            })
                        }
                    )
                })
        });
    };

    handleModalClose = e => {
        this.setState({
            modal: false
        })
    };

    openModal = (lead) => {
        this.setState({
            modal: true,
            modalInfo: lead
        })
    };

    goToClient = (id, close) => {
        let closeModal = close || null

        if(closeModal) this.handleModalClose()
        this.props.goToClient(id);
    };

    goToLead = (id) => {
        this.handleModalClose()
        this.props.goToLead(id);
    };

    convert = (item) => {
        localStorage.removeItem('lead_info');
        localStorage.setItem('lead_info', JSON.stringify(item));
        this.handleModalClose();
        this.props.convert(item.id);
    }

    handleStatusChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({
            [name]: value,
            filtered_list: this.filterApts(value)
        });
    };




    filterApts = (val) => {

        let old_list = [];
        old_list = [...this.state.ucoming_apt]

        let new_list = [];

        if(val !== 0) {
            new_list = old_list.filter(r => {
                return r.status === val
            });
        }else{
            new_list = old_list
        }


        return new_list;
    };

    render(){
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        {
                            this.state.user && this.state.user.owner ?
                                 <Leads
                                            info={this.openModal}
                                            owner={this.state.user.owner}
                                            lc={this.state.location}
                                /> : <Location  lc={this.state.location} />

                        }
                        {
                            this.state.user.owner ?
                                <Members clients={this.state.clients} loading={this.state.clLoading} clientDetails={this.goToClient} />
                                : null
                        }
                        {/*{
                            this.state.user.owner ?
                                <GiftCards />
                                : null
                        }*/}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {
                            Services.isTech(this.state.user) ?
                                null : <Birthday />
                        }
                        <Paper className='p-3'>
                            <h5 className='font-weight-normal mb-3'>
                                {
                                    Services.isTech(this.state.user) ? 'This Month\'s Appointments' : 'Upcoming Appointments'
                                }

                                (<b>{this.state.filtered_list.length}</b>)
                                <div className="float-right">
                                    <Select
                                        value={this.state.status}
                                        name='status'
                                        onChange={this.handleStatusChange}
                                    >
                                        <MenuItem key={0} value={0} selected={this.state.status === 0 ? 'selected' : null}>All Status</MenuItem>
                                        {
                                            Object.keys(env.appt_status).map((key, index) => {
                                                if(key !== '2'){
                                                    return <MenuItem key={index} value={parseInt(key)}>{env.appt_status[key]}</MenuItem>
                                                }else{
                                                    return null
                                                }

                                            })
                                        }
                                    </Select>
                                </div>
                            </h5>
                            {
                                this.state.apLoading ?
                                    <Loading />
                                    : this.state.filtered_list.map( (info, index) => {
                                        let apt_date = info.start.toDate();
                                        let end_of_month = moment().endOf('month');
                                        if(
                                            (Services.isTech(this.state.user) && moment(apt_date) <= end_of_month)
                                            || !Services.isTech(this.state.user)
                                        )
                                        return <div key={index} className='mb-2'>
                                            <span className={'font-weight-bold d-inline-block mr-2 apt-status-' + info.status}>
                                                {
                                                    this.state.status === 0 ?
                                                        <Flag color={'status-' + info.status} flag={env.appt_status[info.status]}/>
                                                        : null
                                                }

                                                <span className="notif-btn" onClick={() => this.goToClient(info.client.id)}>{info.client.name}</span>
                                                {
                                                    info.deposit || info.client.member ?
                                                        <span className={'client-icon ' + ( info.deposit ? 'deposited' :( info.client.membership ? 'member' : ''))}> </span>
                                                        : null
                                                }
                                                {
                                                    info.source ?
                                                        <Flag color={'badge-warning'} flag={'Online'}/>
                                                        : null
                                                }
                                                ({moment(apt_date).format('LLL')})
                                            </span>
                                            <small>
                                                {info.service.cat_name}:
                                                {info.service.name}
                                                <Flag flag={info.service.flag} />
                                            </small>
                                            {
                                                info.aff && this.state.user.owner?
                                                    <Flag flag={info.aff} color={'badge-success'} />:null
                                            }
                                        </div>
                                    return null
                                    })
                            }
                        </Paper>
                    </Grid>
                </Grid>


                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modal}
                    onClose={this.handleModalClose}
                >
                    <DialogContent style={this.confirmStyle} className=''>
                        <LeadInfo info={this.state.modalInfo} owner={this.state.user.owner} />
                        <div className="text-center mb-3">
                            <Button onClick={() => this.goToLead(this.state.modalInfo.id)} variant="contained" className="btn btn-secondary float-left">Lead Details</Button>
                            {
                                this.state.modalInfo.returning ?
                                    null : <Button onClick={() => this.convert(this.state.modalInfo)} variant="outlined" className="btn btn-success float-left ml-2">Convert >></Button>
                            }

                            {
                                this.state.modalInfo.returning ?
                                    <Button onClick={() => this.goToClient(this.state.modalInfo.uid, true)} variant="contained" className='btn primary-btn float-right'>Profile</Button>
                                    : <Button onClick={this.handleModalClose} variant="contained" className='btn primary-btn float-right'>Close</Button>
                            }
                            <div className='clearfix'></div>
                        </div>
                    </DialogContent>
                </Modal>
            </div>
        );
    }
}

export default Dash;
