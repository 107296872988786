import React from 'react';
import Services from "../../services/Services";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Lashes from "./Lashes";
import Skin from "./Skin";
import Hr from "./Hr";
import Eyebrow from "./Eyebrow";

class Menu extends React.Component {

    state = {
        tab: 0
    };

    componentDidMount() {
        Services.checkIfLoggedIn(this.props.user, this.props.history, '', false);
    }

    handleChangeTab = (e, newValue) => {
        this.setState({
            tab: newValue
        })
    };

    render() {

        return(
            <div className='clients-list mt-3 mx-3'>
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Lashes" />
                    <Tab label="Wax/Eyebrow" />
                    <Tab label="Skin" />
                    <Tab label="PHR" />
                </Tabs>

                {this.state.tab === 0 && <Lashes numbers={this.props.numbers}/>}
                {this.state.tab === 1 && <Eyebrow numbers={this.props.numbers}/>}
                {this.state.tab === 2 && <Skin numbers={this.props.numbers}/>}
                {this.state.tab === 3 && <Hr numbers={this.props.numbers}/>}

            </div>
        )
    }
}

export default Menu;
