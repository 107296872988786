import React from 'react';
import ClientForm from "./ClientForm";

class UpdateClient extends React.Component {

    id = this.props.match.params.id;

    componentDidMount() {
        localStorage.removeItem('lead_info');
    }

    render(){
        return(
            <ClientForm />
        )
    }
}


export default UpdateClient;
