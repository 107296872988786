import React from 'react';
import Grid from "@material-ui/core/Grid";
import Instagram from "../../material-ui/Instagram";
import RowInfo from "../../clients/RowInfo";
import Flag from "../../material-ui/Flag";
import moment from 'moment';
import LeadLog from "./LeadLog";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import env from "../../../conf";
import Button from "@material-ui/core/Button";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import db from "../../../Firestore";
import Services from "../../../services/Services";
import {Icon} from "@material-ui/core";
import Confirm from "../../material-ui/Confirm";
import axios from "axios";
import Loading from "../../material-ui/Loading";

class LeadDetails extends React.Component {

    lead = this.props.lead;

    state = {
        logs: this.lead.logs,
        open_log_form: false,
        note: '',
        timestamp: false,
        loading: false
    };

    goBack = () => {
        this.props.close(null)
    };

    componentDidMount() {
    }

    addLog = () => {
        if(this.state.note !== ''){
            let list = this.state.logs ? [...this.state.logs] : [];

            list.push({
                note: this.state.note,
                created_at: new Date()
            });

            db.collection('/leads')
                .doc(this.lead.id)
                .set({logs: list}, {merge: true})
                .then(r => {
                    this.setState({
                        logs: list,
                        open_log_form: !this.state.open_log_form,
                        note: ''
                    })
                }).catch( er => {
                console.log(er)
            })

        }else{
            alert('Note cannot be empty!')
        }
    };

    handleChange = (e) => {
        this.setState({
            note: e.target.value
        })
    };


    toggleNote = (str) => {
        this.setState({
            open_log_form: !this.state.open_log_form,
            note: '',
            sms_reminder: str === 'sms',
            email_reminder: str === 'email',
            loading: false
        })
    };

    sendReminder = () => {

        if(this.state.sms_reminder){
            this.setState({
                loading: true
            }, r => {
                let list = ['+1' + this.lead.phone];

                let payload = {
                    list: list,
                    message: 'Hi ' + this.lead.name + env.texts.sms_reminder_text
                };

                axios.post(env.base_api + '/send', payload)
                    .then(r => {
                        if(r.data.success) this.setState({
                            note: '>>>>> SMS Reminder sent'
                        }, res => {
                            this.addLog()
                        })
                    })
                    .catch( er => {
                        console.log('there was an error while sending sms ', er)
                        this.toggleNote('close')
                    })
            })

        }else{
            console.log('email reminder will be sent')
        }
    }


    goToClient = (id) => {
        this.props.goToClient(id);
    }

    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div className='p-3 position-relative'>
                            <div className="client-top">
                                {
                                    this.lead.returning &&  this.lead.member?

                                        <span className='emoji-details' role='img' aria-label='members'>&#128081;</span>
                                        : null
                                }

                                <Instagram ig={this.lead.instagram} w={150} />
                                <span className="client-title">{this.lead.name}</span>

                                <div className='text-left pt-2'>
                                    <RowInfo icon={'phone'} value={this.lead.phone} phone={true} />
                                    <RowInfo icon={'email'} value={this.lead.email} />
                                    <RowInfo icon={'people'} value={'@'+this.lead.instagram} />
                                    <RowInfo icon={'room_service'} value={this.lead.service + ' - ' + this.lead.custom_service + ' (' + this.lead.price + ')' } />

                                    {
                                        this.lead.step === 2 ?
                                            <RowInfo icon={'calendar_today'} value={this.lead.date + ' - ' + this.lead.time} /> : null
                                    }
                                    {
                                        this.lead.code ?
                                            <Flag flag={'Code: ' + this.lead.code} color={'badge-danger'}/> : null
                                    }
                                    <Flag flag={'step ' + this.lead.step} color={'badge-info'}/>
                                    <Flag flag={this.lead.returning ? 'Existing Client' : 'New Client'} color={this.lead.returning ? 'badge-success' : 'badge-dark'}/>
                                    {
                                        (this.props.owner && this.lead.aff)
                                        || (!this.props.owner && Services.showAffiliate(this.lead.aff)) ? //this.props.owner
                                            <Flag flag={this.lead.aff} color={'badge-warning'}/>
                                            : null
                                    }
                                    {
                                        this.lead.returning ?
                                            <span className={'btn btn-xs btn-outline-success'} onClick={() => this.goToClient(this.lead.uid)}>Full Profile</span> : null
                                    }
                                </div>

                                <span className="mt-3 gray-color d-block">
                                    <span className="float-left">Lead received at: {moment(this.lead.created_at.toDate()).format('LLL')}</span>
                                </span>

                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} className='ld-comments'>
                        {
                            this.lead.comment && this.lead.comment !== '' ?
                                <React.Fragment>
                                    <h6 className='comment-title'>
                                        <Icon className='flag-icon comment-icon'>chat</Icon>
                                        Lead Comment</h6>
                                    <span className="retail-box"></span>
                                    <p className='lead-comment'>
                                        {this.lead.comment}
                                    </p>
                                </React.Fragment>  : null
                        }
                        {
                            this.state.logs && this.state.logs.length > 0 ?
                                <React.Fragment>
                                    <h6>Logs</h6>
                                    {
                                        this.state.logs.map((itm, idx) => {
                                            return <LeadLog log={itm} key={idx} />
                                        })
                                    }
                                </React.Fragment> : null
                        }

                        <Button onClick={e => this.toggleNote('log')} variant="contained" className="secondary-btn">Add Log</Button>
                        <Button onClick={e => this.toggleNote('sms')} variant="contained" className="secondary-btn m-2">SMS Reminder</Button>
                        {/*<Button onClick={e => this.toggleNote('email')} variant="contained" className="secondary-btn">Email Reminder</Button>*/}
                    </Grid>
                </Grid>


                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open_log_form}
                    onClose={this.toggleNote}
                >
                    <DialogContent style={env.modal_style}>

                        {
                            this.state.loading ?
                                <Loading />
                                : (this.state.sms_reminder || this.state.email_reminder ?
                                        <Confirm
                                            client={this.state.sms_reminder ? env.texts.sms_reminder : env.texts.email_reminder}
                                            close={e => this.toggleNote('close')}
                                            delete={this.sendReminder}
                                            general={true}
                                        />
                                    :

                                    <Grid container className="mt-3 mb-4 text-center">
                                        <h4 className='text-center'>Add Note</h4>
                                        <Grid item xs={12}>
                                            <ValidatorForm
                                                ref="form"
                                                onSubmit={this.addLog}
                                                onError={errors => console.log(errors)}
                                            >
                                            <TextValidator
                                                label="note"
                                                onChange={this.handleChange}
                                                name="log"
                                                value={this.state.note}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                className='row'
                                                multiline
                                                rows={3}
                                                rowsMax={3}
                                            />
                                            </ValidatorForm>
                                        </Grid>
                                        <Grid item xs={12} className='mt-3'>
                                            <Button type='submit' onClick={this.addLog} variant="contained" className="primary-btn">Add Log</Button>
                                            <Button onClick={this.toggleNote} className="secondary-btn">Cancel</Button>
                                        </Grid>
                                    </Grid>
                                )}

                    </DialogContent>
                </Modal>
            </div>
        );
    }
}

export default LeadDetails;
