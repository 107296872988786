import React from "react";
import {Paper} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";




class AddGiftCard extends React.Component {

    state = {
        from: '',
        from_email: '',
        to: '',
        to_email: '',
        amount: '',
    }

    handleSubmit(){

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    render() {
        return(
            <div>
                <Paper className='p-3 mt-3'>
                    <h5 className='font-weight-normal mb-3'>Add New Gift Card</h5>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <TextValidator
                            label="Sender Name"
                            onChange={this.handleChange}
                            name="from"
                            value={this.state.from}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            className='row'
                        />
                        <TextValidator
                            label="Sender Email"
                            onChange={this.handleChange}
                            name="from_email"
                            value={this.state.from_email}
                            validators={['required', 'email']}
                            errorMessages={['this field is required', 'this field has to be an email address']}
                            className='row'
                        />
                        <TextValidator
                            label="Recipient Name"
                            onChange={this.handleChange}
                            name="to"
                            value={this.state.to}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            className='row'
                        />
                        <TextValidator
                            label="Recipient Name"
                            onChange={this.handleChange}
                            name="to_email"
                            value={this.state.to_email}
                            validators={['required', 'email']}
                            errorMessages={['this field is required', 'this field has to be an email address']}
                            className='row'
                        />
                        <TextValidator
                            label="Amount"
                            onChange={this.handleChange}
                            name="s_name"
                            value={this.state.amount}
                            validators={['required', 'number']}
                            errorMessages={['this field is required', 'this field has to be a number']}
                            className='row'
                        />
                        <TextValidator
                            label="Sender Name"
                            onChange={this.handleChange}
                            name="s_name"
                            value={this.state.s_name}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            className='row'
                        />
                    </ValidatorForm>
                </Paper>
            </div>
        )
    }
}

export default AddGiftCard;