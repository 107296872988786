import React from 'react';
import Services from "../../services/Services";
import {Icon} from "@material-ui/core";

class Flag extends React.Component {

    color = this.props.color || null;
    size = this.props.size || null;
    icon = this.props.icon || null;

    renderClass = (str) => {
        let classes = 'ml-1 badge';

        if(this.color === null){
            if(str !== 'refill'){
                classes += ' badge-dark';
            }else{
                classes += ' badge-light';
            }
        }else{
            classes += ' ' + this.color;
        }

        return classes;
    };

    render(){
        return(
            this.props.flag !== null ?
                <span className={this.renderClass(this.props.flag) + (this.size ? ' size-' + this.size : '')}>
                    {
                        this.icon ?
                            <Icon className='flag-icon'>{this.icon}</Icon>
                            : null
                    }
                    {Services.reformatString(this.props.flag)}
                </span>
                : ''
        )
    }
}

export default Flag;
