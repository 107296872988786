import React from 'react';
import Icon from "@material-ui/core/Icon";
import NumberFormat from "react-number-format";

class RowInfo extends React.Component {

    render() {
        return(
            this.props.value ?
                <div className="liner mb-2">
                    <Icon className="gray-color icon-top7">{this.props.icon}</Icon>
                    <span className="dgray-color ml-2">
                    {
                        this.props.text ?
                            this.props.text
                            : null
                    }
                        {
                            this.props.phone ?
                                <NumberFormat value={this.props.value} displayType={'text'} format="(###) ###-####" />
                                : this.props.value}
                </span>
                </div> : null
        )
    }
}

export default RowInfo;
