import React from 'react';
import {NavLink} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import env from "../conf";
import Notifications from "../components/notifications/Notifications";
import {Hidden, Select} from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import { withRouter } from 'react-router-dom';
import Services from "../services/Services";
import MenuItem from "@material-ui/core/MenuItem";

class Header extends React.Component {

    constructor(props){
        super(props);
        this.state.logged_in = props.user;
        this.state.numbers = props.numbers || false;
        this.state.location = props.lc || env.default_location;
    }

    state ={
        logged_in: null,
        numbers: null,
        openMenu: false,
        path: window.location.pathname,
        location: env.default_location
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            logged_in: nextProps.user
        })
    }

    handleChange = e => {
        let s_name = e.target.name;
        let s_value = e.target.value;
        let new_state = {
            [s_name]: s_name === 'location' ? s_value : !this.state.numbers
        }

        this.setState(new_state, () => {
            if(s_name === 'location'){
                this.props.current_lc(this.state.location);
                localStorage.setItem(env.local.location, '' + this.state.location);
            }else{
                this.props.show_num(this.state.numbers);
                localStorage.setItem(env.local.show_number, '' + this.state.numbers);
            }

        })
    };

    menuToggle = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    };

    goToClientDetails = (id) => {
        this.props.history.push('/clients?cl=' + id);
    };

    componentDidMount() {
        let local_lc = localStorage.getItem(env.local.location)
        this.setState({
            location: local_lc ? local_lc : this.state.location
        })
    }


    render(){
        return (
            <div className="all-container">
                <AppBar position="fixed" style={{backgroundColor: '#d8a9b8'}} className="pl-0 ml-0">
                    <Toolbar>
                        <Typography variant="h6" className="col-md-4 col-xs-12">
                            <div className="logo">
                                <img src="https://drglowgetter.com/wp-content/uploads/2019/02/logo2-300x104.png" width="160" alt="dr glow getter" />
                                <small className="management">Manager</small>
                            </div>
                            {
                                this.state.logged_in && this.state.logged_in.owner ?
                                    <div className="text-left float-left numbers-switch">
                                        <FormControlLabel className='mr-0 pr-0 ml-1' control={
                                            <Switch
                                                checked={this.state.numbers}
                                                onChange={this.handleChange}
                                                value={this.state.numbers}
                                                name="numbers"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        } label="" />
                                        <Hidden only='xs'>
                                            <Icon>money_off</Icon>
                                        </Hidden>
                                    </div>
                                    : null
                            }
                            {
                                this.state.logged_in ?
                                    <div className={'text-left float-left numbers-switch location-sl' + (this.state.logged_in.owner ? '' : ' not-owner')}>
                                        <Select
                                            value={this.state.location}
                                            name='location'
                                            onChange={this.handleChange}
                                        >
                                            {
                                                Object.keys(env.locations).map((keyName, index) => {
                                                    return <MenuItem key={index} value={keyName}>{env.locations[keyName].name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </div>
                                    : null
                            }

                        </Typography>
                        <div className="col-md-8 col-xm-12">
                            <div className="float-right">
                                {
                                    this.state.logged_in != null ?
                                        <React.Fragment>
                                            <Hidden only="xs">
                                                <NavLink to='/home' className='link'>Home</NavLink>
                                                {
                                                    Services.isTech(this.state.logged_in) ?
                                                        null : <NavLink to='/menu' className='link'>Menu</NavLink>
                                                }

                                                <NavLink to='/clients' className='link'>Clients</NavLink>
                                                <NavLink to='/appointments' className='link'>Appointments</NavLink>
{/*
                                                <NavLink to='/inventory' className='link'>Inventory</NavLink>
*/}
                                                {
                                                    this.state.logged_in.owner ?
                                                        <React.Fragment>
                                                            <NavLink to='/accounting' className='link'>Reports</NavLink>
                                                            <NavLink to='/marketing' className={'link'}>Marketing</NavLink>
                                                        </React.Fragment>
                                                        : null // TODO here you add some loginc to show only gift cards when ready
                                                }

                                                <NavLink to='/logout' className='link'>Logout</NavLink>
                                            </Hidden>
                                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                <span className={'menu-dots mh-icons' + (this.state.logged_in.owner ? '' : ' not-owner-dots')} onClick={this.menuToggle}>
                                                    <Icon>more_vert</Icon>
                                                </span>
                                            </Hidden>
                                            {
                                                this.state.logged_in.owner ?
                                                    <React.Fragment>
                                                        <NavLink activeClassName='active-setting' to='/settings' className='link mh-icons mh-sett'>
                                                            <Icon>settings</Icon>
                                                        </NavLink>
                                                        <Notifications goToClient={this.goToClientDetails} />
                                                    </React.Fragment>
                                                    : null
                                            }
                                        </React.Fragment>
                                        : <NavLink to='/login' className='link mh-icons'>Login</NavLink>
                                }
                            </div>

                        </div>
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Fade in={this.state.openMenu}>
                                <div elevation={4} className='m-menu'>
                                    <NavLink to='/home' className='link' onClick={this.menuToggle}>Home</NavLink>
                                    <NavLink to='/menu' className='link' onClick={this.menuToggle}>Menu</NavLink>
                                    <NavLink to='/clients' className='link' onClick={this.menuToggle}>Clients</NavLink>
                                    <NavLink to='/appointments' className='link' onClick={this.menuToggle}>Appointments</NavLink>
{/*
                                    <NavLink to='/inventory' className='link' onClick={this.menuToggle}>Inventory</NavLink>
*/}
                                    {
                                        this.state.logged_in && this.state.logged_in.owner ?
                                            <React.Fragment>
                                                <NavLink to='/accounting' className='link' onClick={this.menuToggle}>Reports</NavLink>
                                                <NavLink to='/marketing' className='link' onClick={this.menuToggle}>Marketing</NavLink>
                                            </React.Fragment>
                                          : null
                                    }


                                    <NavLink to='/logout' className='link'>Logout</NavLink>
                                </div>
                            </Fade>
                        </Hidden>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

Header.propTypes = {
    user: PropTypes.any
};


export default withRouter(Header);
