import React from 'react';
import db from "../../Firestore";
import SingleClient from "./SingleClient";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import {TextField} from "@material-ui/core";
import myStyles from "../../layouts/Styles";
import env from './../../conf';
import TablePagination from "@material-ui/core/TablePagination";
import Modal from "@material-ui/core/Modal";
import Confirm from "../material-ui/Confirm";
import DialogContent from "@material-ui/core/DialogContent";
import ClientDetails from "./ClientDetails";
import Loading from "../material-ui/Loading";
import Redeem from "../material-ui/Redeem";
import Hidden from '@material-ui/core/Hidden';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import moment from "moment";
import Services from "../../services/Services";
/*import {jsons2csv} from "react-csv/src/core";*/

import { withRouter} from 'react-router-dom';



class ClientsList extends React.Component {

    state = {
        clients: [],
        current_list: [],
        keyword: '',
        per_page: env.per_page.default,
        page: 0,
        start: 0,
        end: env.per_page.default - 1,
        size: 0,
        modal: false,
        selected_client: null,
        client_details: false,
        redeemModal: false,
        filter: '',
        filtered_clients: [],
        is_phone_search: false,
        add_note: false,
        is_filter: false,
        user: Services.getUser(),
        csvClients: [],
        location: Services.getLocation()
    };

    clientRef = db.collection('/clients');

    confirmStyle = env.modal_style;

    snapshot = () => {

        return this.clientRef
            .orderBy('first_name')
            .onSnapshot( res => {

                let csvClients = res.docs.map(itm => {
                    let usr = itm.data();
                    return {
                        email: usr.email,
                        first_name: usr.first_name,
                        last_name: usr.last_name,
                        phone: usr.phone,
                        member: usr.member,
                        banned: !usr.subscribed_sms,
                        created_at: usr.created_at
                    }
                }).filter(user => user.email && user.created_at.toDate() >= new Date(env.csv.from) )

                this.setState({
                    clients: res.docs.map((doc, index, arr) => {
                        let count = arr.length - 1;

                        if(count === index) {
                            this.setState({
                                size: arr.length
                            });
                        }

                        return {
                            ...doc.data(),
                            uid: doc.id
                        }
                    }),
                    current_list: this.getCurrentList(res.docs, true),
                    csvClients: csvClients
                }, cb => {
                    if(this.props.location.search.includes('?cl=')) this.isClientDetails();
                });

            });
    };

    getCurrentList = (full_list, first_load) => {

        let new_list = [];

        if(first_load){
            new_list = full_list.map(doc => {
                return {
                    ...doc.data(),
                    uid: doc.id
                }
            })
        }else{
            new_list = full_list;
        }

        let another_list = new_list.filter((item, index) => {
            return index >= this.state.start && index <= this.state.end;
        });

        return another_list;

    };

    isClientDetails = () => {
        let client_id = Services.getPramValue('cl', this.props.location.search);

        let new_list = [...this.state.clients];
        new_list = new_list.filter(r => r.uid === client_id);

        let my_client = new_list[0];

        this.openClientDetails(my_client);

        return client_id !== null;
    };

    /*componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            console.log('Route Updated');
        }
    }*/

    componentDidMount() {

        if(this.props.location.search.includes('lead')){
            let lead_id = Services.getPramValue('lead', this.props.location.search)
            this.props.history.push('/clients/add?convert=' + lead_id)
        }

        this.unsubscribe = this.snapshot();

        return this.unsubscribe;
    }

    addNew = () => {
        localStorage.removeItem('lead_info');
        this.props.history.push('/clients/add');
    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    handleSearchInput = (e) => {
        e.preventDefault();
        let value = e.target.value.toLowerCase();

        let new_clients = [];

        if(value === ''){
            new_clients = this.state.clients;
        }else{
            let new_list = this.state.clients;
            new_clients = new_list.filter(r => {
                let targeted_field = this.state.is_phone_search ? '' + r.phone : r.first_name.toLowerCase() + ' ' + r.last_name.toLowerCase();

                let condition = targeted_field.includes(value);
                return condition;
            });
        }

        this.setState({
            start: 0,
            end: env.per_page.default,
            page: 0,
            size: new_clients.length,
            keyword: value
        }, () => {
            this.setState({
                current_list: this.getCurrentList(new_clients, false)
            })
        })
    };

    handleFilter = (filter) => {
        this.setState({
            filter: filter
        }, cb => {
            let list = [];
            if(this.state.filter === ''){
                list = this.state.clients
            }else{
                let newiLst = this.state.clients;
                list = newiLst.filter(item => {
                    if(this.state.filter === 'members'){
                        return item.member
                    }else if(this.state.filter === 'former-members'){
                        return !item.member && item.membership
                    }

                    return !item.member;
                });
            }

            this.setState({
                start: 0,
                page: 0,
                size: list.length,
                keyword: '',
                current_list: this.getCurrentList(list, false),
                filtered_clients: list
            })
        })
    };

    handleChangePage = (e, page) => {
        let start = null;
        let end = null;

        if(page > this.state.page){
            start = this.state.end + 1;
            end = start + this.state.per_page - 1;
        }else{
            end = this.state.start - 1;
            start = end - this.state.per_page + 1;
        }

        this.setState({
            page: page,
            start: start,
            end: end
        }, () => {

            let real_current_list = this.state.filter === '' && !this.state.is_filter ? this.state.clients : this.state.filtered_clients;

            this.setState({
                current_list: this.getCurrentList(real_current_list, false)
            })
        })

    };

    handleChangeRowsPerPage = (e, obj) => {
        this.setState({
            per_page: parseInt(obj.key),
            start: 0,
            end: parseInt(obj.key) - 1
        }, () => {
            this.setState({
                current_list: this.getCurrentList(this.state.clients, false)
            })
        });
    };

    handleModalClose = e => {
        this.setState({
            modal: false
        })
    };

    deletClient = id => {
        this.clientRef.doc(this.state.selected_client.uid).delete().then(res => {
            this.setState({
                modal: false,
                selected_client: null,
                keyword: ''
            });
        });
    };

    selectClient = (client) => {
        this.setState({
            modal: true,
            selected_client: client
        })
    };

    openClientDetails = (client) => {
        this.setState({
            client_details: true,
            selected_client: client
        })
    };

    openRedeem = () => {
        this.setState({
            modal: true,
            redeemModal: true
        })
    };

    openNote = () => {
        this.setState({
            modal: true,
            add_note: true
        })
    };

    handleGoBack = e => {
        this.setState({
            client_details: false,
            redeemModal: false,
            add_note: false
        })
    };

    toggleSwitch = (name) => {
        this.setState({
            [name]: !this.state[name]
        }, cb => {
            if(!this.state[name] && name === 'is_filter'){
                let lst = this.state.clients;
                this.setState({
                    start: 0,
                    page: 0,
                    size: lst.length,
                    keyword: '',
                    current_list: this.getCurrentList(lst, false),
                    filtered_clients: lst
                })
            }
        })
    };

    activeFilter = (e) => {
        let time = e.target.innerText.toLowerCase();
        let list = [];
        let newiLst = this.state.clients;

        if(time === 'never'){
            list = newiLst.filter(item => {
                return !item.upcoming_appt && !item.last_visit;
            });
        }else if(time === 'none'){
            list = newiLst.filter(item => {
                return !item.upcoming_appt && item.last_visit;
            });
        }else{
            let filter_arr = time.split(' ');
            let num = parseInt(filter_arr[0]);
            let str = num === 1 ? filter_arr[1] + 's' : filter_arr[1];

            let date = moment().subtract(num, str).toDate();

            list = newiLst.filter(item => {
                console.log(item.last_visit)
                return (
                    item.last_visit && item.last_visit.start.toDate() <= date
                    && (!item.upcoming_appt || (item.upcoming_appt && item.upcoming_appt.start.toDate() < new Date()))
                );
            });

            list.sort(Services.sortByLastVisit)
        }


        this.setState({
            start: 0,
            page: 0,
            size: list.length,
            keyword: '',
            current_list: this.getCurrentList(list, false),
            filtered_clients: list
        })
    };

    exportCSV = () => {

        const csvString = [
            [
                "email",
                "first_name",
                "last_name",
                "phone_number",
                "member",
                "banned"
            ],
            ...this.state.csvClients.map(item => [
                item.email,
                item.first_name,
                item.last_name,
                item.phone,
                item.member,
                item.banned
            ])
        ].map(e => e.join(","))
            .join("\n");

        Services.downloadCSV(csvString, 'contacts')

    }

    render(){
        return(
             <div className="clients-list">
                {
                    !this.state.client_details && !Services.isTech(this.state.user) ?
                        <div>
                            <div className="col-sm-4 col-xs-12 float-left my-4 client-head">
                                <div className='row'>

                                    <div className='col-sm-12'>
                                        <TextField
                                            placeholder="Search for client..."
                                            value={this.state.keyword}
                                            name='keyword'
                                            onChange={this.handleSearchInput}
                                            className='mr-2'
                                        />

                                        <span onClick={e => this.toggleSwitch('is_phone_search')} className={this.state.is_phone_search ? 'mx-2 active-btn' : 'mr-1'}>
                                            <Icon fontSize={'small'} className="dgray-color">phone</Icon>
                                        </span>

                                        <span onClick={e => this.toggleSwitch('is_filter')} className={this.state.is_filter ? 'active-btn' : ''}>
                                            <Icon fontSize={'small'} className="dgray-color">filter_list</Icon>
                                        </span>
                                    </div>

                                    {
                                        this.state.is_filter ?
                                            <div className='col-sm-12 mx-0 px-0 mt-2'>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">None</Button>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">2 weeks</Button>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">1 month</Button>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">2 months</Button>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">3 months</Button>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">6 months</Button>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">1 year</Button>
                                                <Button size="small" onClick={this.activeFilter} className="secondary-btn">never</Button>
                                            </div>
                                            : null
                                    }

                                </div>



                            </div>

                            <div className="col-sm-4 col-xs-12 float-left my-4 text-center button-filter">
                                <ButtonGroup
                                    size="small"
                                    variant="outlined"
                                    color="default"
                                >
                                    <Button onClick={e => this.handleFilter('')} className={this.state.filter === '' ? 'orange' : ''} >All</Button>
                                    <Button onClick={e => this.handleFilter('members')} className={this.state.filter === 'members' ? 'orange' : ''}>Members</Button>
                                    <Button onClick={e => this.handleFilter('nonmembers')} className={this.state.filter === 'nonmembers' ? 'orange' : ''}>Non-Members</Button>
                                    {/*<Button onClick={e => this.handleFilter('former-members')} className={this.state.filter === 'former-members' ? 'orange' : ''}>Former-Members</Button>*/}
                                </ButtonGroup>

                            </div>
                            <Hidden only='xs'>
                                <div className="col-sm-4 col-xs-6 float-right my-4" style={{textAlign: 'right'}}>
                                    <Fab onClick={this.addNew} color="primary" style={myStyles.button} aria-label="Add">
                                        <Icon>add</Icon>
                                    </Fab>
                                    {
                                        env.csv.active ?
                                            <Fab onClick={this.exportCSV} color="primary" style={myStyles.button}
                                                 aria-label="save">
                                                <Icon>Export Csv</Icon>
                                            </Fab> : null
                                    }
                                </div>
                            </Hidden>

                        </div> : null
                }
                <div className="clearfix"></div>
                <div className="col-sm-12">
                    {
                        this.state.client_details ?
                            <ClientDetails
                                client={this.state.selected_client}
                                back={this.handleGoBack}
                                redeem={this.openRedeem}
                            />
                            : <Paper>
                                {
                                    this.state.clients.length > 0 ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width={60}></TableCell>
                                                    <TableCell>
                                                        {/*<Icon className="float-left" >arrow_drop_down</Icon>*/}
                                                        Full Name
                                                    </TableCell>
                                                    {
                                                        Services.isTech(this.state.user) ?
                                                            null : <Hidden only="xs"><TableCell>Phone</TableCell></Hidden>
                                                    }
                                                    <Hidden only="xs"><TableCell>Last Visit</TableCell></Hidden>
                                                    {
                                                        Services.isTech(this.state.user) ?
                                                            null : <Hidden only="xs"><TableCell>Rewards</TableCell></Hidden>
                                                    }

                                                    {
                                                        Services.isTech(this.state.user) ?
                                                            null :<TableCell></TableCell>
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state.current_list.map(doc => {
                                                        return <SingleClient
                                                            key={doc.uid}
                                                            client={doc}
                                                            select_client={this.selectClient}
                                                            openClientDetails={this.openClientDetails}
                                                            user={this.state.user}
                                                        />})
                                                }
                                                <TableRow>
                                                    <TableCell colSpan={6}>
                                                        <TablePagination
                                                            rowsPerPageOptions={env.per_page.options}
                                                            component="div"
                                                            count={this.state.size}
                                                            rowsPerPage={env.per_page.default}
                                                            page={this.state.page}
                                                            backIconButtonProps={{
                                                                'aria-label': 'Previous Page',
                                                            }}
                                                            nextIconButtonProps={{
                                                                'aria-label': 'Next Page',
                                                            }}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        : <div className="text-center p-4 d-block">
                                            {this.state.keyword === '' ?
                                                <Loading />
                                                : <div className="text-center">There's no client matches your search :(</div>
                                            }

                                        </div>
                                }
                            </Paper>
                    }

                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.modal}
                        onClose={this.handleModalClose}
                    >
                        <DialogContent style={this.confirmStyle}>
                            {
                                this.state.redeemModal ?
                                    <Redeem client={this.state.selected_client} close={this.handleModalClose} />
                                    :
                                    this.state.add_note?
                                        <span>add note goes here</span>
                                        : <Confirm
                                            client={this.state.selected_client}
                                            close={this.handleModalClose}
                                            delete={this.deletClient}
                                        />
                            }
                        </DialogContent>
                    </Modal>

                </div>
            </div>
        )
    }
}


export default withRouter(ClientsList);
