import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableLine from "./TableLine";
import {Paper} from "@material-ui/core";

class Lashes extends React.Component {

    new_fs = 'New FS';

    list = {
        lashes: {
            head: ['Non-Members', 'Full-set', '1 Week Touch-Up', '2 Weeks RF', '3 Weeks RF', '4+ Weeks RF'],
            classic: ['Classic Look', 250, 85, 100, 150,  this.new_fs],
            hybrid: ['Hybrid Wispy', 250, 85, 100, 150,  this.new_fs],
            l_volume: ['Light Volume', 265, 85, 100, 150,  this.new_fs],
            volume: ['Volume Drama', 285, 100, 150, 200,  this.new_fs],
            mega_volume: ['Mega Volume', 350, 150, 200, 260, this.new_fs],
            lash_lift: ['Lash Lift & Tint', 150, '', '',  '']
        },
        lashes_members : {
            head: ['Members', '1RF/mo', '2RF/mo'],
            classic: ['Classic', 84.99, 165.99],
            hybrid: ['Hybrid', 99.99, 189.99],
            volume: ['Volume', 124.99, 239.99],
            mega_volume: ['Mega Volume', '$124.99 + $60', '$239.99 + $60']
        }
    };

    render() {
        return(
            <div>
                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableLine list={this.list.lashes.head} head={true} />
                        </TableHead>
                        <TableBody>
                            <TableLine list={this.list.lashes.classic} head={false} />
                            <TableLine list={this.list.lashes.hybrid} head={false} />
                            <TableLine list={this.list.lashes.l_volume} head={false} />
                            <TableLine list={this.list.lashes.volume} head={false} />
                            <TableLine list={this.list.lashes.mega_volume} head={false} />
                            <TableLine list={this.list.lashes.lash_lift} head={false} />
                        </TableBody>
                    </Table>
                </Paper>
                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableLine list={this.list.lashes_members.head} head={true} membership={true}/>
                        </TableHead>
                        <TableBody>
                            <TableLine list={this.list.lashes_members.classic} head={false} />
                            <TableLine list={this.list.lashes_members.hybrid} head={false} />
                            <TableLine list={this.list.lashes_members.volume} head={false} />
                            <TableLine list={this.list.lashes_members.mega_volume} head={false} />
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

export default Lashes;
