import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import env from "../../conf";
import NumberFormat from "react-number-format";
import moment from "moment";
import Instagram from "../material-ui/Instagram";

class CampDetails extends React.Component{

    campaign = this.props.campaign;

    componentDidMount() {
        console.log(this.campaign)
    }

    close = () => {
        this.props.close()
    };

    render() {
        return(
            <div className='cmapaign-details'>
                <Grid container className="my-0 mt-sm-3 mb-sm-4">
                    <Grid item xs={12} sm={12}>
                        <Button onClick={this.close} className='link' style={{color: '#bbb', float: 'right', cursor: 'pointer'}}>
                            <Icon>close</Icon>
                        </Button>
                        <h4 className='float-left'>{this.campaign.title}</h4>
                        <div className='clearfix'></div>
                    </Grid>
                </Grid>
                <Grid container className="my-0 mt-sm-3 mb-sm-4">
                    <Grid container className="my-0 my-sm-3">
                        <Grid item xs={12} sm={6}>
                            <div className='my-0 mb-sm-4'>
                                <b>Sent</b>: {moment(this.campaign.created_at.toDate()).format('LLL')} <br/>
                                <b>Category</b>: {env.marketing.sms_categories[this.campaign.campaign_cat]} <br/>
                                <b>Client Type</b>: {env.marketing.sms_clients_type[this.campaign.client_type]} <br/>
                            </div>

                            <h5>SMS Content</h5>
                            <div className='text-preview'>
                                {this.campaign.content}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5 className='mt-1 mt-sm-0'>Recipients ({this.campaign.selected_clients.length})</h5>
                            <div className='box-client box-customized p-1'>
                                {
                                    this.campaign.selected_clients.map((cl, id) => {
                                        return <div key={id}>
                                            <Instagram ig={cl.instagram} w={24} />
                                            <b> {cl.name}</b> - <NumberFormat value={cl.phone} displayType={'text'} format="(###) ###-####" /><br/>
                                        </div>
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container className="my-3">
                        <Grid item xs={12} className='text-center'>
                            <Button onClick={this.close} variant='outlined' color='primary'>
                                CLOSE
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default CampDetails;
