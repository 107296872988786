import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import AddAppt from "../components/appointments/AddAppt";
import ApptList from "../components/appointments/ApptList";
import Services from "../services/Services";
import { withRouter} from 'react-router-dom';
import env from "../conf";
import Loading from "../components/material-ui/Loading";

class Appointments extends React.Component {

    componentWillReceiveProps(nextProps, context) {
        Services.checkIfLoggedIn(nextProps.user, this.props.history, '', false);
    }

    addIncome = (id) => {
        localStorage.setItem(env.local.income_from_cal, JSON.stringify(true));
        localStorage.setItem(env.local.apt_id, JSON.stringify(id));
        this.props.history.push('/accounting');
    };

    goToClientDetails = (id) => {
        this.props.history.push('/clients?cl=' + id);
    };

    render(){
        return (
            this.props.user != null ?
                <Router>
                    <Route
                        path='/appointments'
                        exact
                        render={(props) =>
                            <ApptList
                                {...props}
                                income={this.addIncome}
                                goToClient={this.goToClientDetails}
                                lc={this.props.lc}
                            />}
                    />
                    <Route exact path='/appointments/add' component={AddAppt}></Route>
                </Router>
                : <Loading />
        );
    }
}


export default withRouter(Appointments);
