import React from "react";
import db from "../../Firestore";
import Loading from "../material-ui/Loading";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class AddCategory extends React.Component {

    state = {
        name: '',
        cat_id: '',
        description: '',
        loading: false
    }

    cat_ref = db.collection('/inv_categories');

    componentDidMount() {

    }

    clearRedirect = () => {
        localStorage.removeItem('inv_item');
        localStorage.removeItem('inv_cat');
        this.props.history.push('/inventory');
    }

    checkIfNewItem = () => {
        return true
    }

    handleSubmit = () => {
        if(this.checkIfNewItem){
            let cat = {
                name: this.state.name,
                description: this.state.description,
                created_at: new Date()
            }
            this.cat_ref.add(cat).then( r => {
                console.log('added');
                this.clearRedirect()
            })
        }

    }

    handleChange = (e) => {
        let {name, value} = e.target
        this.setState({
            [name]: value
        });
    }


    render() {
        return(
            this.state.loading ?
                <Loading />
                : <Container maxWidth="sm">
                    <div className="new-client mt-5">
                        <Card>
                            <CardContent>
                            <span onClick={this.clearRedirect} className='notif-btn'>
                                <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                            </span>
                                <h4 className="form-title">
                                    {this.checkIfNewItem() ? 'Add New Category' : 'Update '+ this.state.name}</h4>
                                <div className="clearfix"></div>

                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={errors => console.log(errors)}
                                >
                                    <TextValidator
                                        label="Name"
                                        onChange={this.handleChange}
                                        name="name"
                                        value={this.state.name}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className='row'
                                    />
                                    <TextValidator
                                        label="Description"
                                        onChange={this.handleChange}
                                        name="description"
                                        value={this.state.description}
                                        className='row'
                                    />
                                    <Grid container className="mt-5">
                                        <Grid item xs={4}>
                                            <Button type="submit" variant="contained" className="primary-btn">
                                                <Icon className="mr-2">{this.checkIfNewItem() ? 'add' : 'refresh'}</Icon>
                                                {this.checkIfNewItem() ? 'Add' : 'Update'}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={8} className="text-right p-2">
                                        <span onClick={this.clearRedirect} className='link gray-color text-decoration-none notif-btn'>
                                            Cancel
                                        </span>
                                            <small>{this.loading ? 'Loading...' : ''}</small>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </CardContent>
                        </Card>
                    </div>
                </Container>
        )
    }
}

export default AddCategory