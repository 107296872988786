import {Paper} from "@material-ui/core";
import React from "react";
import db from "../../Firestore";
import Flag from "../material-ui/Flag";
import Icon from "@material-ui/core/Icon";
import Loading from "../material-ui/Loading";
import Services from "../../services/Services";

class Leads extends React.Component {

    state = {
        leads: [],
        location: this.props.lc,
        loading: false
    };

    componentDidMount() {
        this.getLeads();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            location: nextProps.lc
        }, r => {
            console.log('lc from will receipt props', this.state.location)
            this.getLeads();
        })
    }

    openInfo = (item) => {
        this.props.info(item)
    };

    getLeads = () => {
        this.setState({
            loading: true
        }, t => {
            db.collection('/leads')
                .orderBy('created_at', 'desc')
                .where('location', '==', this.state.location)
                .limit(20)
                .onSnapshot( r => {

                    let leads = r.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        };
                    }).filter(r => {
                        return !r.paid && !r.resolved
                    })

                    this.setState({
                            leads: leads
                        }, () =>
                            this.setState({
                                loading: false
                            })
                    )
                })
        })

    };


    render() {
        return(
            <Paper className='p-3'>
                <h5 className='font-weight-normal mb-3'>
                    Latest 10 Leads
                    <span className='float-right'>{this.state.leads.length}</span>
                </h5>
                {
                    this.state.loading ?
                        <Loading />
                        : this.state.leads.map( (info, index) => {
                            return <div key={index} className='mb-2'>
                                <span className='mr-3'>
                                    <span
                                        onClick={() => this.openInfo(info) }
                                        style={{cursor: 'pointer'}}
                                    >
                                        {
                                            info.logs ?
                                                info.name: <b>&#8226; {info.name}</b>
                                        }

                                    </span>
                                    {
                                        info.returning ?
                                            <Flag flag={'Client'} color={'badge-success'}/> : null
                                    }
                                    <Flag flag={'s' + info.step} color={'badge-info'}/>
                                    {
                                        (this.props.owner && info.aff)
                                        || (!this.props.owner && Services.showAffiliate(info.aff)) ? //&& this.props.owner
                                            <Flag flag={info.aff} color={'badge-warning'}/>
                                            : null
                                    }
                                </span>

                                <span className='float-right' style={{cursor: 'pointer'}}>
                                    <Icon className="orange" onClick={() => this.openInfo(info)}>info</Icon>
                                </span>
                            </div>
                        })
                }
            </Paper>
        )
    }
}

export default Leads;
