import React from 'react';
import moment from "moment";
import Flag from "../material-ui/Flag";
import Icon from "@material-ui/core/Icon";
import db from "../../Firestore";
import Retail from "./Retail";
import env from "../../conf";
import {TextField} from "@material-ui/core";
import Services from "../../services/Services";

class SingleAppointment extends React.Component {

    state = {
        add_note: false,
        note: '',
        by: '',
        item: this.props.item,
        show_note: false,
        loading: true,
        is_upcoming: this.props.upcoming && this.props.upcoming === 'Upcoming Appointments',
        user: Services.getUser()
        //is_tech: Services.isTech()
    };

    toggleNote = () => {
        this.setState({
            add_note: !this.state.add_note,
            note: ''
        })
    };

    handleNoteChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    openNotes = () => {
        this.setState({
            show_note: !this.state.show_note
        })
    }

    submitNote = () => {

        let note = {
            note: this.state.note,
            created_at: new Date(),
            by: this.state.by
        }

        if(this.state.note === ''){
            alert('Error: Note cannot be empty')
        }else if(this.state.by === ''){
            alert('Error: Name is missing! Who is writing this note?')
        }else{
            let new_notes;

            if(this.state.item.note){
                new_notes = [...this.state.item.note];
                new_notes.push(note)
            }else{
                new_notes = [note]
            }

            db.collection('/appoitments')
                .doc(this.state.item.uid)
                .set({note: new_notes}, {merge: true})
                .then(r => {
                    this.toggleNote();
                }).catch(er => {
                console.log(er)
            })
        }

    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
        },e => {
            this.setState({
                loading: false
            });
            console.log('------ pt ------')
            console.log(this.state.item);
        });
    }

    render() {
        return(

                !this.state.loading ?
                    <div className='mb-2 single-apt'>
                        <Flag color='location' icon={'location_on'} flag={env.locations[this.state.item.location].name}/>
                        <Flag color='person' icon={'person_2'} flag={Services.getEmployeeName(this.state.item.resourceId)}/>
                        <Flag color={'status-' + this.state.item.status} flag={env.appt_status[this.state.item.status]}/>
                        <span className={'font-weight-bold d-inline-block mr-2 apt-status-' + this.state.item.status}>
                        ({moment(this.state.item.start.toDate()).format('LLL')})
                        </span>
                        <small>
                            {this.state.item.service.cat_name}:
                            {this.state.item.service.name}
                            <Flag flag={this.state.item.service.flag}/>
                            {
                                this.state.item.source ?
                                    <Flag flag={'Online'} color={'badge-warning'}/> : null
                            }
                        </small>
                        {
                            this.state.item.note ?
                                <span onClick={this.openNotes}>
                                    <Icon className='xs-icon note-icon'>description</Icon>
                                </span> : null
                        }


                        {
                            this.state.add_note ?
                                <span className="add-note" onClick={this.submitNote}>
                                    <Icon className='sm-icon'>save</Icon> Save
                                </span> : null
                        }

                        {
                            (this.state.is_upcoming && (this.state.user.owner || (!this.state.user.owner && this.state.user.comp === 'L1')))
                            || !this.state.is_upcoming ?
                                <span className="add-note" onClick={this.toggleNote}>
                                    {
                                        this.state.add_note ?
                                            <Icon className='sm-icon'>close</Icon>
                                            : <React.Fragment>
                                                <Icon className='sm-icon'>add</Icon> Note
                                            </React.Fragment>
                                    }

                                </span> : null
                        }


                        <Retail items={this.props.items} />

                        {
                            this.state.item.comment !== '' && this.state.item.comment ?
                                <div className='retail'>
                                    <span className='retail-box'></span>
                                    <p className='lead-comment'>
                                        <Icon className='flag-icon comment-icon'>chat</Icon>
                                        <b>Client Comment: </b>
                                        {this.state.item.comment}
                                    </p>
                                </div>  : null
                        }

                        {
                            this.state.add_note ?
                                <React.Fragment>
                                    <textarea
                                        className='note-text'
                                        name='note'
                                        value={this.state.note}
                                        placeholder='Type a note...'
                                        onChange={this.handleNoteChange}
                                    > </textarea>
                                    <TextField
                                        className='note-text'
                                        placeholder="By"
                                        value={this.state.by}
                                        name='by'
                                        onChange={this.handleNoteChange}
                                    />
                                </React.Fragment>
                                : null
                        }

                        {
                            this.state.item.note && this.state.show_note ?
                                <div className='retail'>
                                    <span className='retail-box'></span>
                                    {
                                this.state.item.note.map(item => {
                                    return <p className='note-details'>
                                        {item.note}
                                        <br/>
                                        <Flag
                                            flag={'By: ' + item.by + ' @ ' + moment(item.created_at.toDate()).format('lll')}
                                            color={'badge-success'}
                                        />
                                    </p>
                                })}
                                </div>
                                : null
                        }
                    </div> : null
        )
    }
}

export default SingleAppointment;
