import React from 'react';
import Services from "../services/Services";
import Dash from "../components/dashboard/Dash";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Loading from "../components/material-ui/Loading";

class Dashboard extends React.Component{

    componentDidMount() {
        Services.checkIfLoggedIn(this.props.user, this.props.history, '/home', false);
    }

    goToClientDetails = (id) => {
        this.props.history.push('/clients?cl=' + id);
    };

    goToLeadDetails = (id) => {
        this.props.history.push('/marketing?ld=' + id);
    };

    convert = (id) => {

        this.props.history.push({
            pathname: '/clients',
            search: '?lead=' + id
        })

    }


    render(){
        return (
            this.props.user != null ?
                <div className='p-3'>
                    <Router>
                        <Route
                            exact
                            path='/home'
                            render={(props) =>
                                <Dash
                                    goToClient={this.goToClientDetails}
                                    goToLead={this.goToLeadDetails}
                                    convert={this.convert}
                                    lc={this.props.lc}
                                />}
                        />
                    </Router>
                </div>
                : <Loading />
        );
    }
}

export default Dashboard;
