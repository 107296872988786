import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import env from "../../conf";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import db, {increment} from "../../Firestore";
import Services from "../../services/Services";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import moment from 'moment';
import SearchInput from "../material-ui/SearchInput";
import RowInfo from "../clients/RowInfo";
import Switch from "@material-ui/core/Switch";
import Flag from "../material-ui/Flag";

class AddService extends React.Component {

    _isMounted = false;

    state = {
        type: 'service',
        products: [],
        appt_selected: false,
        amount: '',
        tip: '',
        has_products: false,
        appointment: {},
        payment_type: 0,
        use_credit: false,
        location: Services.getLocation()
    };

    params = [];
    loading = false;

    getUserPastAppt = item => {

        if(Services.isObjEmpty(item)){
            this.setState({
                appt_selected: false,
                appointment: item,
                amount: ''
            })
        }else{
            db.collection('clients').doc(item.client.id).get().then(itm => {

                let new_apt = item;
                new_apt.credit = itm.data().credit;

                this.setState({
                    appt_selected: true,
                    appointment: new_apt,
                    amount: new_apt.service.price,
                    use_credit: new_apt.credit > 0
                })
            }).catch(er => {
                console.log('error from getting the right credit')
            })
        }

    };

    handleSubmit = () => {

        // if form is submitted without selecting an appointment
        if(this.state.type === 'service' && Services.isObjEmpty(this.state.appointment)){
            alert('Error! please add an appointment using the search box')
        }else{
            this.loading = true;

            // restricting the income object
            let income = {
                created_at: new Date(),
                location: this.state.location,
                type: this.state.type,
                products: this.state.products,
                payment_type: this.state.type === 'subscription' ? 4 : this.state.payment_type
            };

            if(this.state.type === 'service' || this.state.type === 'subscription'){


                income.amount = parseFloat(this.state.amount);

                if(this.state.type === 'service'){
                    income.client = this.state.appointment.client;
                    income.service = {
                        name: this.state.appointment.service.name,
                        cat_name: this.state.appointment.service.cat_name,
                        flag: this.state.appointment.service.flag,
                        location: this.state.appointment.location
                    };

                    income.appointment_id = this.state.appointment.uid;
                    income.service_date = moment(this.state.appointment.start).clone().toDate();
                    income.tip = this.state.tip > 0 ? parseFloat(this.state.tip) : 0;
                    income.credit_used = this.state.use_credit
                }else{
                    income.client = {
                        id: this.state.client.uid,
                        name: this.state.client.first_name + ' ' + this.state.client.last_name,
                        member: this.state.client.member,
                        membership: this.state.client.membership,
                        generation: this.state.client.generation,
                        credits: this.state.client.membership_credits
                    };

                    income.service_date = new Date();
                }

                if(income.products.length === 0) delete income.products;

            }else{
                income.service_date = new Date();
            }

            db.collection('/incomes')
                .add(income)
                .then(() => {

                    // hiding the loading indicator
                    this.loading = false;

                    // updating rewards, check first if it's a type of service
                    if(this.state.type === 'service' && Services.canServiceUseCredit(income.service.name)){

                        let serviceStartOfDayDate = moment(income.service_date).startOf('day').toDate();
                        let serviceEndOfDayDate = moment(income.service_date).endOf('day').toDate();

                        // checking if services in the same day from the same client, increment rewards only once
                        db.collection('/incomes')
                            .where('client.id', "==", income.client.id)
                            .where('service_date', '>=', serviceStartOfDayDate)
                            .where('service_date', '<=', serviceEndOfDayDate)
                            .get()
                            .then(r => {

                                if(r.docs.length < 2){
                                    let updated_reward = {
                                        rewards_count: increment,
                                        //last_visit: income.service_date
                                    };

                                    db.collection('/clients')
                                        .doc(income.client.id)
                                        .set(updated_reward, {merge: true})
                                        .then( r => {

                                            this.props.history.push('/accounting');
                                        });
                                }else{
                                    this.props.history.push('/accounting');
                                }
                            });
                    }else{
                        this.props.history.push('/accounting');
                    }
                })
        }

    };

    handleChange = e => {

        let name = e.target.name;
        let value = '';

        if(name === 'has_products'){ //

            let empty_product = {
                name: '',
                price: '',
                qty: ''
            };

            value = this.state.has_products ? [] : this.state.products.concat(empty_product);

            this.setState({
                has_products: !this.state.has_products,
                products: value
            });
            return;

        }else{
            value = e.target.value
        }


        this.setState({
            [name]: value,
        }, () => {
            if(name === 'type'){
                // initialize the state values depends on the income type
                // to prevent the auto increment each time we change something
                if(value === 'product'){
                    this.setState({
                        appt_selected: false,
                        appointment: {},
                        has_products: true,
                        products: [{
                            name: '',
                            price: '',
                            qty: ''
                        }],
                    })
                }else{
                    this.setState({
                        products: [], has_products: false
                    });

                }
            }
        })

    };

    handleProductChange = (evt, idx, name) => {

        let list = this.state.products;
        let val = evt.target.value;
        let value = name === 'name' ? val :  val > 0 ? parseInt(val) : 1;

        const new_products = list.map((product, sidx) => {
            if (idx !== sidx) return product;
            return { ...product, [name]: value };
        });

        this.setState({ products: new_products });
    };

    handleAddProduct = e => {
        this.setState({
            products: this.state.products.concat({
                name: '',
                price: '',
                qty: ''
            }),
            type: this.state.type
        })
    };

    handleRemoveProduct = idx => () => {
        this.setState({
            products: this.state.products.filter((s, sidx) => idx !== sidx),
        });
    };

    componentDidMount() {

        // check if visitor is coming from the calendar selecting the appointment already, I used URL params
        if(this.props.location.search !== '') this.isApptProvided();
    }

    isApptProvided = () => {

        this._isMounted = true;

        // params needed:
        // apt, which is the appointment id
        let apt_id = Services.getPramValue('apt', this.props.location.search);


        db.collection('appoitments')
            .doc(apt_id)
            .get()
            .then( res => {
                let new_apt = res.data();

                new_apt.start = moment(res.data().start.toDate()).format('LLL');
                new_apt.uid = apt_id;
                this.getUserPastAppt(new_apt);
            }).catch(er => {
            console.log(er)
        });
    };

    componentWillMount() {
        if(this._isMounted) this._isMounted = false;
    }

    getClient = client => {

        this.setState({
            client: client,
            appt_selected: true,
            amount: client.member ? client.membership_price: 0
        });

    };

    handleCredit = e => {
        this.setState({
            [e.target.name]: !this.state.use_credit
        });
    };

    render() {
        return(
            <Container maxWidth="sm">
                <div className="new-income mt-5">
                    <Card>
                        <CardContent>
                            <NavLink to='/accounting' className='link'>
                                <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                            </NavLink>
                            <h4 className="form-title">
                                Add New Income</h4>
                            <div className="clearfix"></div>

                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={errors => console.log(errors)}
                            >
                                <div className="mt-3 mb-3">
                                    <span className="mt-2 mr-2 dgray-color">Income Type:</span>
                                    <span className="mt-3 mr-2 icon-list">
                                        <Icon className='orange icon-list'>
                                            { this.state.type === 'service' ? 'local_atm' : 'shopping_basket'}</Icon>
                                    </span>
                                    <Select
                                            value={this.state.type}
                                            name='type'
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem key={0} value={env.accounting.sales_types[0]}>{Services.reformatString(env.accounting.sales_types[0])}</MenuItem>
                                            <MenuItem key={1} value={env.accounting.sales_types[1]}>{Services.reformatString(env.accounting.sales_types[1])}</MenuItem>
                                            <MenuItem key={2} value={env.accounting.sales_types[2]}>{Services.reformatString(env.accounting.sales_types[2])}</MenuItem>
                                            <MenuItem key={3} value={env.accounting.sales_types[3]}>{Services.reformatString(env.accounting.sales_types[3])}</MenuItem>
                                        </Select>

                                </div>

                                {
                                    this.state.type !== 'product' ?
                                        <div>
                                            {
                                                this.state.type === 'service' && this.state.appt_selected ?
                                                    <Grid container>
                                                        <Grid item xs={12} className='rounded border border-warning pt-2 mb-2'>
                                                            <span className="dgray-color pl-2">Selected Appointment:</span><br/>
                                                            <div className='pl-2'>
                                                                - <b>{this.state.appointment.client.name}</b> ({this.state.appointment.start})<br/>
                                                                - {this.state.appointment.service.cat_name}: {this.state.appointment.service.name} <Flag flag={this.state.appointment.service.flag} /><br/>
                                                                {this.state.appointment.client.member ? '- Credit: ' + this.state.appointment.credit : null}
                                                            </div>

                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <React.Fragment>
                                                        <SearchInput
                                                            client={this.getClient}
                                                            status={this.state.appt_selected}
                                                            selectedClient={this.state.client}
                                                        />
                                                        {
                                                            this.state.client && this.state.client.member ?
                                                                <div className='pb-3'>
                                                                    <RowInfo
                                                                        icon={'done_all'}
                                                                        value={env.membership[this.state.client.membership].name + ' - $' + this.state.client.membership_price + '/mo'}
                                                                    />
                                                                    <RowInfo
                                                                        icon={'done_all'}
                                                                        value={moment(this.state.client.membership_date.toDate()).format('Do')}
                                                                    />
                                                                </div>
                                                                : null
                                                        }
                                                    </React.Fragment>
                                            }

                                            <TextValidator
                                                label="Amount"
                                                onChange={this.handleChange}
                                                name="amount"
                                                value={this.state.amount}
                                                validators={['required', env.accounting.decimalValidator]}
                                                errorMessages={['This field is required', 'not a valid value']}
                                                className='row mb-3'
                                            />
                                            {
                                                this.state.type === 'service' ?
                                                    <React.Fragment>
                                                        <TextValidator
                                                            label="Tip"
                                                            onChange={this.handleChange}
                                                            name="tip"
                                                            value={this.state.tip}
                                                            validators={[env.accounting.decimalValidator]}
                                                            errorMessages={['Not a valid value']}
                                                            className='row mb-3'
                                                        />

                                                        <Grid container className="my-3">
                                                            <Grid item xs={12} className="dgray-color pt-3">
                                                                <FormControlLabel className='row' control={
                                                                    <Switch
                                                                        checked={this.state.use_credit}
                                                                        onChange={this.handleCredit}
                                                                        value={this.state.use_credit}
                                                                        name="use_credit"
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                    />
                                                                } label="Use Credit?" /> {this.state.use_credit && this.state.appointment.client.member? '==>' + this.state.appointment.credit + ' credits available' : null}
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container  className="mb-3">
                                                            <Grid item xs={4} className="dgray-color">
                                                                <span className="mt-2 d-block">Any product sales?</span>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <FormControlLabel
                                                                    value='true'
                                                                    checked={this.state.has_products}
                                                                    name='has_products'
                                                                    control={<Radio color="secondary" />}
                                                                    label="Yes"
                                                                    labelPlacement="end"
                                                                    onChange={this.handleChange}
                                                                />
                                                                <FormControlLabel
                                                                    value='false'
                                                                    checked={!this.state.has_products}
                                                                    name='has_products'
                                                                    control={<Radio color="secondary" />}
                                                                    label="No"
                                                                    labelPlacement="end"
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment> : null
                                            }
                                        </div>
                                        : ''
                                }
                                {
                                    this.state.has_products || this.state.products.length > 0 ?
                                        this.state.products.map((product, index) => {
                                            return <div key={index} className='product-list mb-2'>
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                        <TextValidator
                                                            label={'Product #'+ (index+1) +' name'}
                                                            onChange={e => this.handleProductChange(e, index, 'name')}
                                                            value={product.name}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                            className='row'
                                                            key={'name'+index}
                                                        />
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} key={'pr'+index}>
                                                                <TextValidator
                                                                    label={'Product #'+ (index+1) +' price'}
                                                                    onChange={e => this.handleProductChange(e, index, 'price')}
                                                                    value={product.price}
                                                                    validators={['required', env.accounting.decimalValidator]}
                                                                    errorMessages={['This field is required', 'not a valid value']}
                                                                    className='row'
                                                                    key={'price'+index}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} key={'qt'+index}>
                                                                <TextValidator
                                                                    label={'Product #'+ (index+1)  +' Qty'}
                                                                    onChange={e => this.handleProductChange(e, index, 'qty')}
                                                                    value={product.qty}
                                                                    validators={['required', 'isNumber']}
                                                                    errorMessages={['this field is required', 'this field has to be a number']}
                                                                    className='row'
                                                                    key={'qty'+index}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2} className='text-center pt-4 orange'>
                                                        <Button onClick={this.handleRemoveProduct(index)}>
                                                            <Icon className='orange'>delete</Icon>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        }) : ''
                                }
                                {
                                    this.state.has_products || this.state.products.length > 0 ?
                                        <Button onClick={this.handleAddProduct} className='d-block w-100' variant='contained'>
                                            <Icon>add</Icon>
                                        </Button>
                                        : ''
                                }

                                {
                                    this.state.type !== 'subscription' ?
                                        <div className="mt-3">
                                            <span className="mt-2 mr-2">Payment type:</span>
                                            <span className="mt-3 mr-2 icon-list">
                                                    <Icon className='orange icon-list'>
                                                        { this.state.payment_type === 0 ? 'attach_money' : 'credit_card'}
                                                    </Icon>
                                                </span>
                                            <Select
                                                value={this.state.payment_type}
                                                name='payment_type'
                                                onChange={this.handleChange}
                                            >
                                                {
                                                    env.accounting.expense_types.map((item, idx) => {
                                                        return <MenuItem key={idx} value={idx}>{item}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </div> : null
                                }

                                {/*{
                                    this.state.type === 'package' ?
                                        <div className="mt-3">
                                            <span className="mt-2 mr-2">Payment type:</span>
                                            <span className="mt-3 mr-2 icon-list">
                                                    <Icon className='orange icon-list'>
                                                        { this.state.payment_type === 0 ? 'attach_money' : 'credit_card'}
                                                    </Icon>
                                                </span>
                                            <Select
                                                value={this.state.payment_type}
                                                name='payment_type'
                                                onChange={this.handleChange}
                                            >
                                                {
                                                    env.accounting.expense_types.map((item, idx) => {
                                                        return <MenuItem key={idx} value={idx}>{item}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </div> : null
                                }*/}

                                <Grid container className="mt-5 clearfix">
                                    <Grid item xs={4}>
                                        <Button type="submit" variant="contained" className="primary-btn">
                                            <Icon className="mr-2">add</Icon>
                                            Add
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8} className="text-right p-2">
                                        <NavLink to='/accounting' className='link gray-color text-decoration-none'>
                                            Cancel
                                        </NavLink>
                                        <small>{this.loading ? 'Loading...' : ''}</small>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        )
    }
}

export default AddService;
