import React from 'react';
import SingleClientChip from "./SingleClientChip";
import Button from "@material-ui/core/Button";

class CampaignClients extends React.Component {

    customize = () => {
        this.props.customize()
    };

    render() {
        return(
            <div>

                <div className='box-client b-1'>
                    {
                        this.props.list.map( (item, key) => {
                            return <SingleClientChip key={key} client={item} managed={false} />
                        })
                    }

                </div>
                <Button onClick={this.customize} className='custmize-btn'>customize</Button>
            </div>
        )
    }
}

export default CampaignClients;
