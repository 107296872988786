import React from 'react';
import Fab from "@material-ui/core/Fab";
import myStyles from "../../layouts/Styles";
import Icon from "@material-ui/core/Icon";
import db from "../../Firestore";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Hidden from "@material-ui/core/Hidden";
import TableBody from "@material-ui/core/TableBody";
import {Paper} from "@material-ui/core";
import SingleCampaign from "./SingleCampaign";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import CampDetails from "./CampDetails";
import { withRouter} from 'react-router-dom';


class CampList extends React.Component {

    state = {
        campaigns: [],
        modal: false,
        selected_campaign: {}
    };

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns = () => {
        return db.collection('/campaigns')
            .orderBy('created_at', 'desc')
            .get()
            .then( res => {
                let list = res.docs.map(item => {
                    return {
                        ...item.data(),
                        id: item.id
                    }
                });

                this.setState({
                    campaigns: list
                })
            })
    };

    addNew = () =>{
        this.props.history.push('/campaigns/new');
    };

    close = () => {
        this.setState({
            modal: false
        })
    };

    openCampaignDetails = (camp) => {

        console.log(camp)
        this.setState({
            modal: true,
            selected_campaign: camp
        })
    };

    render() {
        return(
            <div className="clients-list px-0">
                <div className="col-sm-4 col-xs-6 float-right my-4 mx-0 px-0" style={{textAlign: 'right'}}>
                    <Fab onClick={this.addNew} color="primary" style={myStyles.button} aria-label="Add">
                        <Icon>add</Icon>
                    </Fab>
                </div>
                <div className='clearfix'></div>
                <Paper className='mx-0 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <Hidden only='xs'>
                                    <TableCell>Category</TableCell>
                                </Hidden>
                                <TableCell>Title</TableCell>{/*add limited width={60} for IG avatar*/}
                                <Hidden only='xs'>
                                    <TableCell>Clients</TableCell>
                                </Hidden>
                                <TableCell>Targeted</TableCell>
                                <Hidden only='xs'>
                                    <TableCell>Date</TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.campaigns.map(
                                    doc => <SingleCampaign key={doc.id} campaign={doc} open={this.openCampaignDetails} />
                                )
                            }
                        </TableBody>
                    </Table>
                </Paper>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modal}
                    onClose={this.close}
                >
                    <DialogContent className='modal-big'>
                        {
                            this.state.modal ?
                                <CampDetails close={this.close} campaign={this.state.selected_campaign} /> : null
                        }
                    </DialogContent>
                </Modal>
            </div>
        )
    }

}

export default withRouter(CampList);
