import React from 'react';
import Icon from "@material-ui/core/Icon";

class Retail extends React.Component{

    state = {
        products: [],
        open: false
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.items && nextProps.items.products){
            this.setState({
                products: nextProps.items.products
            })
        }
    }

    toggleRetail = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return(
            this.state.products && this.state.products.length > 0 ?
                <div className='retail'>
                    <span className='retail-box'></span>
                    <span className="add-note sales" onClick={this.toggleRetail}>
                        <Icon className='sm-icon'>{this.state.open ? 'remove' : 'add'}</Icon> Product Sale
                    </span>
                    {
                        this.state.open ?
                            <div className='sales-container'>
                                {this.state.products.map((item, idx) => {
                                    return <div key={idx} className='sale-item'>{item.qty} x [{item.name} (${item.price})]</div>
                                })}
                            </div> : null
                    }

                </div> : null
        )
    }
}

export default Retail;