import React from "react";
import {Paper} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
/*import TablePagination from "@material-ui/core/TablePagination";
import env from "../../conf";*/
import Table from "@material-ui/core/Table";
import SingleExpense from "./SingleExpense";

class Expense extends React.Component {

    state = {
        page: 1,
        size: 30,
        new_list: [...this.props.list]
    };

    receivedProps = false;

    componentWillMount() {
        this.receivedProps = true;
    }

    componentWillReceiveProps(nextProps, nextContext){
        let another_list = [...nextProps.list]
        this.setState({
            new_list: another_list
        })
    }

    render() {
        return(
            this.receivedProps ?
                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell>Payment Type</TableCell>
                                <TableCell>Expense</TableCell>
                                <TableCell>Note</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.new_list.map((doc, idx) => {
                                    return <SingleExpense key={idx} expense={doc} numbers={this.props.numbers}/>})
                            }
                            {/*<TableRow>
                            <TableCell colSpan={7}>
                                <TablePagination
                                    rowsPerPageOptions={env.per_page.options}
                                    component="div"
                                    count={this.state.size}
                                    rowsPerPage={env.per_page.default}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableCell>
                        </TableRow>*/}
                        </TableBody>
                    </Table>
                </Paper>
                : 'Loading'
        )
    }

}

export default Expense;
