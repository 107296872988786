import React from 'react';
import {Paper} from "@material-ui/core";
import db from "../../Firestore";
import Icon from "@material-ui/core/Icon";
import moment from "moment";

class Birthday extends React.Component {

    state = {
        list: []
    };

    componentDidMount() {

        let today = moment().format('MM/DD');

        db.collection('clients')
            .where('birthday', '==', today)
            .get()
            .then(r => {
                this.setState({
                    list: r.docs.map(doc => {
                        //console.log(doc.data());
                        return {
                            ...doc.data()
                        }
                    })
                })
            })
    }

    render(){
        return(
            this.state.list.length > 0 ?
                <Paper className='p-3 mb-3'>
                    <Icon className="gray-color icon-list mr-1">cake</Icon>
                    {
                        this.state.list.map((doc, idx) => {
                            return <span className='' key={doc.uid}>
                            {
                                idx !== 0 ?
                                    ' - ':null
                            }
                                {doc.first_name} {doc.last_name}
                        </span>
                        })
                    }
                </Paper> : null
        )
    }
}

export default Birthday;
