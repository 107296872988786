import React from "react";
import Grid from "@material-ui/core/Grid";
import Charts from "../material-ui/Charts";
import Services from "../../services/Services";
import AccInsightStats from "./AccInsightStats";
import env from "../../conf";
import Paper from "@material-ui/core/Paper";
import SmallChart from "../material-ui/SmallChart";

class AccInsights extends React.Component {

    receivedProps = false;

    chart = env.chart;
    env_cats = [...env.cats, 'Deposits'];

    componentWillMount() {
        this.receivedProps = true;
    }

    getValueOf = (type) => {

        let incomes = this.props.accState.incomes;

        let payments = incomes.filter(item => {
            return item.payment_type === type
        });

        if(this.checkTotal(payments)) return this.checkTotal(payments)

        return 0;

    };

    getTotalof = (cat) => {
        let incomes = this.props.accState.incomes;

        let isSkin = env.cats.indexOf(cat) === 1;
        let isLashes = env.cats.indexOf(cat) === 0;

        let filter_string = isSkin ? env.accounting.filter_str.skin_membership : env.accounting.filter_str.lash_membership;
        let subscription = env.accounting.filter_str.membership_income_type

        let cats = incomes.filter(item => {

            if(cat === 'Deposits'){
                return item.type === 'deposit'
            }

            if(item.type === subscription){

                if(isLashes || isSkin){
                    console.log('sscription and', cat);
                    return item.client.membership.includes(filter_string)
                }

            }

            return item.type === 'service' && item.service.cat_name === cat
        });

        console.log(cat, cats)

        if(this.checkTotal(cats)) return this.checkTotal(cats);

        return 0;

    }

    checkTotal = (list) => {
        if(list.length > 0){

            if(list.length === 1){

                let total_single =  Services.calculateIncome(list[0]);

                return total_single;

            }else{

                let sum = 0;

                list.map(elem => {
                    sum = (sum || 0) + Services.calculateIncome(elem);
                    return sum
                });

                return sum;
            }
        }
    }

    render() {
        return(
            this.receivedProps ?
                <div>
                    <Paper className='mx-3 mt-2 mb-3'>
                        <Grid container spacing={2}>
                            <Charts
                                condition={this.props.accState.products_count ||  this.props.accState.service}
                                numbers={[
                                    {
                                        label: `Service ($${Services.formatPrice(this.props.accState.service, this.props.numbers)})`,
                                        value: this.props.accState.service,
                                        color: this.chart.colors.service
                                    },
                                    {
                                        label: `Tip ($${Services.formatPrice(this.props.accState.tip, this.props.numbers)})`,
                                        value: this.props.accState.tip,
                                        color: this.chart.colors.tip },
                                    {
                                        label: `Retail ($${Services.formatPrice(this.props.accState.products_count, this.props.numbers)})`,
                                        value: this.props.accState.products_count,
                                        color: this.chart.colors.products
                                    },
                                ]}
                            />
                            <Charts
                                condition={this.props.accState.incomes_count || this.props.accState.expenses_count}
                                numbers={[
                                    {
                                        label: `Expenses ($${Services.formatPrice(this.props.accState.expenses_count, this.props.numbers)})`,
                                        value: this.props.accState.expenses_count,
                                        color: this.chart.colors.expense
                                    },
                                    {
                                        label: `Net Income ($${Services.formatPrice(Services.subscract(this.props.accState.incomes_count, this.props.accState.expenses_count), this.props.numbers)})`,
                                        value: Services.subscract(this.props.accState.incomes_count, this.props.accState.expenses_count), // TODO fix this value, it logs a lot of NaN errors
                                        color: this.chart.colors.income
                                    }
                                ]}
                            />
                        </Grid>
                        {
                            this.props.accState.incomes_count ?
                                <Grid container alignItems="center" spacing={3}>
                                    {
                                        env.accounting.expense_types.map((itm, idx) => {
                                            return <SmallChart
                                                title={itm}
                                                value={this.getValueOf(idx)}
                                                total={this.props.accState.incomes_count}
                                                numbers={this.props.numbers}
                                                color={env.accounting.types_colors[idx]}
                                            />
                                        })
                                    }
                                </Grid> : null
                        }
                        {
                            this.props.accState.incomes_count ?
                                <Grid container alignItems="center" spacing={3}>
                                    {
                                        this.env_cats.map((itm, idx) => {
                                            return <SmallChart
                                                title={itm}
                                                value={this.getTotalof(itm)}
                                                total={this.props.accState.incomes_count}
                                                numbers={this.props.numbers}
                                                color={env.accounting.types_colors[idx]}
                                            />
                                        })
                                    }
                                </Grid> : null
                        }
                    </Paper>
                    <AccInsightStats
                        numbers={this.props.numbers}
                        incomes_count={this.props.accState.incomes_count}
                        expenses_count={this.props.accState.expenses_count}
                        total_services={this.props.accState.stats.total_services}
                        total_clients={this.props.accState.stats.total_clients}
                        from={this.props.accState.custom_from}
                        to={this.props.accState.custom_to}
                    />
                </div> : 'Loading'
        )
    }
}

export default AccInsights;
