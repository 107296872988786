import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

class SingleGiftCard extends React.Component {

    card = this.props.card;

    handleClick = () => {
        this.props.openLeadDetails(this.card);
    };

    render() {
        return(
            <TableRow key={this.props.key}>
                <TableCell component="th" scope="row" onClick={this.handleClick}>${this.card.amount}</TableCell>
                <TableCell scope="row" onClick={this.handleClick}>{this.card.from}</TableCell>
                <TableCell scope="row" onClick={this.handleClick}>{this.card.to}</TableCell>
                <TableCell scope="row" onClick={this.handleClick}>{moment(this.card.created_at.toDate()).format('LLL')}</TableCell>
            </TableRow>
        )
    }
}

export default SingleGiftCard;
