import React from 'react';
import PropTypes from 'prop-types';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Icon from '@material-ui/core/Icon';
import Services from "../../services/Services";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import EditButton from "../shared/EditButton";

class SingleItem extends React.Component {

    item = this.props.item;
    cat = this.props.cat

    openConfirm = () => {
        this.props.selectItem(this.item)
    };

    handleClick = () => {
        this.props.openitemDetails(this.item);
    };

    render(){
        return(
            <TableRow key={this.item.id}>
                <TableCell component="th" scope="row" onClick={this.handleClick}>
                    {Services.reformatString(this.item.name)}
                </TableCell>
                <TableCell onClick={this.handleClick}>
                   {this.item.qty}
                </TableCell>
                <TableCell onClick={this.handleClick}>
                    {this.item.size}
                </TableCell>
                <Hidden only="xs">
                    <TableCell onClick={this.handleClick}>
                        {this.cat}
                    </TableCell>
                </Hidden>

                <TableCell>
                    <EditButton client={this.item} type='icon' />
                        <IconButton color="secondary" className="orange" aria-label="delete" onClick={this.openConfirm}>
                            <Icon className="orange">delete</Icon>
                        </IconButton>
                </TableCell>
            </TableRow>
        )
    }
}

SingleItem.propTypes = {
    item: PropTypes.object.isRequired,
};

export default SingleItem;

