import moment from "moment";

const Dates = {
    isDateValid(date){
        return moment(date).isValid();
    },
    isUpcoming(date){
        return date > new Date();
    },
    getDate(date, range, custom_date){

        if(custom_date === null){

            let str = '';

            if(date === 'This Month' || date === 'Past Month' || date === 'Past 6 Months' || date === 'Past 12 Months') str = 'month';
            if(date === 'This Quarter' || date === 'Past Quarter') str = 'quarter';
            if(date === 'This Week' || date === 'Past Week') str = 'week';
            if(date === 'This Year' || date === 'Past Year') str = 'year';
            if(date === 'Today' || date === 'Yesterday') str = 'day';

            if( range === 'end'){ // end

                if(date === 'Past Quarter' || date === 'Past Month' || date === 'Past Week' || date === 'Past Year' || date === 'Yesterday'){
                    return moment().subtract(1, str + 's').endOf(str).toDate();
                }else{
                    return moment().toDate();
                }


            }else{ // start

                let final_val;

                switch(date){
                    case 'This Quarter':
                    case 'This Month':
                    case 'This Week':
                    case 'This Year':
                    case 'Today':
                        final_val = moment().startOf(str).toDate();
                        break;

                    case 'Yesterday':
                    case 'Past Week':
                    case 'Past Month':
                    case 'Past Quarter':
                    case 'Past Year':
                        final_val = moment().subtract(1, str + 's').startOf(str).toDate();
                        break;

                    case 'Past 6 Months':
                        final_val = moment().subtract(6, str + 's').startOf('day').toDate();
                        break;

                    case 'Past 12 Months':
                        final_val = moment().subtract(12, str + 's').startOf('day').toDate();
                        break;

                    default:
                        break;
                }

                return final_val;

            }

        }else{
            if(range === 'custom_from'){
                return moment(custom_date).startOf('day').toDate();
            }else{
                return moment(custom_date).endOf('day').toDate();
            }

        }

    }
};

export default Dates;
