import React from 'react';
import {Paper} from "@material-ui/core";
import db from "../../Firestore";
import Loading from "../material-ui/Loading";
import SingleMembership from "./SingleMembership";

class Subscriptions extends React.Component {

    state = {
        list: [],
        loading: false
    };

    // props: client_id

    componentDidMount() {
        this.loading = true;
        this.getSubscriptions();
    }

    getSubscriptions = () => {

        this.setState({
            loading: true
        }, cb => {
            return db.collection('/incomes')
                .where('client.id', '==', this.props.client_id)
                //.where('type', '==', 'subscription')
                .orderBy('created_at', 'asc')
                .get()
                .then( res => {

                    let list = res.docs.map((doc) => {

                        return {
                            ...doc.data(),
                            uid: doc.id
                        };
                    });

                    let list_1 = list, list_2 = list;
                    let subscriptions = list_1.filter( r => r.type === 'subscription');
                    let retails = list_2.filter( r => r.products && r.products.length > 0);

                    retails = this.convertList(retails);


                    this.setRetail(retails);

                    this.setState({
                        list: subscriptions,
                        loading: false
                    });

                });
        })
    };

    convertList = (arr) => {
        let rv = {};
        for (let i = 0; i < arr.length; ++i)
            rv[arr[i]['appointment_id']] = arr[i];
        return rv;
    };

    setRetail = (list) => {
        this.props.retails(list)
    };


    render() {
        return(
            this.props.member? //|| this.props.membership ?
                <Paper className='p-3 mb-3'>
                    <h4 className="gray-color">
                        {this.props.member ? 'Recurring' : 'Former Membership'} Payments
                        <span className="float-right">{this.state.list.length}</span>
                    </h4>
                    {
                        this.state.loading ?
                            <Loading />
                            : this.state.list.length === 0 ?
                            <i className="dgray-color">Nothing :/</i>
                            : this.state.list.map((item, idx) => {
                                return <SingleMembership
                                            idx={idx}
                                            item={item}
                                            owner={this.props.owner}
                                            last={this.state.list.length - 1 === idx}
                                />
                            })
                    }
                </Paper> : null
        )
    }
}

export default Subscriptions;
