import React from 'react';
import env from "../../conf";

class PaymentTypeBadge extends React.Component {

    data = this.props.data;

    buildClassName = (name) => {

        let class_name = '';

        switch(this.data){
            case 1:
                class_name = 'badge-dark';
                break;

            case 2:
                class_name = 'badge-success';
                break;

            case 3:
                class_name = 'badge-danger';
                break;

            case 4:
                class_name = 'badge-primary';
                break;

            default:
                class_name = 'badge-secondary';
                break;
        }

        return name + class_name;
    };

    class_name =  this.buildClassName('badge ');


    render() {
        return(
            <span className={this.class_name}>
                {env.accounting.expense_types[this.data]}
            </span>
        )
    }
}


export default PaymentTypeBadge;
