import React from 'react';
import {Paper} from "@material-ui/core";
import env from "../../conf";
import Instagram from "../material-ui/Instagram";
import Loading from "../material-ui/Loading";
import db from "../../Firestore";
import moment from "moment";
import Flag from "../material-ui/Flag";

class Members extends React.Component{

    state = {
        logs: false,
        logsList: [],
        loading: false
    };

    toggleTab = (status) => {

        this.setState({
            logs: status
        }, ()=> {
            if(status){
                this.getLogs()
            }
        });
    };

    getLogs = () => {

        this.setState({
            loading: true
        });

        db.collection('/logs')
            .orderBy('created_at', 'desc')
            .get()
            .then( r => {

                let logs = r.docs.map(doc => {
                    return doc.data();
                });

                this.setState({
                        logsList: logs
                    },
                    () => this.setState({
                        loading: false
                    })
                )
            })
    };

    clientDetails = (id) => {
        this.props.clientDetails(id)
    };



    render() {
        return(
            <Paper className='p-3 mt-3'>
                <h5 className='font-weight-normal mb-3'>
                    Rewards
                    <div className="float-right">
                        <span className={'tab-menu' + (this.state.logs ? '' : ' active')} onClick={() => this.toggleTab(false)}>Top {env.dashboard.top_client}</span>
                        <span className={'tab-menu' + (this.state.logs ? ' active' : '')} onClick={() => this.toggleTab(true)}>Logs</span>
                    </div>
                </h5>
                {
                    this.props.loading || this.state.loading ?
                        <Loading />
                        : this.state.logs ?
                        this.state.logsList.map( (info, index) => {
                            return <div key={index} className='mb-2'>
                                    <span>
                                        {info.name}
                                        <Flag flag={info.phone} color={'badge-secondary'} /> checked rewards
                                        <span className='dgray-color'>
                                                {' ' + moment(info.created_at.toDate()).fromNow()}
                                            </span>
                                        </span>
                            </div>
                        })
                        : this.props.clients.map( (info, index) => {
                            return <div key={index} className='mb-2'>
                                    <span className='mr-3'>
                                        <Instagram ig={info.instagram} w={24} />
                                    </span>
                                <span>
                                        <span onClick={e => this.clientDetails(info.uid)} className="notif-btn">{info.first_name} {info.last_name}</span>
                                        <span className='float-right'>
                                            {info.rewards_count}
                                            <span role='img' aria-label='emoji'>&#11088;</span>
                                        </span>
                                    </span>
                            </div>
                        })
                }
            </Paper>
        )
    }
}

export default Members;
